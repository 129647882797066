import { styled } from '@mui/material';
import Grid from '@mui/material/Grid';
import Tooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip';
import { ReactElement, ReactNode } from 'react';
import { Timesheet } from '../../../../../constants/Timesheet/Timesheet';
import {
	sortTimesheetHoursDayMondayFirst,
	toShortDay,
} from '../../../../helpers/dateUtilities';

export type TimesheetHoursTooltipProps = {
	children: ReactElement;
	hours: Timesheet['hours'];
	title: string;
	subtitle?: ReactNode;
};

export const TimesheetHoursTooltip = ({
	children,
	hours,
	title,
	subtitle,
}: TimesheetHoursTooltipProps): JSX.Element => {
	const sortedHours = Object.entries(hours)
		.filter(([key, _]) => key !== 'total')
		.sort(([dayA, _], [dayB, __]) =>
			sortTimesheetHoursDayMondayFirst(dayA, dayB),
		);

	const formattedTitle = (
		<Grid container item xs={12}>
			<Grid
				container
				data-testid="timesheet-tooltip-title"
				item
				xs={12}
				fontWeight="fontWeightBold">
				{title}
			</Grid>
			{subtitle && (
				<Grid
					data-testid="timesheet-tooltip-subtitle"
					container
					item
					xs={12}>
					{subtitle}
				</Grid>
			)}
		</Grid>
	);

	const formattedHours = (
		<Grid container item xs={12}>
			{sortedHours.map(([key, value]) => {
				const hoursKey = key as keyof Omit<Timesheet['hours'], 'total'>;

				return (
					<Grid
						container
						item
						xs={12}
						key={key}
						justifyContent="space-between">
						<Grid container item xs={6}>
							{toShortDay(hoursKey)}
						</Grid>
						<Grid container item xs={6} justifyContent="flex-end">
							{`${value.billable} hrs`}
						</Grid>
					</Grid>
				);
			})}
		</Grid>
	);

	const formattedTooltip = (
		<Grid container rowGap={2}>
			{formattedTitle}
			{formattedHours}
		</Grid>
	);

	return (
		<CustomWidthTooltip title={formattedTooltip} arrow>
			{children}
		</CustomWidthTooltip>
	);
};

const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
	<Tooltip {...props} classes={{ popper: className }} />
))({
	[`& .${tooltipClasses.tooltip}`]: {
		maxWidth: 120,
	},
});

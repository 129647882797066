import { Button, Grid } from '@mui/material';
import firebase from 'firebase';
import { Dispatch, SetStateAction, useState } from 'react';
import { AccountsAccountType } from '../../constants/Accounts';
import {
	Site,
	UserDetails,
	UserProfile,
	WorkerType,
} from '../../constants/Common';
import { FirebaseApi } from '../../firebase/firebaseApi';
import { UsersRemoveModal } from './UsersRemoveModal';

export type UserOptionsProps = {
	modalOpen: boolean;
	setModalOpen: Dispatch<SetStateAction<boolean>>;
	setSelected: (userID: string) => void;
	accountType: AccountsAccountType;
	existingUser: UserDetails;
	canRemoveUser: boolean;
	sites: Record<string, Site>;
	firebaseApi: Pick<
		FirebaseApi,
		| 'companiesSubscriptionByType'
		| 'updateUserAccountDetails'
		| 'updateUserProfileCompany'
		| 'updateUserDetailsCompanyInfo'
	>;
};

export const UsersOptions = ({
	setModalOpen,
	setSelected,
	existingUser,
	canRemoveUser,
	firebaseApi,
}: UserOptionsProps): JSX.Element => {
	const [removeModalOpen, setRemoveModalOpen] = useState(false);

	// Remove user from company & UserProfile
	const removeUserFromCompany = async (): Promise<void> => {
		if (!existingUser) {
			return;
		}
		const updatedUser: Pick<
			UserDetails,
			| 'accountType'
			| 'workerType'
			| 'site'
			| 'siteID'
			| 'siteCompany'
			| 'siteCompanyID'
			| 'company'
			| 'companyID'
			| 'contractedTo'
		> = {
			accountType: '',
			workerType:
				firebase.firestore.FieldValue.delete() as unknown as WorkerType, // delete field
			site: '',
			siteID: '',
			siteCompany: '',
			siteCompanyID: '',
			company: '',
			companyID: '',
			contractedTo: null,
		};

		const updatedUserProfile: Pick<UserProfile, 'company'> = {
			company: {
				id: '',
				name: '',
			},
		};

		try {
			await firebaseApi.updateUserProfileCompany(
				existingUser.userID,
				updatedUserProfile,
			);
		} catch (error) {
			// Continue if unable to find UserProfile
			console.error(error);
		}

		await firebaseApi.updateUserDetailsCompanyInfo(
			existingUser.userID,
			updatedUser,
		);
	};

	return (
		<>
			<UsersRemoveModal
				modalOpen={removeModalOpen}
				setModalOpen={setRemoveModalOpen}
				userName={existingUser.displayName}
				removeUserFromCompany={removeUserFromCompany}
			/>
			<Grid container spacing={1}>
				<Grid item xs={12} sm={canRemoveUser ? 6 : 12}>
					<Button
						fullWidth
						onClick={(): void => {
							setSelected(existingUser.userID);
							setModalOpen(true);
						}}
						variant="outlined">
						Edit
					</Button>
				</Grid>
				{canRemoveUser && (
					<Grid item xs={12} sm={6}>
						<Button
							fullWidth
							onClick={(): void => setRemoveModalOpen(true)}
							variant="contained"
							color="error">
							Remove
						</Button>
					</Grid>
				)}
			</Grid>
		</>
	);
};

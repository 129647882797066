import { Activity, DayString } from '../../../../../constants/Common';
import { NoteDay, TimesheetNote } from '../../../../../constants/Note';
import { Action } from '../../../../../constants/TypescriptUtilities';

type EditAction<
	T extends EditActionsTypes,
	P extends object | void = void,
> = Action<T, P>;

export const enum EditActionsTypes {
	ADD_ACTIVITY = 'ADD_ACTIVITY',
	DELETE_ACTIVITY = 'DELETE_ACTIVITY',
	UPDATE_ACTIVITY = 'UPDATE_ACTIVITY',
	UPDATE_BREAKS = 'UPDATE_BREAKS',
	UPDATE_WEEK = 'UPDATE_WEEK',
	UPDATE_NOTE = 'UPDATE_NOTE',
	UPDATE_TIMESHEET_ERRORS = 'UPDATE_TIMESHEET_ERRORS',
}

type AddActivityAction = EditAction<
	EditActionsTypes.ADD_ACTIVITY,
	{ day: DayString }
>;

type DeleteActivityAction = EditAction<
	EditActionsTypes.DELETE_ACTIVITY,
	{ id: Activity['id'] }
>;

type UpdateActivityAction = EditAction<
	EditActionsTypes.UPDATE_ACTIVITY,
	{ id: Activity['id']; activity: Activity['activity']; hours: number }
>;

type UpdateBreaksAction = EditAction<
	EditActionsTypes.UPDATE_BREAKS,
	{ day: DayString; hours: number }
>;

type UpdateWeekAction = EditAction<
	EditActionsTypes.UPDATE_WEEK,
	{ weekEnding: Date }
>;

type UpdateNoteAction = EditAction<
	EditActionsTypes.UPDATE_NOTE,
	Pick<TimesheetNote, 'day' | 'id' | 'note'>
>;

type UpdateTimesheetErrors = EditAction<
	EditActionsTypes.UPDATE_TIMESHEET_ERRORS,
	{ errors: Record<string, true> }
>;

export type EditActions =
	| AddActivityAction
	| DeleteActivityAction
	| UpdateActivityAction
	| UpdateBreaksAction
	| UpdateWeekAction
	| UpdateNoteAction
	| UpdateTimesheetErrors;

export const addActivity = (day: DayString): AddActivityAction => ({
	type: EditActionsTypes.ADD_ACTIVITY,
	payload: { day },
});

export const deleteActivity = (id: Activity['id']): DeleteActivityAction => ({
	type: EditActionsTypes.DELETE_ACTIVITY,
	payload: { id },
});

export const updateActivity = (
	id: Activity['id'],
	activity: Activity['activity'],
	hours: Activity['hours'],
): UpdateActivityAction => ({
	type: EditActionsTypes.UPDATE_ACTIVITY,
	payload: {
		id,
		activity,
		hours,
	},
});

export const updateBreaks = (
	day: DayString,
	hours: number,
): UpdateBreaksAction => ({
	type: EditActionsTypes.UPDATE_BREAKS,
	payload: { day, hours },
});

export const updateWeek = (weekEnding: Date): UpdateWeekAction => ({
	type: EditActionsTypes.UPDATE_WEEK,
	payload: { weekEnding },
});

export const updateNote = (
	day: NoteDay,
	id: TimesheetNote['id'],
	note: TimesheetNote['note'],
): UpdateNoteAction => ({
	type: EditActionsTypes.UPDATE_NOTE,
	payload: {
		day,
		id,
		note,
	},
});

export const updateTimesheetErrors = (
	errors: Record<string, true>,
): UpdateTimesheetErrors => ({
	type: EditActionsTypes.UPDATE_TIMESHEET_ERRORS,
	payload: { errors },
});

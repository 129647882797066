import { Paper, Stack } from '@mui/material';
import { endOfWeek } from 'date-fns';
import { useEffect, useMemo, useReducer } from 'react';
import {
	Company,
	CompanyTypes,
	DayString,
	Site,
	UserDetails,
} from '../../../../constants/Common';
import { NewTimesheetStatus } from '../../../../constants/Timesheet/TimesheetUtilities';
import { FirebaseApi } from '../../../../firebase/firebaseApi';
import { LoadingDots } from '../../../Management/subcomponents/LoadingDots';
import { splitSiteLogsByDay } from '../Details/timesheetTableUtilities';
import {
	addActivity,
	deleteActivity,
	selectClient,
	selectSite,
	selectTimesheetStatus,
	selectWeekEnding,
	selectWorker,
	updateActivity,
	updateLoading,
	updateSiteLogs,
} from './actions';
import { CreateWeekList } from './CreateBody/CreateWeekList';
import { CreateHeader } from './CreateHeader';
import {
	createInitialCreateState,
	createTimesheetReducer,
	NewActivity,
} from './reducer';

const TABLE_MIN_WIDTH = 330;

export type CreateFirebaseCalls = 'userSitelogsForWeekSubscription';

export type CreateProps = {
	loading: boolean;
	userDetails: UserDetails;
	users: Record<string, UserDetails>;
	companies: Record<string, Company>;
	sites: Record<string, Site>;
	weekEndingQueryParam: Date;
	setWeekQueryParams: (weekEnding: Date) => void;
	firebaseApi: Pick<FirebaseApi, CreateFirebaseCalls>;
};

export const Create = ({
	loading,
	userDetails,
	users,
	companies,
	sites,
	weekEndingQueryParam,
	setWeekQueryParams,
	firebaseApi,
}: CreateProps): JSX.Element => {
	const [state, dispatch] = useReducer(
		createTimesheetReducer,
		{ userDetails, sites, weekEnding: weekEndingQueryParam, loading },
		createInitialCreateState,
	);

	const companyOptions = useMemo(() => {
		const filteredCompanies = Object.values(companies)
			.filter(
				(company) =>
					(state.authorizedActions.canChangeClient &&
						company.companyType === CompanyTypes.construction) ||
					(!state.authorizedActions.canChangeClient &&
						company.id === userDetails.companyID) ||
					(state.authorizedActions.isRecruitmentSite &&
						company.id === userDetails.companyID),
			)
			.reduce<Record<string, Company>>((companies, company) => {
				companies[company.id] = company;
				return companies;
			}, {});
		return filteredCompanies;
	}, [
		companies,
		state.authorizedActions.canChangeClient,
		state.authorizedActions.isRecruitmentSite,
		userDetails.companyID,
	]);

	// ../Timesheets.tsx isn't handling loading properly
	// results in changing tab not defaulting site but page reload does.
	// This is a temporary fix to ensure the site is selected but at a lag
	useEffect(() => {
		const defaultSite = sites[userDetails.siteID];
		if (defaultSite && !state.selectedSite) {
			dispatch(selectSite(defaultSite));
		}
		// We don't want this to run every time state.selectedSite changes
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sites, userDetails.siteID]);

	// Also a side effect of the parent component not handling loading properly
	// only load on initial load
	useEffect(() => {
		if (state.initialLoad && !loading) {
			dispatch(updateLoading(loading));
		}
	}, [loading, state.initialLoad]);

	useEffect(() => {
		if (
			state.selectedWorker !== null &&
			state.selectedClient !== null &&
			state.selectedSite !== null &&
			state.selectedWeekEnding !== null
		) {
			return firebaseApi.userSitelogsForWeekSubscription(
				state.selectedWorker.userID,
				state.selectedSite.id,
				state.selectedWeekEnding,
				(siteLogs) => {
					dispatch(updateSiteLogs(siteLogs));
				},
			);
		} else {
			dispatch(updateSiteLogs([]));
		}
	}, [
		firebaseApi,
		state.selectedClient,
		state.selectedSite,
		state.selectedWeekEnding,
		state.selectedWorker,
	]);

	const handleSelectWorker = (userID: string): void => {
		dispatch(selectWorker(users[userID] ?? null));
	};

	const handleSelectClient = (
		client: Pick<Company, 'id' | 'name'> | null,
	): void => {
		dispatch(selectClient(client));
	};

	const handleSelectSite = (site: Site | null): void => {
		dispatch(selectSite(site));
	};

	const handleSelectTimesheetStatus = (status: NewTimesheetStatus): void => {
		dispatch(selectTimesheetStatus(status));
	};

	const handleSelectWeekEnding = (date: Date | null): void => {
		const weekEnding = date ?? endOfWeek(new Date());
		setWeekQueryParams(weekEnding);
		dispatch(selectWeekEnding(weekEnding));
	};

	const handleAddActivity = (day: DayString): void => {
		dispatch(addActivity(day));
	};

	const handleUpdateActivity = (activity: NewActivity): void => {
		dispatch(updateActivity(activity));
	};

	const handleDeleteActivity = (day: DayString, id: string): void => {
		dispatch(deleteActivity(day, id));
	};

	return state.initialLoad ? (
		<LoadingDots />
	) : (
		<Paper elevation={0} sx={{ overflow: 'auto' }}>
			<Stack
				height="calc(100vh - 189px)"
				spacing={1}
				minWidth={TABLE_MIN_WIDTH}>
				<CreateHeader
					users={Object.values(users)}
					companies={companyOptions}
					sites={sites}
					selectedWorker={state.selectedWorker}
					handleSelectWorker={handleSelectWorker}
					selectedClient={state.selectedClient}
					handleSelectClient={handleSelectClient}
					selectedTimesheetStatus={state.selectedTimesheetStatus}
					handleSelectTimesheetStatus={handleSelectTimesheetStatus}
					selectedSite={state.selectedSite}
					handleSelectSite={handleSelectSite}
					selectedWeekEnding={state.selectedWeekEnding}
					handleSelectedWeekEnding={handleSelectWeekEnding}
					disableClientSelect={
						!state.authorizedActions.canChangeClient ||
						state.authorizedActions.isRecruitmentSite
					}
					disableSiteSelect={!state.authorizedActions.canChangeSite}
					disableStatusSelect={
						!state.authorizedActions.canChangeStatus
					}
					headerErrors={state.headerErrors}
				/>
				<CreateWeekList
					activityOptions={
						state.selectedSite?.timesheetActivitiesV2 ?? []
					}
					siteLogs={splitSiteLogsByDay(state.siteLogs)}
					activities={state.activities}
					addActivity={handleAddActivity}
					updateActivity={handleUpdateActivity}
					deleteActivity={handleDeleteActivity}
				/>
			</Stack>
		</Paper>
	);
};

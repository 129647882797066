import CheckCircleIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import OutboundOutlinedIcon from '@mui/icons-material/OutboundOutlined';
import { Tooltip } from '@mui/material';
import { add, endOfWeek, sub } from 'date-fns';
import type { FirebaseApi } from '../../firebase/firebaseApi';
import { ContractStatus } from '../Contract';
import { PayAndChargeOutRate, Timesheet } from './Timesheet';
import { MultiStageStatus, TimesheetStatus } from './TimesheetStatus';

export const getRates = async (
	employeeID: string,
	siteID: string,
	supplierCompanyID: string,
	accepterCompanyID: string | undefined,
	firebaseApi: Pick<
		FirebaseApi,
		| 'getLimitedContractsByEmployeeSiteSupplierAccepterStatus'
		| 'getLimitedContractByEmployeeSupplierAccepterStatus'
		| 'getUser'
	>,
): Promise<PayAndChargeOutRate> => {
	let rates: PayAndChargeOutRate | null = null;

	if (accepterCompanyID) {
		// Check if there is a contract that is an exact match
		const contractsWithSite =
			await firebaseApi.getLimitedContractsByEmployeeSiteSupplierAccepterStatus(
				employeeID,
				siteID,
				supplierCompanyID,
				accepterCompanyID,
				ContractStatus.Accepted,
				1,
			);
		if (contractsWithSite.length > 0) {
			// there should only ever be one or none of these, but we don't enforce that
			const contract = contractsWithSite[0];
			rates = {
				id: contract.id,
				source: 'Contract',
				payRate: contract.payRate,
				chargeOutRate: contract.chargeOutRate,
			};
		}
		if (!rates) {
			// if there was none, check if there was a contract without a site specified
			const contractsWithoutSite =
				await firebaseApi.getLimitedContractByEmployeeSupplierAccepterStatus(
					employeeID,
					supplierCompanyID,
					accepterCompanyID,
					ContractStatus.Accepted,
					1,
				);

			if (contractsWithoutSite.length > 0) {
				// there should only ever be one or none of these
				const contract = contractsWithoutSite[0];
				rates = {
					id: contract.id,
					source: 'Contract',
					payRate: contract.payRate,
					chargeOutRate: contract.chargeOutRate,
				};
			}
		}
		if (!rates) {
			// fall back to active status
			const activeContractsWithSite =
				await firebaseApi.getLimitedContractsByEmployeeSiteSupplierAccepterStatus(
					employeeID,
					siteID,
					supplierCompanyID,
					accepterCompanyID,
					ContractStatus.Active,
					1,
				);

			if (activeContractsWithSite.length > 0) {
				// there should only ever be one or none of these, but we don't enforce that
				const contract = activeContractsWithSite[0];
				rates = {
					id: contract.id,
					source: 'Contract',
					payRate: contract.payRate,
					chargeOutRate: contract.chargeOutRate,
				};
			}
		}
		if (!rates) {
			// fall back to active status without a site specified
			const activeContractsWithoutSite =
				await firebaseApi.getLimitedContractByEmployeeSupplierAccepterStatus(
					employeeID,
					supplierCompanyID,
					accepterCompanyID,
					ContractStatus.Active,
					1,
				);

			if (activeContractsWithoutSite.length > 0) {
				// there should only ever be one or none of these
				const contract = activeContractsWithoutSite[0];
				rates = {
					id: contract.id,
					source: 'Contract',
					payRate: contract.payRate,
					chargeOutRate: contract.chargeOutRate,
				};
			}
		}
	}

	if (rates === null) {
		// fallback to rates on userDoc
		const user = await firebaseApi.getUser(employeeID);
		rates = {
			source: 'Employee',
			chargeOutRate: user.chargeOut,
			payRate: user.chargeOut,
		};
	}

	return rates;
};

export const statusIcon = (
	status: TimesheetStatus | MultiStageStatus | null,
	extraDetail?: string | null | undefined,
): JSX.Element => {
	const icons: Record<
		TimesheetStatus | MultiStageStatus | 'null',
		JSX.Element
	> = {
		null: <ErrorOutlineIcon color="error" />,
		[TimesheetStatus.Active]: <ErrorOutlineIcon color="error" />,
		[TimesheetStatus.Approved]: <CheckCircleIcon color="success" />,
		[TimesheetStatus.Archived]: <CheckCircleIcon color="disabled" />,
		[TimesheetStatus.Submitted]: <OutboundOutlinedIcon color="secondary" />,
		[MultiStageStatus.PreApproved]: <CheckCircleIcon color="secondary" />,
	};

	return (
		<Tooltip
			title={`${status ?? 'Not Created'}${
				extraDetail !== null && extraDetail !== undefined
					? ` ${extraDetail}`
					: ''
			}`}>
			{icons[status ?? 'null']}
		</Tooltip>
	);
};

export const newTimesheetStatuses = [
	TimesheetStatus.Submitted,
	TimesheetStatus.Approved,
] as const;
export type NewTimesheetStatus = (typeof newTimesheetStatuses)[number];

const sortOrder = {
	[TimesheetStatus.Active]: 1,
	[TimesheetStatus.Approved]: 2,
	[TimesheetStatus.Archived]: 3,
	[TimesheetStatus.Submitted]: 0,
} as const;

/** Provides a default ordering of timesheets details view, by their status, then user name
 *
 * Also de-dupes, in case we're combining multiple sources
 */

export const timesheetsListSort = (timesheets: Timesheet[]): Timesheet[] =>
	Object.values(
		timesheets
			.sort(
				(timesheetA, timesheetB) =>
					sortOrder[timesheetA.timesheetStatus] -
						sortOrder[timesheetB.timesheetStatus] ||
					timesheetA.employee.name.localeCompare(
						timesheetB.employee.name,
					),
			)
			.reduce<Record<string, Timesheet>>(
				(acc, timesheet) => ({ ...acc, [timesheet.id]: timesheet }),
				{},
			),
	);

export const roundHours = (num: number): string =>
	Number.isInteger(num) ? num.toString() : num.toFixed(2);

export const validTimesheetDateRange = (
	today: Date,
): { minDate: Date; maxDate: Date } => ({
	minDate: sub(endOfWeek(today), {
		weeks: 3,
	}),
	maxDate: add(endOfWeek(today), {
		weeks: 1,
	}),
});

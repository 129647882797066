import AddIcon from '@mui/icons-material/Add';
import { Box, BoxProps, IconButton, Tooltip } from '@mui/material';

export type AddActivityButtonProps = {
	disabled: boolean;
	addActivity: () => void;
	buttonContainerProps?: BoxProps;
};

export const AddActivityButton = ({
	disabled,
	addActivity,
	buttonContainerProps,
}: AddActivityButtonProps): JSX.Element => {
	return (
		<Tooltip title="Add Activity">
			<Box {...buttonContainerProps}>
				<IconButton
					disabled={disabled}
					color="primary"
					onClick={addActivity}>
					<AddIcon fontSize="small" />
				</IconButton>
			</Box>
		</Tooltip>
	);
};

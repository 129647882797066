import type firebase from 'firebase';
import { Company, Site, SiteStatus } from '../../constants/Common';
import { InductionConfig, InductionStep } from '../../constants/InductionStep';
import { Firestore } from '../firebase';

const COLLECTION = 'sites';

type SitesCallback = (sites: Record<string, Site & { id: string }>) => void;

const siteConverter: firebase.firestore.FirestoreDataConverter<Site> = {
	toFirestore: (model) => model,
	fromFirestore: (snapshot, _) =>
		({ ...snapshot.data(), id: snapshot.id } as Site),
};

const sitesSnapshot = (
	query: firebase.firestore.Query<firebase.firestore.DocumentData>,
	callback: SitesCallback,
): (() => void) =>
	query.onSnapshot((snapshot) =>
		callback(
			Object.fromEntries(
				snapshot.docs.map((doc) => [
					doc.id,
					{
						...(doc.data() as Site),
						id: doc.id,
					},
				]),
			),
		),
	);

const getSite = async (siteID: string): Promise<Site | undefined> => {
	const doc = await Firestore.collection(COLLECTION).doc(siteID).get();
	return doc.exists ? { ...(doc.data() as Site), id: doc.id } : undefined;
};

const siteSubscription = (
	siteID: string,
	callback: (site: Site) => void,
): (() => void) =>
	Firestore.collection(COLLECTION)
		.doc(siteID)
		.onSnapshot((docSnapshot) =>
			callback({
				...(docSnapshot.data() as Site),
				id: docSnapshot.id,
			}),
		);

const getActiveSites = async (): Promise<
	firebase.firestore.QuerySnapshot<Site>
> =>
	await Firestore.collection(COLLECTION)
		.where('status', '==', SiteStatus.Active)
		.withConverter(siteConverter)
		.get();

const getActiveSitesByCompany = async (
	companyID: string,
): Promise<firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>> =>
	await Firestore.collection(COLLECTION)
		.where('status', '==', SiteStatus.Active)
		.where('companyID', '==', companyID)
		.withConverter(siteConverter)
		.get();

const activeSitesByCompanySubscription = (
	companyID: string,
	callback: SitesCallback,
): (() => void) =>
	sitesSnapshot(
		Firestore.collection(COLLECTION)
			.where('status', '==', SiteStatus.Active)
			.where('companyID', '==', companyID),
		callback,
	);

const activeSitesSubscription = (callback: SitesCallback): (() => void) =>
	sitesSnapshot(
		Firestore.collection(COLLECTION).where(
			'status',
			'==',
			SiteStatus.Active,
		),
		callback,
	);

const activeSitesByCompanyTypeSubscription = (
	companyType: Company['companyType'],
	callback: SitesCallback,
): (() => void) =>
	sitesSnapshot(
		Firestore.collection(COLLECTION)
			.where('status', '==', SiteStatus.Active)
			.where('companyType', '==', companyType),
		callback,
	);

const activeSitesCountByCompanySubscription = (
	companyID: string,
	callback: (count: number) => void,
): (() => void) =>
	Firestore.collection(COLLECTION)
		.where('status', '==', SiteStatus.Active)
		.where('companyID', '==', companyID)
		.onSnapshot((snapshot) => {
			callback(snapshot.size);
		});

const updateSiteActivities = async (
	siteID: string,
	updatedSiteActivities: Pick<Site, 'timesheetActivitiesV2'>,
): Promise<void> =>
	await Firestore.collection(COLLECTION)
		.doc(siteID)
		.update(updatedSiteActivities);

const siteInductionConfigSubscription = (
	siteID: string,
	callback: (inductionConfig: InductionConfig | null) => void,
): (() => void) => {
	return Firestore.collection(COLLECTION)
		.doc(siteID)
		.collection('inductionConfig')
		.doc(siteID)
		.onSnapshot((doc) => {
			if (doc.exists) {
				const data = doc.data();
				callback(data as InductionConfig);
			} else {
				callback(null);
			}
		});
};

const siteInductionStepsSubscription = (
	siteID: string,
	callback: (inductionConfig: Record<string, InductionStep>) => void,
): (() => void) => {
	return Firestore.collection(COLLECTION)
		.doc(siteID)
		.collection('inductionConfig')
		.doc(siteID)
		.collection('inductionSteps')
		.onSnapshot((docsSnapshot) => {
			const data: Record<string, InductionStep> = {};
			docsSnapshot.forEach((doc) => {
				data[doc.id] = doc.data() as InductionStep;
			});
			callback(data);
		});
};

const sitesByCompanySubscription = (
	companyID: string,
	callback: SitesCallback,
): (() => void) =>
	sitesSnapshot(
		Firestore.collection(COLLECTION).where('companyID', '==', companyID),
		callback,
	);

const searchSitesByName = async (
	filterBySiteName: string,
): Promise<Record<string, Site>> => {
	const snapshot = await Firestore.collection(COLLECTION)
		.where('name', '>=', filterBySiteName)
		.where('name', '<=', filterBySiteName + '\uf8ff')
		.withConverter(siteConverter)
		.get();
	const record: Record<string, Site> = {};
	snapshot.docs.forEach((doc) => {
		record[doc.id] = doc.data();
	});
	return record;
};

const searchSitesByCompanyName = async (
	filterByCompanyName: string,
): Promise<Record<string, Site>> => {
	const snapshot = await Firestore.collection(COLLECTION)
		.where('company', '>=', filterByCompanyName)
		.where('company', '<=', filterByCompanyName + '\uf8ff')
		.withConverter(siteConverter)
		.get();
	const record: Record<string, Site> = {};
	snapshot.docs.forEach((doc) => {
		record[doc.id] = doc.data();
	});
	return record;
};

const searchSitesByNameCompanyName = async (
	filterBySiteName: string,
	filterByCompanyName: string,
): Promise<Record<string, Site>> => {
	const snapshot = await Firestore.collection(COLLECTION)
		.where('name', '>=', filterBySiteName)
		.where('name', '<=', filterBySiteName + '\uf8ff')
		.where('company', '==', filterByCompanyName)
		.withConverter(siteConverter)
		.get();
	const record: Record<string, Site> = {};
	snapshot.docs.forEach((doc) => {
		record[doc.id] = doc.data();
	});
	return record;
};

const sitesFirebaseApi = {
	getSite,
	siteSubscription,
	updateSiteActivities,
	getActiveSites,
	getActiveSitesByCompany,
	activeSitesByCompanySubscription,
	activeSitesSubscription,
	activeSitesCountByCompanySubscription,
	siteInductionConfigSubscription,
	siteInductionStepsSubscription,
	sitesByCompanySubscription,
	searchSitesByName,
	searchSitesByCompanyName,
	searchSitesByNameCompanyName,
	activeSitesByCompanyTypeSubscription,
};

export default sitesFirebaseApi;

import {
	MUIDataTableColumnDef,
	MUIDataTableMeta,
	MUIDataTableOptions,
} from 'mui-datatables';
import { ReactNode } from 'react';
import { ExplicitAny } from './AnyTypes';

export type InternalData = { index: number; data: ExplicitAny[] }[];

const filterColumns = <T>(array: T[], excludedColumns: number[]): T[] =>
	array.filter((_, index) => !excludedColumns.includes(index));

export const onDownload: (
	columnDefs: MUIDataTableColumnDef[],
	excludedColumns?: number[], // provide the index of columns to be included in the export, if you do not want all columns
) => NonNullable<MUIDataTableOptions['onDownload']> =
	(columnDefs: MUIDataTableColumnDef[], excludedColumns?: number[]) =>
	(buildHead, buildBody, headers: string[], data: InternalData) => {
		const includedHeaders = excludedColumns
			? filterColumns(headers, excludedColumns)
			: headers;

		const includedData = excludedColumns
			? data.map((col) => ({
					...col,
					data: filterColumns(col.data, excludedColumns),
			  }))
			: data;

		const mappedData = includedData.map((col) => ({
			index: col.index,
			data: col.data.map((cell, index) => {
				const columnDef = columnDefs[index];
				if (typeof columnDef === 'string') {
					return cell;
				}

				const options = columnDef.options;
				// Get the representation of the cell, as seen in the actual table
				let rendered: string | number | ReactNode;
				if (
					!options?.customBodyRender &&
					!options?.customBodyRenderLite
				) {
					// mui will render the cell value as-is
					rendered = cell;
				} else if (options.customBodyRender) {
					rendered = options.customBodyRender(
						cell,
						{} as MUIDataTableMeta,
						() => undefined,
					);
				} else if (options.customBodyRenderLite) {
					rendered = options.customBodyRenderLite(col.index, index);
				}

				// check whether we should use the render or the raw data
				if (
					typeof rendered === 'string' ||
					typeof rendered === 'number'
				) {
					return rendered;
				} else if (
					typeof rendered !== 'string' &&
					(typeof cell === 'string' || typeof cell === 'number')
				) {
					// if we rendered  a node, then get the raw string interpretation instead
					return cell;
				} else {
					console.warn(
						'Neither the cell nor the rendering of that cell is easily representable as a number or a string',
						cell,
						rendered,
					);
					return JSON.stringify(cell);
				}
			}),
		}));

		return '\uFEFF' + buildHead(includedHeaders) + buildBody(mappedData);
	};

import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import { Avatar, Box, Stack, Typography } from '@mui/material';
import { DefaultTheme } from '../../../../Theme';

export enum ReviewStatus {
	Pending = 'Pending',
	PreApproved = 'PreApproved',
	Reviewed = 'Reviewed',
}

export type ReviewerCardProps = {
	review:
		| { status: ReviewStatus.Pending; pendingReviewCompany: string | null }
		| {
				status: ReviewStatus.PreApproved;
				hours: number;
				name: string;
				reviewedAt: string;
		  }
		| {
				status: ReviewStatus.Reviewed;
				name: string;
				reviewedAt: string;
		  };
};

export const ReviewerCard = ({ review }: ReviewerCardProps): JSX.Element => (
	<Stack direction="row" spacing={2} alignItems="flex-start">
		{review.status === ReviewStatus.Pending ? (
			<>
				<Avatar
					sx={{
						backgroundColor: DefaultTheme.palette.tertiary.default,
						color: DefaultTheme.palette.info.contrastText,
					}}>
					<PendingActionsIcon color="inherit" />
				</Avatar>
				<Box>
					<Typography variant="h7Bold">Pending Review</Typography>
					<Typography fontSize="small">
						{review.pendingReviewCompany ?? 'Unknown'}
					</Typography>
				</Box>
			</>
		) : review.status === ReviewStatus.PreApproved ? (
			<>
				<Avatar
					sx={{
						backgroundColor: DefaultTheme.palette.tertiary.default,
						color: DefaultTheme.palette.info.contrastText,
					}}>
					<AssignmentTurnedInOutlinedIcon color="inherit" />
				</Avatar>
				<Box>
					<Typography variant="h7Bold">
						Pre Approved {review.hours}hrs
					</Typography>
					<Typography fontSize="small">{review.name}</Typography>
					<Typography fontSize="small">
						{review.reviewedAt}
					</Typography>
				</Box>
			</>
		) : (
			<>
				<Avatar
					sx={{
						backgroundColor: DefaultTheme.palette.tertiary.default,
						color: DefaultTheme.palette.info.contrastText,
					}}>
					<AssignmentTurnedInOutlinedIcon color="inherit" />
				</Avatar>
				<Box>
					<Typography variant="h7Bold">Reviewed</Typography>
					<Typography fontSize="small">{review.name}</Typography>
					<Typography fontSize="small">
						{review.reviewedAt}
					</Typography>
				</Box>
			</>
		)}
	</Stack>
);

// Common types used across the app
import firebase from 'firebase';
import { EmailNotificationType } from '../components/AccountSettings/NotificationToggle/EmailNotificationToggle';
import { Timestamp } from '../firebase/firebase';
import { InvoicingType } from '../models/Integrations/InvoicingIntegration';
import { PayrollType } from '../models/Integrations/PayrollIntegration';
import { ProjectTrackingType } from '../models/Integrations/ProjectTrackingIntegration';
import { FixMeLater } from './AnyTypes';
import { TimesheetStatus } from './Timesheet/TimesheetStatus';

export type UserDetails = {
	userID: string;
	status: UserStatusType;
	accountType: AccountType;
	chargeOut: number;
	company: string;
	companyID: string;
	displayName: string;
	email: string;
	firstname: string;
	lastname: string;
	mobileNumber: string;
	photoURL: string;
	signInPicNotRequired: boolean;
	signedIn: boolean;
	site: string;
	siteID: string;
	siteCompany: string;
	siteCompanyID: string;
	workerType: WorkerType;
	contractedTo: {
		name: string;
		id: string;
	} | null;
	recentSites: (Pick<Site, 'id' | 'name' | 'companyID'> & {
		companyName: string;
	})[];
	recentClients: Minimal<Company>[];
	disabledLeave?: boolean;
	disableTimesheetApproval?: boolean;
	disabledNotifications?: Partial<Record<EmailNotificationType, true>>;
	hiddenMenuItemsPreferences?: Record<string, true>;
	// Kiosk users only
	batteryLevel?: number;
	pin?: [number, number, number, number];
};

export type MinimalUserDetails = {
	id: string;
	name: string;
};

export type Company = {
	id: string;
	name: string;
	logoLocation?: string;
	companyType: CompanyType;
	canCreateSites?: boolean;
	multiStageApproval?: boolean;
	payrollIntegrated?: PayrollType;
	invoicingIntegrated?: InvoicingType;
	projectTrackingIntegrated?: ProjectTrackingType;
	subscriptionStatus?: (typeof SubscriptionStatuses)[keyof typeof SubscriptionStatuses];
};

export type EmployeeRates = {
	id: string;
	chargeOutRate: number;
	payRate: number;
	onCost: number;
	margin: number;
};

export type CompanyEmployeeRates = Record<UserDetails['userID'], EmployeeRates>;

export const SubscriptionStatuses = {
	Active: 'active',
	Canceled: 'canceled',
	Incomplete: 'incomplete',
	IncompleteExpired: 'incomplete_expired',
	PastDue: 'past_due',
	Trialing: 'trialing',
	Unpaid: 'unpaid',
} as const;

export type SubscriptionDates = {
	trialEnd: number | null;
	trialStart: number | null;
};

export type StripeGetCustomerOutstandingPaymentsResponse =
	| {
			status: typeof stripeBillingStatus.Paid;
	  }
	| {
			status: typeof stripeBillingStatus.Outstanding;
			unpaidInvoicesCount: number;
			totalCost: number;
			unpaidInvoices: OutstandingStripeInvoice[];
	  };

type OutstandingStripeInvoice = {
	invoiceNumber: string | null;
	invoiceURL: string | null | undefined;
	dateIssued: number;
	dueDate: number | null;
	currency: string;
	amountRemaining: number;
};

export const stripeBillingStatus = {
	Outstanding: 'Outstanding',
	Paid: 'Paid',
} as const;

export type StripeBillingStatus =
	(typeof stripeBillingStatus)[keyof typeof stripeBillingStatus];

export const companyTypes = ['recruitment', 'construction'] as const;
export const CompanyTypes: { [companyType in CompanyType]: companyType } = {
	recruitment: 'recruitment',
	construction: 'construction',
};
export type CompanyType = (typeof companyTypes)[number];

export const isCompanyType = (
	possibleCompanyType: string,
): possibleCompanyType is CompanyType => {
	return companyTypes.includes(possibleCompanyType as FixMeLater);
};

export const CompanyTypeName: Record<CompanyType, string> = {
	[CompanyTypes.recruitment]: 'Recruitment Agency',
	[CompanyTypes.construction]: 'Construction Company',
} as const;

export const workerTypes = [
	'Labourer',
	'Carpenter',
	'Safety Manager',
	'Site Manager',
	'Hammerhand',
	'Not Selected',
] as const;
export type WorkerType = (typeof workerTypes)[number];

// Can't just call this Day, as it would stomp on Day in date-fns
export const dayStrings = [
	'Monday',
	'Tuesday',
	'Wednesday',
	'Thursday',
	'Friday',
	'Saturday',
	'Sunday',
] as const;
export type DayString = (typeof dayStrings)[number];
export const lowercaseDayString: (day: DayString) => Lowercase<DayString> = (
	day: DayString,
): Lowercase<DayString> => day.toLowerCase() as Lowercase<DayString>;

export type Activity = {
	id: string;
	date: Timestamp;
	day: DayString;
	displayDay: string;
	employerName: string;
	employerID: string;
	hours: number;
	rate: number;
	status: TimesheetStatus;
	timesheetID: string;
	totalCost: number;
	week: Timestamp;
	weekEnding: Timestamp;
	workerID: string;
	workerName: string;
	activity: {
		name: string;
		id: string;
	};
	siteCompany: string;
	siteCompanyID: string;
	siteID: string;
	siteName: string;
	finalReviewBy: string | null;
	finalReviewAt: Timestamp | null;
};

export type EntriesAddToDay = Pick<
	Activity,
	| 'week'
	| 'weekEnding'
	| 'employerName'
	| 'employerID'
	| 'siteCompany'
	| 'siteCompanyID'
	| 'siteID'
	| 'siteName'
	| 'status'
	| 'timesheetID'
	| 'workerID'
	| 'workerName'
	| 'rate'
>;

export type UserProps = {
	user?: firebase.User | null;
	userDetails: UserDetails;
};

export const SiteLogType = {
	In: 'In',
	Out: 'Out',
} as const;

export type SiteLog = {
	id: string;
	company: string;
	companyID: string | null;
	datetime: Timestamp;
	site: string;
	siteID: string;
	siteCompany: string;
	siteCompanyID: string;
	siteStartTime: number;
	takenBy: string;
	type: (typeof SiteLogType)[keyof typeof SiteLogType];
	url: string;
	workerID: string;
	isGuest: boolean;
	contractedTo: { id: string; name: string } | null;
	// Used for displaying date times in csv data
	dateString?: string;
	timeString?: string;
};

export type Guest = {
	id: string;
	company: string;
	displayName: string;
	signedIn: boolean;
	site: string;
	siteID: string;
	mobileNumber: string;
	email?: string;
};

/**
 * Evacuee doc ID is the same as the user/guest doc ID
 */
export type Evacuee = {
	isGuest: boolean;
	isSafe: boolean;
	name: string;
};

export type Evacuation = {
	endTime: Timestamp;
	note: string;
	site: string;
	siteCompany: string;
	siteCompanyID: string;
	siteID: string;
	startTime: Timestamp;
	totalPeople: number;
	totalPeopleSafe: number;
};

export type Month =
	| 'jan'
	| 'feb'
	| 'mar'
	| 'apr'
	| 'may'
	| 'jun'
	| 'jul'
	| 'aug'
	| 'sep'
	| 'oct'
	| 'nov'
	| 'dec';

export enum SiteStatus {
	Active = 'active',
	Archived = 'archived',
}

export type Site = {
	id: string;
	address: string;
	allowAppSignIn: boolean;
	autoSignOutTime: keyof typeof AutoSignOutTimes;
	city: string;
	company: string;
	companyID: string;
	companyType: Company['companyType'];
	createdBy: string;
	createdByID: string;
	jobNumber: string;
	name: string;
	region: string;
	showCovidWarning: boolean;
	siteContact: string;
	siteContactNumber: string;
	status: SiteStatus;
	timesheetActivitiesV2: TimesheetActivity[];
	startTime: number; // represents milliseconds since 12am NZDT
	hasInductions: boolean;
	safetyCourseRequiredForInduction: boolean;
};

export const AutoSignOutTimes = {
	// technically any value not in the 24 hour clock will work, but we use -1 as a signal value to get a nice message on front end for users
	[-1]: 'No Auto Signout Time',
	0: '12:00 am',
	1: '1:00 am',
	2: '2:00 am',
	3: '3:00 am',
	4: '4:00 am',
	5: '5:00 am',
	6: '6:00 am',
	7: '7:00 am',
	8: '8:00 am',
	9: '9:00 am',
	10: '10:00 am',
	11: '11:00 am',
	12: '12:00 pm',
	13: '1:00 pm',
	14: '2:00 pm',
	15: '3:00 pm',
	16: '4:00 pm',
	17: '5:00 pm',
	18: '6:00 pm',
	19: '7:00 pm',
	20: '8:00 pm',
	21: '9:00 pm',
	22: '10:00 pm',
	23: '11:00 pm',
} as const;

export enum ActivityFieldFriendlyType {
	number = 'Number',
	string = 'Text',
	Timestamp = 'Date',
}

export type ActivityFieldType = string | number | Timestamp;

export type TimesheetActivity = {
	id: string;
	activityName: string;
	active: boolean;
};

// https://tradelegion.atlassian.net/wiki/spaces/DOC/pages/17465345/Work+History
export type HoursRecord = Record<
	'Total',
	{ hoursWorked: number; activityName: 'Total' }
> &
	Record<string, HoursComponent>;

export type HoursComponent = { hoursWorked: number; activityName: string };
export type WorkHistory = {
	company: Minimal<Company>;
	site: {
		id: string;
		name: string;
		companyName: string;
		companyId: string;
		address: string;
	};
	activityHours: HoursRecord;
	startDate: Timestamp;
	endDate: Timestamp;
	contractedTo: Minimal<Company> | null;
};

export type Residency =
	| 'nzCitizen'
	| 'nzPermanentResident'
	| 'australianCitizen'
	| 'australianPermanentResident'
	| 'nzWorkVisa'
	| 'other';

export const ResidencyHumanName: Record<Residency, string> = {
	nzCitizen: 'NZ Citizen',
	nzWorkVisa: 'NZ Work Visa',
	nzPermanentResident: 'NZ Permanent Resident',
	australianCitizen: 'Australian Citizen',
	australianPermanentResident: 'Australian Permanent Resident',
	other: 'Other',
};

export type License =
	| 'none'
	| 'learners'
	| 'restricted'
	| 'full'
	| 'class2'
	| 'class3'
	| 'class5'
	| 'class6';

export const LicenseHumanName: Record<License, string> = {
	none: 'None',
	learners: 'Learners',
	restricted: 'Restricted',
	full: 'Full',
	class2: 'Medium Rigid Vehicle (Class 2)',
	class3: 'Medium Combination Vehicle (Class 3)',
	class5: 'Heavy Rigid Vehicle (Class 4)',
	class6: 'Heavy Combination Vehicle (Class 5)',
};

export type UserProfile = {
	id: string;
	company: {
		id: string;
		name: string;
	};
	displayName: string;
	profileImg: string;
	// We need to make so many of these optional as we don't have data for them automatically, but will need to keep the totalHours and workHistory collectoin updated
	residency?: Residency;
	license?: License;
	transport?: boolean;
	location?: string;
	dateOfBirth?: Timestamp;
	siteSafe?: boolean;
	cv?: string;
	// This model makes a big assumption which is that, while activtyIDs are NOT globally unique,
	// two identical activityIDs from different sites will be similar enough to aggregate together
	totalHours: HoursRecord;
};
export type MinimalInterface = {
	name: string;
	id: string;
};
export type Minimal<T extends MinimalInterface> = Pick<T, 'name' | 'id'>;

export enum UserStatusType {
	Active = 'Active',
	Inactive = 'Inactive',
}

export type AccountType =
	| ''
	| 'handler'
	| 'management'
	| 'thirdPartyWorker'
	| 'cx'
	| 'seniorManagement'
	| 'worker';

export const AccountTypeHumanName: Record<AccountType, string> = {
	'': 'Unknown',
	handler: 'Handler',
	management: 'Management',
	thirdPartyWorker: 'Third Party Employee',
	cx: 'Trade Legion Customer Experience',
	seniorManagement: 'Senior Management',
	worker: 'Employee',
};
export const accountTypes: { [accountType in AccountType]: accountType } = {
	'': '',
	handler: 'handler',
	management: 'management',
	thirdPartyWorker: 'thirdPartyWorker',
	cx: 'cx',
	seniorManagement: 'seniorManagement',
	worker: 'worker',
};

export const selectableAccountTypes = [
	'handler',
	'management',
	'thirdPartyWorker',
	'cx',
	'seniorManagement',
	'worker',
] as const;
export const ManageSubscriptionAccountTypes: UserDetails['accountType'][] = [
	'seniorManagement',
	'handler',
];

export type AccountTypeSubset<T extends AccountType> = T;
export type AccountTypeData = {
	redirect: string;
	filterAllSites: boolean | null;
	display: 'company' | 'site';
};

export const getAccountTypes = (
	user: UserDetails | null,
	company: Company | null,
): AccountTypeData => {
	switch (user?.accountType) {
		case 'handler': {
			return company?.canCreateSites
				? {
						redirect: '/dashboard',
						filterAllSites: false,
						display: 'site',
				  }
				: {
						redirect: '/dashboard',
						filterAllSites: null,
						display: 'company',
				  };
		}
		case 'management':
		case 'seniorManagement': {
			return {
				redirect: '/dashboard',
				filterAllSites: false,
				display: 'site',
			};
		}
		case 'cx': {
			return {
				redirect: '/dashboard',
				filterAllSites: null,
				display: 'company',
			};
		}
		case 'worker':
		case 'thirdPartyWorker': {
			return {
				redirect: '/dashboard',
				filterAllSites: true,
				display: 'site',
			};
		}
		case '': {
			return {
				redirect: '/account-information',
				filterAllSites: null,
				display: 'company',
			};
		}
		case undefined: {
			return {
				redirect: '',
				filterAllSites: null,
				display: 'company',
			};
		}
	}
};

export function assertAccountType<T extends AccountType>(
	item: T | AccountType | undefined,
	list: readonly string[],
): asserts item is T {
	if (item === undefined || !list.includes(item as T)) {
		throw new Error(
			`User of accountType ${item} not authorized to view this page`,
		);
	}
}

export function* chunks<T>(arr: T[], chunkSize = 10): Generator<T[], void> {
	for (let i = 0; i < arr.length; i += chunkSize) {
		yield arr.slice(i, i + chunkSize);
	}
}

export const emptyFunction = (): void => {
	// do nothing
};

export const emptyAsyncFunction = async (): Promise<void> => {
	// do nothing
};

export const appDrawerWidth = 240;
export const appHeaderHeight = 64;

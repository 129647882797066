import { Box, Stack, Typography } from '@mui/material';
import { memo } from 'react';
import {
	Activity,
	DayString,
	TimesheetActivity,
} from '../../../../../../constants/Common';
import { getTimeForHours } from '../../../../../../constants/stringUtilities';
import {
	ActivityEditFields,
	Timesheet,
} from '../../../../../../constants/Timesheet/Timesheet';
import { IncrementedTimeSelect } from '../IncrementedTimeSelect';
import { AddActivityButton } from './AddActivityButton';
import { EditRow } from './EditRow';

// IconButtons size
const RIGHT_ICON_BUTTON_PADDING = 4.5;

export type HoursSectionProps = {
	day: DayString;
	disabled: boolean;
	formattedLogs: string | null;
	siteActivities: TimesheetActivity[];
	activities: Pick<Activity, ActivityEditFields>[];
	activityErrors: Record<string, true>;
	timesheetHours: Pick<Timesheet['hours']['total'], 'break' | 'billable'>;
	addActivity: (day: DayString) => void;
	updateActivity: (
		id: Activity['id'],
		activity: Activity['activity'],
		hours: number,
	) => void;
	deleteActivity: (id: Activity['id']) => void;
	updateBreaks: (day: DayString, breakHours: number) => void;
};

const HoursSectionImpl = ({
	day,
	disabled,
	formattedLogs,
	activities,
	activityErrors,
	siteActivities,
	timesheetHours,
	addActivity,
	updateActivity,
	deleteActivity,
	updateBreaks,
}: HoursSectionProps): JSX.Element => {
	return (
		<Stack spacing={0.5}>
			<Stack
				direction="row"
				spacing={1}
				paddingRight={RIGHT_ICON_BUTTON_PADDING}
				justifyContent="space-between">
				<Typography>Site Log Hours</Typography>
				<Typography>{formattedLogs || getTimeForHours(0)}</Typography>
			</Stack>
			<Stack
				data-testid={`${day}-breaks`}
				direction="row"
				spacing={1}
				alignItems="center"
				paddingRight={RIGHT_ICON_BUTTON_PADDING}
				justifyContent="space-between">
				<Typography>Unpaid Breaks</Typography>
				{disabled ? (
					<Typography>
						{getTimeForHours(timesheetHours.break)}
					</Typography>
				) : (
					<Box flex="0 0 auto">
						<IncrementedTimeSelect
							removeInputPadding
							maxHours={4}
							hours={timesheetHours.break}
							setHours={(hours): void => updateBreaks(day, hours)}
						/>
					</Box>
				)}
			</Stack>
			<Stack
				direction="row"
				alignItems="center"
				paddingRight={RIGHT_ICON_BUTTON_PADDING}>
				<Typography>Activities</Typography>
				<AddActivityButton
					disabled={disabled}
					addActivity={(): void => addActivity(day)}
				/>
			</Stack>
			{activities.map((activity) => (
				<EditRow
					key={`${day}-activity-${activity.id}`}
					disabled={disabled}
					activity={activity}
					error={activityErrors[activity.id]}
					siteActivities={siteActivities}
					updateActivity={updateActivity}
					deleteActivity={deleteActivity}
				/>
			))}
			<Stack
				direction="row"
				spacing={1}
				paddingTop={1}
				alignItems="center"
				paddingRight={RIGHT_ICON_BUTTON_PADDING}
				justifyContent="space-between">
				<Typography>Total Paid Hours</Typography>
				<Typography>
					{getTimeForHours(timesheetHours.billable)}
				</Typography>
			</Stack>
		</Stack>
	);
};

const propsAreEqual = (
	prevProps: Readonly<HoursSectionProps>,
	nextProps: Readonly<HoursSectionProps>,
): boolean => {
	const activitiesEqual =
		prevProps.activities.every((activity, index) => {
			const nextActivity = nextProps.activities[index];
			const errorEqual =
				prevProps.activityErrors[activity.id] ===
				nextProps.activityErrors[activity.id];
			return (
				nextActivity &&
				errorEqual &&
				activity.id === nextActivity.id &&
				activity.hours === nextActivity.hours &&
				activity.activity.id === nextActivity.activity.id &&
				activity.activity.name === nextActivity.activity.name
			);
		}) && prevProps.activities.length === nextProps.activities.length;

	const timesheetHoursEqual =
		prevProps.timesheetHours.break === nextProps.timesheetHours.break &&
		prevProps.timesheetHours.billable === nextProps.timesheetHours.billable;

	return (
		activitiesEqual &&
		timesheetHoursEqual &&
		prevProps.day === nextProps.day &&
		prevProps.disabled === nextProps.disabled &&
		prevProps.formattedLogs === nextProps.formattedLogs &&
		prevProps.siteActivities === nextProps.siteActivities
	);
};

export const HoursSection = memo(HoursSectionImpl, propsAreEqual);

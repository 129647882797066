import { Close } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Divider,
	IconButton,
	Typography,
} from '@mui/material';
import { useState } from 'react';
import type { CloudFunctionApi } from '../../../../cloudfunctions';
import { UserDetails } from '../../../../constants/Common';
import { Timesheet } from '../../../../constants/Timesheet/Timesheet';
import { TimesheetStatus } from '../../../../constants/Timesheet/TimesheetStatus';
import type { FirebaseApi } from '../../../../firebase/firebaseApi';
import { useAbortController } from '../../../../hooks/useAbortController';
import { useUserAuthContext } from '../../../../providers/UserProvider';

type NoTimesheetDialogProps = {
	employee?: UserDetails;
	weekEnding: Date;
	endDate: Date;
	timesheetID: string | null;
	timesheetEmployeeName: string;
	timesheetStatus: Timesheet['timesheetStatus'] | null;
	modalOpen: boolean;
	setModalOpen: (open: boolean) => void;
	canRemoveFromCompany: boolean;
	canRemoveFromSite: boolean;
	firebaseApi: Pick<
		FirebaseApi,
		| 'updateUserDetailsSiteInfo'
		| 'updateUserDetailsCompanyInfo'
		| 'deleteTimesheet'
	>;
	setNotificationSuccess: (success: boolean | undefined) => void;
	setRemoveSuccess: (success: boolean) => void;
	cloudFunctionApi: Pick<
		CloudFunctionApi,
		'sendTimesheetReminderNotification'
	>;
};

export const NoTimesheetDialog = ({
	employee,
	weekEnding,
	endDate,
	timesheetID,
	timesheetEmployeeName,
	timesheetStatus,
	modalOpen,
	setModalOpen,
	canRemoveFromCompany,
	canRemoveFromSite,
	firebaseApi,
	setNotificationSuccess,
	setRemoveSuccess,
	cloudFunctionApi,
}: NoTimesheetDialogProps): JSX.Element => {
	const [disableSend, setDisableSend] = useState(false);

	const user = useUserAuthContext();
	const abortSignal = useAbortController();

	const deleteTimesheet = async (): Promise<void> => {
		if (!user || !timesheetID) return;

		await firebaseApi.deleteTimesheet(abortSignal, user, timesheetID);
	};

	const removeWorkerFromSite = async (): Promise<void> => {
		if (!user) return;

		const updatedDetails: Parameters<
			typeof firebaseApi.updateUserDetailsSiteInfo
		>[1] = {
			site: '',
			siteID: '',
			siteCompany: '',
			siteCompanyID: '',
		};

		setModalOpen(false);
		try {
			// Update user details for site
			if (employee && employee.siteID !== '') {
				await firebaseApi.updateUserDetailsSiteInfo(
					employee.userID,
					updatedDetails,
				);
			}
			// Delete the timesheet if necessary
			if (timesheetID && timesheetStatus !== TimesheetStatus.Approved) {
				await deleteTimesheet();
			}

			setRemoveSuccess(true);
		} catch (err) {
			setRemoveSuccess(false);
		}
	};

	const removeWorkerFromCompany = async (): Promise<void> => {
		if (!user) return;
		const updatedDetails: Parameters<
			typeof firebaseApi.updateUserDetailsCompanyInfo
		>[1] = {
			site: '',
			siteID: '',
			siteCompany: '',
			siteCompanyID: '',
			company: '',
			companyID: '',
			contractedTo: null,
			workerType: 'Not Selected',
			accountType: '',
		};

		setModalOpen(false);
		try {
			// Update user details for company
			if (employee && employee.companyID !== '') {
				await firebaseApi.updateUserDetailsCompanyInfo(
					employee.userID,
					updatedDetails,
				);
			}

			// Delete the timesheet if necessary
			if (timesheetID && timesheetStatus !== TimesheetStatus.Approved) {
				await deleteTimesheet();
			}

			setRemoveSuccess(true);
		} catch (err) {
			setRemoveSuccess(false);
		}
	};

	const removeTimesheet = async (): Promise<void> => {
		setModalOpen(false);
		try {
			await deleteTimesheet();
			setRemoveSuccess(true);
		} catch (err) {
			setRemoveSuccess(false);
		}
	};

	const sendReminderNotification = async (): Promise<void> => {
		// we do not allow the user to send reminders to employee who are no longer employed by the company, on the site or contracted to the company
		if (!user || !employee) return;
		setDisableSend(true);
		const notificationSentSuccess =
			await cloudFunctionApi.sendTimesheetReminderNotification(
				abortSignal,
				user,
				employee.userID,
				weekEnding,
				endDate,
			);
		setModalOpen(false);
		setNotificationSuccess(notificationSentSuccess);
	};

	return (
		<>
			<Dialog
				color="default"
				fullWidth
				maxWidth="xs"
				open={modalOpen}
				onClose={(): void => setModalOpen(false)}>
				<DialogTitle>
					{timesheetEmployeeName}
					<IconButton
						onClick={(): void => {
							setModalOpen(!modalOpen);
						}}
						sx={{
							position: 'absolute',
							right: 8,
							top: 8,
						}}>
						<Close />
					</IconButton>
				</DialogTitle>
				<DialogContent>
					<>
						<Typography>
							{timesheetEmployeeName} has
							{timesheetID ? ' not submitted' : ' not created'} a
							timesheet.
						</Typography>

						{employee && (
							<Typography>
								You can send them a notification to remind them.
							</Typography>
						)}

						{(canRemoveFromCompany || canRemoveFromSite) &&
							employee && (
								<Divider>
									<Typography>OR</Typography>
								</Divider>
							)}

						{(canRemoveFromCompany ||
							canRemoveFromSite ||
							(timesheetID && !employee)) && (
							<Typography>
								If they are no longer
								{canRemoveFromCompany
									? ' employed by your company '
									: canRemoveFromSite
									? ' on this site '
									: ' contracted to your company '}
								you can
								{timesheetID
									? ' remove their timesheet'
									: ' remove them'}
								.
							</Typography>
						)}
					</>
				</DialogContent>
				<DialogActions>
					{employee && (
						<LoadingButton
							onClick={sendReminderNotification}
							loading={disableSend}
							variant="text">
							Send Reminder
						</LoadingButton>
					)}
					{(canRemoveFromCompany ||
						canRemoveFromSite ||
						(timesheetID && !employee)) && (
						<Button
							onClick={
								canRemoveFromCompany
									? removeWorkerFromCompany
									: canRemoveFromSite
									? removeWorkerFromSite
									: removeTimesheet
							}
							disabled={disableSend}>
							Remove
						</Button>
					)}
				</DialogActions>
			</Dialog>
		</>
	);
};

import { Grid, MenuItem, TextField, Typography } from '@mui/material';
import { Company, Site, UserDetails } from '../../../../constants/Common';
import {
	NewTimesheetStatus,
	newTimesheetStatuses,
	validTimesheetDateRange,
} from '../../../../constants/Timesheet/TimesheetUtilities';
import {
	CompanySelectGroup,
	SimpleCompanyAutocomplete,
} from '../../../Autocomplete/SimpleCompanyAutocomplete';
import DateWeekSelector from '../../../DateWeekSelector/DateWeekSelector';
import { SiteSelectDropdown } from '../../../SiteSelectDropdown/SiteSelectDropdown';
import { TimesheetStatusSelect } from '../../TimesheetStatusSelect';
import { HeaderFields } from './HeaderFields';

const FULLWIDTH_GRID_SIZE = 12 / 5; // Fullwidth needs equal spacing for 5 columns sorry MUI
const errorHelperText: Record<HeaderFields, string> = {
	[HeaderFields.Worker]: 'Please select a worker',
	[HeaderFields.Client]: 'Please select a client',
	[HeaderFields.Site]: 'Please select a site',
	[HeaderFields.Status]: 'Please select a status',
	[HeaderFields.WeekEnding]: 'Please select a valid week',
};

enum HeaderSiteSelectGroup {
	ClientSites = 'Client Sites',
	Sites = 'Sites',
}

export type CreateHeaderProps = {
	users: Pick<UserDetails, 'userID' | 'displayName'>[];
	companies: Record<string, Company>;
	sites: Record<string, Site>;
	selectedWorker: Pick<
		UserDetails,
		| 'userID'
		| 'company'
		| 'companyID'
		| 'contractedTo'
		| 'displayName'
		| 'recentClients'
	> | null;
	handleSelectWorker: (userID: string) => void;
	selectedClient: Pick<Company, 'id' | 'name'> | null;
	handleSelectClient: (client: Pick<Company, 'id' | 'name'> | null) => void;
	selectedSite: Site | null;
	handleSelectSite: (site: Site | null) => void;
	selectedTimesheetStatus: NewTimesheetStatus;
	handleSelectTimesheetStatus: (status: NewTimesheetStatus) => void;
	selectedWeekEnding: Date;
	handleSelectedWeekEnding: (date: Date | null) => void;
	headerErrors: Record<HeaderFields, boolean>;
	disableClientSelect: boolean;
	disableSiteSelect: boolean;
	disableStatusSelect: boolean;
};

export const CreateHeader = ({
	users,
	companies,
	sites,
	selectedWorker,
	handleSelectWorker,
	selectedClient,
	handleSelectClient,
	selectedSite,
	handleSelectSite,
	selectedTimesheetStatus,
	handleSelectTimesheetStatus,
	selectedWeekEnding,
	handleSelectedWeekEnding,
	headerErrors,
	disableClientSelect,
	disableSiteSelect,
	disableStatusSelect,
}: CreateHeaderProps): JSX.Element => {
	return (
		<Grid container padding={2} spacing={1}>
			<Grid item xs={12}>
				<Typography variant="h6Bold" lineHeight={1}>
					New Timesheet
				</Typography>
			</Grid>
			<Grid item xs={12} sm={12} md={6} lg={FULLWIDTH_GRID_SIZE}>
				<TextField
					select
					size="small"
					fullWidth
					label={HeaderFields.Worker}
					value={selectedWorker?.userID ?? ''}
					onChange={(event): void => {
						handleSelectWorker(event.target.value);
					}}
					error={headerErrors[HeaderFields.Worker]}
					helperText={
						headerErrors[HeaderFields.Worker] &&
						errorHelperText[HeaderFields.Worker]
					}>
					{users.map((user) => (
						<MenuItem key={user.userID} value={user.userID}>
							{user.displayName}
						</MenuItem>
					))}
				</TextField>
			</Grid>
			<Grid item xs={6} sm={6} md={6} lg={FULLWIDTH_GRID_SIZE}>
				<SimpleCompanyAutocomplete
					label={HeaderFields.Client}
					companies={companies}
					value={selectedClient?.id ?? null}
					groupBy={
						selectedWorker
							? {
									getGroup: (
										option: Company,
									): CompanySelectGroup =>
										selectedWorker.recentClients.some(
											(client) => client.id === option.id,
										)
											? CompanySelectGroup.RecentClients
											: CompanySelectGroup.Clients,
									order: [
										CompanySelectGroup.RecentClients,
										CompanySelectGroup.Clients,
									],
							  }
							: undefined
					}
					onChange={handleSelectClient}
					disabled={disableClientSelect}
					error={headerErrors[HeaderFields.Client]}
					helperText={
						headerErrors[HeaderFields.Client] &&
						errorHelperText[HeaderFields.Client]
					}
				/>
			</Grid>
			<Grid item xs={6} sm={6} md={4} lg={FULLWIDTH_GRID_SIZE}>
				<SiteSelectDropdown
					inputLabel={HeaderFields.Site}
					value={selectedSite}
					sites={sites}
					groupBy={
						!disableClientSelect && selectedClient
							? {
									getGroup: (
										option: Site,
									): HeaderSiteSelectGroup =>
										option.companyID === selectedClient.id
											? HeaderSiteSelectGroup.ClientSites
											: HeaderSiteSelectGroup.Sites,
									order: [
										HeaderSiteSelectGroup.ClientSites,
										HeaderSiteSelectGroup.Sites,
									],
							  }
							: undefined
					}
					onChange={(_, value): void => {
						handleSelectSite(value);
					}}
					disabled={disableSiteSelect}
					error={headerErrors[HeaderFields.Site]}
					helperText={
						headerErrors[HeaderFields.Site] &&
						errorHelperText[HeaderFields.Site]
					}
					size="small"
				/>
			</Grid>
			<Grid item xs={6} sm={6} md={4} lg={FULLWIDTH_GRID_SIZE}>
				<TimesheetStatusSelect
					value={selectedTimesheetStatus}
					onChange={handleSelectTimesheetStatus}
					statuses={newTimesheetStatuses}
					label={HeaderFields.Status}
					disabled={disableStatusSelect}
					error={headerErrors[HeaderFields.Status]}
					helperText={
						headerErrors[HeaderFields.Status] &&
						errorHelperText[HeaderFields.Status]
					}
				/>
			</Grid>
			<Grid item xs={6} sm={6} md={4} lg={FULLWIDTH_GRID_SIZE}>
				<DateWeekSelector
					weekEnding
					allowFuture
					date={selectedWeekEnding}
					onChange={handleSelectedWeekEnding}
					dateLimits={validTimesheetDateRange(new Date())}
					textFieldProps={{
						size: 'small',
						error: headerErrors[HeaderFields.WeekEnding],
						helperText:
							headerErrors[HeaderFields.WeekEnding] &&
							errorHelperText[HeaderFields.WeekEnding],
					}}
				/>
			</Grid>
		</Grid>
	);
};

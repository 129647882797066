import { Box, Typography, TextField, MenuItem } from '@mui/material';
import { Dispatch, SetStateAction } from 'react';
import { Site } from '../../../../../constants/Common';
import {
	InducteeOption,
	NewInductionValidationErrors,
	inducteeOption,
} from '../../../../../constants/InductionEntry';
import {
	SiteSelectDropdown,
	SiteSelectDropdownProps,
} from '../../../../SiteSelectDropdown/SiteSelectDropdown';

export type NewInductionTypeSiteSelectProps = {
	sites: Record<string, Site>;
	existingUserOrGuest: InducteeOption;
	setInducteeOption: (option: InducteeOption) => void;
	site: string;
	setSite: (newSiteID: string) => void;
	groupBy: SiteSelectDropdownProps['groupBy'];
	siteError: boolean;
	setValidationErrors: Dispatch<SetStateAction<NewInductionValidationErrors>>;
	disableSiteSelect: boolean;
};

export const NewInductionTypeSiteSelect = ({
	sites,
	existingUserOrGuest,
	setInducteeOption,
	site,
	setSite,
	groupBy,
	siteError,
	setValidationErrors,
	disableSiteSelect,
}: NewInductionTypeSiteSelectProps): JSX.Element => (
	<>
		<Box>
			<Typography variant="subtitle1" pb={0.5}>
				Site
			</Typography>
			<SiteSelectDropdown
				sites={sites}
				value={sites[site]}
				onChange={(_, value): void => {
					setSite(value?.id ?? '');
					setValidationErrors((prevState) => ({
						...prevState,
						site: false,
					}));
				}}
				groupBy={groupBy}
				error={siteError}
				inputLabel=""
				placeHolder="Select Site"
				size="small"
				helperText="Site is required"
				disabled={disableSiteSelect}
				disableClearable
			/>
		</Box>
		<Box>
			<Typography variant="subtitle1" pb={0.5}>
				User Or Guest
			</Typography>
			<TextField
				select
				fullWidth
				placeholder="Select Type"
				value={existingUserOrGuest}
				onChange={(event): void =>
					setInducteeOption(event.target.value as InducteeOption)
				}
				variant="outlined"
				size="small">
				{Object.entries(inducteeOption).map(([key, value]) => (
					<MenuItem key={key} value={value}>
						{value}
					</MenuItem>
				))}
			</TextField>
		</Box>
	</>
);

import { useEffect, useState } from 'react';
import { getUuid } from '../components/helpers/uuidHelpers';

export const useUuid = (): string => {
	const [uuid, setUuid] = useState(getUuid());

	useEffect(() => {
		setUuid(getUuid());
	}, []);

	return uuid;
};

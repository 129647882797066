import {
	Box,
	Divider,
	FormControl,
	Grid,
	InputLabel,
	ListItemText,
	MenuItem,
	Select,
	Stack,
	Typography,
} from '@mui/material';
import { endOfWeek, startOfToday, startOfWeek } from 'date-fns';
import { MUIDataTableColumnDef, MUIDataTableOptions } from 'mui-datatables';
import { useEffect, useMemo, useState } from 'react';
import {
	DayString,
	SiteLog,
	SiteLogType,
	UserProps,
} from '../../constants/Common';
import firebaseApi from '../../firebase/firebaseApi';
import { DateDataTable } from '../DataTable/DateDataTable';
import { ExpandDialog } from '../ExpandDialog/ExpandDialog';
import {
	formatTime,
	formatWeekdaySlashedDate,
} from '../helpers/dateFormatters';
import { getDayNumber, toZeroedMonday } from '../helpers/dateUtilities';
import { LoadableImage } from '../Image/LoadableImage';
import { LoadingDots } from '../Management/subcomponents/LoadingDots';

type SiteLogRecord = SiteLog & {
	userType: string;
};

const toSiteLogRecord = (siteLogs: SiteLog[]): Record<string, SiteLogRecord> =>
	Object.fromEntries(
		siteLogs.map((log) => [
			log.id,
			{
				userType: log.isGuest === true ? 'Guest' : 'User',
				...log,
			} as SiteLogRecord,
		]),
	);

/** Converts "Mon 1/1/1970" to "Monday"
 *
 * Also accepts arrays, and will output as an array.
 * Must be a function to accept overloads
 */
function dateStringToDay(dates: string[]): DayString[];
function dateStringToDay(dates: string): DayString;
function dateStringToDay(dates: string | string[]): DayString | DayString[] {
	const mapDate = (date: string): DayString =>
		date.split(' ')[0] as DayString;
	return typeof dates === 'string' ? mapDate(dates) : dates.map(mapDate);
}

export const SignInOutLog = ({ userDetails }: UserProps): JSX.Element => {
	const [tableData, setTableData] = useState<Array<string[]>>([]);
	const [selected, setSelected] = useState<number>(0);
	const [tableLoading, setTableLoading] = useState<boolean>(true);
	const [startDate, setStartDate] = useState<Date>(
		startOfWeek(startOfToday()),
	);
	const [companySignInList, setCompanySignInList] = useState<SiteLog[]>([]);
	const [contractedToSignInList, setContractedToSignInList] = useState<
		SiteLog[]
	>([]);
	const [signInRecord, setSignInRecord] = useState<
		Record<string, SiteLogRecord>
	>({});
	const [companySignOutList, setCompanySignOutList] = useState<SiteLog[]>([]);
	const [contractedToSignOutList, setContractedToSignOutList] = useState<
		SiteLog[]
	>([]);
	const [signOutRecord, setSignOutRecord] = useState<
		Record<string, SiteLogRecord>
	>({});
	const [signOutPhotos, setSignOutPhotos] = useState<Record<string, string>>(
		{},
	);

	const tableOptions: MUIDataTableOptions = {
		filter: true,
		tableBodyHeight: 'calc(100vh - 304px)',
		textLabels: {
			body: {
				noMatch: tableLoading ? (
					<LoadingDots />
				) : (
					'Sorry, no matching sign in or out logs found'
				),
			},
		},
	};

	const checkAccountType = userDetails.accountType === 'management';
	const title = checkAccountType
		? 'Site Sign In Book'
		: 'Company Sign In Book';

	useEffect(() => {
		setTableLoading(true);
		setTableData([]);
		const endDate = endOfWeek(startDate);
		let inSub: () => void = () => {
			return;
		};
		let outSub: () => void = () => {
			return;
		};

		// Queries given by https://tradelegion.atlassian.net/l/c/P6GiN5qy

		// Main contractor management
		if (
			checkAccountType &&
			userDetails.siteCompanyID === userDetails.companyID
		) {
			inSub = firebaseApi.siteLogsBySiteAndTypeDateRangeSubscription(
				userDetails.siteID,
				SiteLogType.In,
				startDate,
				endDate,
				'desc',
				setCompanySignInList,
			);

			outSub = firebaseApi.siteLogsBySiteAndTypeDateRangeSubscription(
				userDetails.siteID,
				SiteLogType.Out,
				startDate,
				endDate,
				'asc',
				setCompanySignOutList,
			);
		}
		// Subcontractor management
		else if (
			checkAccountType &&
			userDetails.siteCompanyID !== userDetails.companyID
		) {
			inSub =
				firebaseApi.siteLogsBySiteContractedToAndTypeDateRangeSubscription(
					userDetails.siteID,
					userDetails.companyID,
					SiteLogType.In,
					startDate,
					endDate,
					'desc',
					setContractedToSignInList,
				);
			outSub =
				firebaseApi.siteLogsBySiteContractedToAndTypeDateRangeSubscription(
					userDetails.siteID,
					userDetails.companyID,
					SiteLogType.Out,
					startDate,
					endDate,
					'asc',
					setContractedToSignOutList,
				);
		}
		// Senior management contractor
		else if (userDetails.accountType === 'seniorManagement') {
			// First subscription pulls all logs from sites belonging to the users company
			inSub =
				firebaseApi.siteLogsBySiteCompanyAndTypeDateRangeSubscription(
					userDetails.companyID,
					SiteLogType.In,
					startDate,
					endDate,
					'desc',
					setCompanySignInList,
				);
			// Second subscription pulls all site logs where the log is contracted to the users company
			// but not on a site the users company owns
			const inSub2 =
				firebaseApi.siteLogsByContractedToAndTypeDateRangeSubscription(
					userDetails.companyID,
					SiteLogType.In,
					startDate,
					endDate,
					'desc',
					(siteLogs) => {
						const newSiteLogs = siteLogs.filter(
							(siteLog) =>
								siteLog.siteCompanyID !== userDetails.companyID,
						);
						setContractedToSignInList(newSiteLogs);
					},
				);

			outSub =
				firebaseApi.siteLogsBySiteCompanyAndTypeDateRangeSubscription(
					userDetails.companyID,
					SiteLogType.Out,
					startDate,
					endDate,
					'asc',
					setCompanySignOutList,
				);
			const outSub2 =
				firebaseApi.siteLogsByContractedToAndTypeDateRangeSubscription(
					userDetails.companyID,
					SiteLogType.Out,
					startDate,
					endDate,
					'asc',
					(siteLogs) => {
						const newSiteLogs = siteLogs.filter(
							(siteLog) =>
								siteLog.siteCompanyID !== userDetails.companyID,
						);
						setContractedToSignOutList(newSiteLogs);
					},
				);
			return () => {
				inSub();
				outSub();
				inSub2();
				outSub2();
			};
		} else if (userDetails.accountType === 'handler') {
			inSub = firebaseApi.siteLogsByCompanyAndTypeDateRangeSubscription(
				userDetails.companyID,
				SiteLogType.In,
				startDate,
				endDate,
				'desc',
				setCompanySignInList,
			);
			outSub = firebaseApi.siteLogsByCompanyAndTypeDateRangeSubscription(
				userDetails.companyID,
				SiteLogType.Out,
				startDate,
				endDate,
				'asc',
				setCompanySignOutList,
			);
		}
		return () => {
			inSub();
			outSub();
		};
	}, [startDate, userDetails, checkAccountType]);

	useEffect(() => {
		setSignInRecord(
			toSiteLogRecord([...companySignInList, ...contractedToSignInList]),
		);
	}, [companySignInList, contractedToSignInList]);

	useEffect(() => {
		setSignOutRecord(
			toSiteLogRecord([
				...companySignOutList,
				...contractedToSignOutList,
			]),
		);
	}, [companySignOutList, contractedToSignOutList]);

	useEffect(() => {
		const getSignOutTime = (itemIn: SiteLogRecord): string => {
			if (signOutRecord) {
				const nextSignIn = Object.values(signInRecord).find(
					(nextIn) => {
						return (
							nextIn.workerID === itemIn.workerID &&
							nextIn.siteID === itemIn.siteID &&
							nextIn.datetime > itemIn.datetime
						);
					},
				);
				const signOutLog = Object.values(signOutRecord).find(
					(itemOut) => {
						return (
							itemOut.workerID === itemIn.workerID &&
							itemOut.siteID === itemIn.siteID &&
							itemOut.datetime > itemIn.datetime &&
							(nextSignIn === undefined ||
								itemOut.datetime < nextSignIn.datetime)
						);
					},
				);
				if (signOutLog) {
					signOutPhotos[itemIn.id] = signOutLog.url;
					setSignOutPhotos(signOutPhotos);

					return formatTime(signOutLog.datetime.toDate());
				}
			}
			return '';
		};
		if (Object.keys(signInRecord).length !== 0 && signOutRecord) {
			const data = Object.values(signInRecord).map((entry) => [
				entry.takenBy,
				entry.company,
				entry.site,
				entry.userType,
				formatWeekdaySlashedDate(entry.datetime.toDate()),
				formatTime(entry.datetime.toDate()),
				getSignOutTime(entry),
			]);
			setTableData(data);
		}
		setTableLoading(false);
	}, [signInRecord, signOutPhotos, signOutRecord]);

	const cellWidth = '14.5%';

	const columns: MUIDataTableColumnDef[] = [
		{
			name: 'Name',
			options: {
				setCellHeaderProps: () => ({
					style: { width: cellWidth },
				}),
			},
		},
		{
			name: 'Company',
			options: {
				setCellHeaderProps: () => ({
					style: { width: cellWidth },
				}),
			},
		},
		{
			name: 'Site',
			options: {
				setCellHeaderProps: () => ({
					style: { width: cellWidth },
				}),
			},
		},
		{
			name: 'Type',
			options: {
				setCellHeaderProps: () => ({
					style: { width: cellWidth },
				}),
			},
		},
		{
			name: 'Date',
			options: {
				setCellHeaderProps: () => ({
					style: { width: cellWidth },
				}),
				filterType: 'custom',
				filterOptions: {
					logic: (day, filters) =>
						filters.length && filters[0] !== ''
							? !filters.includes(dateStringToDay(day))
							: false,
					display: (
						filterList,
						onChange,
						index,
						column,
						filterData,
					): JSX.Element => {
						return (
							<FormControl>
								{/* this refuses to line up and I have no idea why */}
								<InputLabel shrink sx={{ ml: -2 }}>
									Day
								</InputLabel>
								<Select
									notched
									variant="standard"
									displayEmpty
									renderValue={(value): string =>
										value || 'All'
									}
									// should only ever be one value atm
									value={
										filterList[index].length === 0
											? ''
											: filterList[index][0]
									}
									onChange={(event): void => {
										filterList[index] = [
											event.target.value,
										];
										onChange(
											filterList[index],
											index,
											column,
										);
									}}>
									<MenuItem key="All" value="">
										<ListItemText primary="All" />
									</MenuItem>
									{filterData[index]
										.map(dateStringToDay)
										.sort(
											(dayA, dayB) =>
												toZeroedMonday(
													getDayNumber(dayA),
												) -
												toZeroedMonday(
													getDayNumber(dayB),
												),
										)
										.map((day) => (
											<MenuItem key={day} value={day}>
												<ListItemText primary={day} />
											</MenuItem>
										))}
								</Select>
							</FormControl>
						);
					},
				},
				customFilterListOptions: {
					render: (value) => (value[0] === '' ? [] : value),
				},
			},
		},
		{
			name: 'Sign In',
			options: {
				setCellHeaderProps: () => ({
					style: { width: cellWidth },
				}),
			},
		},
		{
			name: 'Sign Out',
			options: {
				setCellHeaderProps: () => ({
					style: { width: cellWidth },
				}),
			},
		},
	];

	const selectedEntry = useMemo(
		() => Object.values(signInRecord)[selected],
		[selected, signInRecord],
	);

	const selectedOutEntry = Object.values(signInRecord)[selected];

	const selectedOutPhotoUrl = selectedOutEntry
		? signOutPhotos[selectedOutEntry.id]
		: undefined;

	return (
		<Stack
			direction={{ xs: 'column', sm: 'column', md: 'column', lg: 'row' }}
			divider={
				<Divider
					sx={{ ml: 1, mr: 1 }}
					orientation="vertical"
					flexItem
				/>
			}
			spacing={2}>
			<Box sx={{ minWidth: '75%' }}>
				<DateDataTable
					title={title}
					tableData={tableData}
					columns={columns}
					date={[startDate, setStartDate]}
					selection={[selected, setSelected]}
					customTableOptions={tableOptions}
				/>
			</Box>
			<Box sx={{ width: '25%' }}>
				<Grid
					spacing={3}
					container
					padding={2}
					alignItems="center"
					flexDirection="column">
					<Grid item xs={12}>
						<Typography variant="h4">Sign In</Typography>
					</Grid>
					<Grid item xs={12}>
						{selectedEntry ? (
							<ExpandDialog
								buttonStyles={{
									width: '100%',
									height: '100%',
									paddingX: 0.5,
								}}>
								<LoadableImage
									src={selectedEntry?.url}
									srcLoading={false}
									altText="No Sign Out Image Data"
									imageStyle={{
										width: '100%',
										height: '100%',
										minHeight: '200px',
										maxWidth: '80%',
									}}
								/>
							</ExpandDialog>
						) : (
							<Typography variant="subtitle2">
								No Image
							</Typography>
						)}
					</Grid>
					<Grid item xs={12}>
						<Typography variant="h4">Sign Out</Typography>
					</Grid>
					<Grid item xs={12}>
						{selectedOutPhotoUrl ? (
							<ExpandDialog
								buttonStyles={{
									width: '100%',
									height: '100%',
									paddingX: 0.5,
								}}>
								<LoadableImage
									src={selectedOutPhotoUrl}
									srcLoading={false}
									altText="No Sign Out Image Data"
									imageStyle={{
										width: '100%',
										height: '100%',
										minHeight: '200px',
										maxWidth: '80%',
									}}
								/>
							</ExpandDialog>
						) : (
							<Typography variant="subtitle2">
								No Image
							</Typography>
						)}
					</Grid>
				</Grid>
			</Box>
		</Stack>
	);
};

import { Typography } from '@mui/material';
import { TimesheetSectionNames } from './TimesheetSectionNames';

export type CreateColumnTitleProps = {
	title: TimesheetSectionNames;
	centerText?: boolean;
};

export const CreateColumnTitle = ({
	title,
	centerText,
}: CreateColumnTitleProps): JSX.Element => {
	return (
		<Typography
			fontWeight="bold"
			textAlign={centerText ? 'center' : undefined}>
			{title}
		</Typography>
	);
};

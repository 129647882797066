import { Site } from '../../../constants/Common';
import { Action } from '../../../constants/TypescriptUtilities';
import { UserOptionsState } from './reducer';

type UserOptionsAction<
	T extends UserOptionsActionTypes,
	P extends object | void = void,
> = Action<T, P>;

export const enum UserOptionsActionTypes {
	UPDATE_SITE = 'UPDATE_SITE',
	UPDATE_ACCOUNT_TYPE = 'UPDATE_ACCOUNT_TYPE',
	UPDATE_CONTRACTED_TO = 'UPDATE_CONTRACTED_TO',
	UPDATE_LOADING = 'UPDATE_LOADING',
	TOGGLE_DISABLED_LEAVE = 'TOGGLE_DISABLED_LEAVE',
	TOGGLE_DISABLE_TIMESHEET_APPROVAL = 'TOGGLE_DISABLE_TIMESHEET_APPROVAL',
}

type UpdateSiteAction = UserOptionsAction<
	UserOptionsActionTypes.UPDATE_SITE,
	{
		site: Site | null;
	}
>;

type UpdateAccountTypeAction = UserOptionsAction<
	UserOptionsActionTypes.UPDATE_ACCOUNT_TYPE,
	{
		accountType: UserOptionsState['user']['accountType'];
	}
>;

type UpdateContractedToAction = UserOptionsAction<
	UserOptionsActionTypes.UPDATE_CONTRACTED_TO,
	{
		contractedTo: UserOptionsState['user']['contractedTo'];
	}
>;

type UpdateLoading = UserOptionsAction<
	UserOptionsActionTypes.UPDATE_LOADING,
	{
		loading: UserOptionsState['loading'];
	}
>;

type ToggleDisableLeaveAction = UserOptionsAction<
	UserOptionsActionTypes.TOGGLE_DISABLED_LEAVE,
	{
		disabledLeave: UserOptionsState['user']['disabledLeave'];
	}
>;

type ToggleDisableTimesheetApprovalAction = UserOptionsAction<
	UserOptionsActionTypes.TOGGLE_DISABLE_TIMESHEET_APPROVAL,
	{
		disableTimesheetApproval: UserOptionsState['user']['disableTimesheetApproval'];
	}
>;

export type UserOptionsActions =
	| UpdateSiteAction
	| UpdateAccountTypeAction
	| UpdateContractedToAction
	| UpdateLoading
	| ToggleDisableLeaveAction
	| ToggleDisableTimesheetApprovalAction;

export const updateSite = (site: Site | null): UpdateSiteAction => ({
	type: UserOptionsActionTypes.UPDATE_SITE,
	payload: { site },
});

export const updateAccountType = (
	accountType: UserOptionsState['user']['accountType'],
): UpdateAccountTypeAction => ({
	type: UserOptionsActionTypes.UPDATE_ACCOUNT_TYPE,
	payload: { accountType },
});

export const updateContractedTo = (
	contractedTo: UserOptionsState['user']['contractedTo'],
): UpdateContractedToAction => ({
	type: UserOptionsActionTypes.UPDATE_CONTRACTED_TO,
	payload: { contractedTo },
});

export const updateLoading = (
	loading: UserOptionsState['loading'],
): UpdateLoading => ({
	type: UserOptionsActionTypes.UPDATE_LOADING,
	payload: { loading },
});

export const toggleDisabledLeave = (
	disabledLeave: UserOptionsState['user']['disabledLeave'],
): ToggleDisableLeaveAction => ({
	type: UserOptionsActionTypes.TOGGLE_DISABLED_LEAVE,
	payload: { disabledLeave },
});

export const toggleDisableTimesheetApproval = (
	disableTimesheetApproval: UserOptionsState['user']['disableTimesheetApproval'],
): ToggleDisableTimesheetApprovalAction => ({
	type: UserOptionsActionTypes.TOGGLE_DISABLE_TIMESHEET_APPROVAL,
	payload: { disableTimesheetApproval },
});

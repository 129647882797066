import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CheckCircleIcon from '@mui/icons-material/CheckCircleOutline';
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import { Box, ThemeProvider, Typography, createTheme } from '@mui/material';
import { Theme } from '@mui/material/styles';
import {
	CustomHeadLabelRenderOptions,
	MUIDataTableColumnDef,
	MUIDataTableColumnOptions,
	MUIDataTableOptions,
} from 'mui-datatables';
import { FixMeLater } from '../../../../constants/AnyTypes';
import { dayStrings, lowercaseDayString } from '../../../../constants/Common';
import { NotesByDay } from '../../../../constants/Note';
import {
	TimesheetSiteLog,
	TimesheetTableActivity,
	TimesheetTableBreaks,
	TimesheetTableRowTimesheet,
} from '../../../../constants/Timesheet/Timesheet';
import { DataTable } from '../../../DataTable/DataTable';
import { sortByDayOfWeekCompareFn } from '../../../helpers/dateUtilities';
import { LoadingDots } from '../../../Management/subcomponents/LoadingDots';
import { SiteLogPicturesTooltip } from '../SiteLogPicturesTooltip';
import { NotesTooltip } from './NotesTooltip';
import { TimesheetFooter } from './TimesheetFooter';
import {
	calculateAutoCheck,
	comparerAutoCheckValue,
	splitActivitiesByDay,
	splitSiteLogsByDay,
} from './timesheetTableUtilities';

export type TimesheetTableProps = {
	activities: TimesheetTableActivity[];
	siteLogs: TimesheetSiteLog[];
	breaks: TimesheetTableBreaks;
	defaultSiteLogURL: string;
	notes: NotesByDay;
	loading: boolean;
};

export const TimesheetTable = ({
	activities,
	siteLogs,
	breaks,
	defaultSiteLogURL,
	notes,
	loading,
}: TimesheetTableProps): JSX.Element => {
	const activitiesByDay = splitActivitiesByDay(activities);
	const siteLogsByDay = splitSiteLogsByDay(siteLogs);

	const toTableData = (): TimesheetTableRowTimesheet[] =>
		dayStrings.map((day) => {
			const autoCheck = calculateAutoCheck(
				siteLogsByDay[day],
				activitiesByDay[day],
				breaks[lowercaseDayString(day)].break,
			);

			return {
				day,
				activities: activitiesByDay[day],
				siteLogs: siteLogsByDay[day],
				breaks:
					activitiesByDay[day].length > 0 ||
					breaks[lowercaseDayString(day)].break > 0
						? breaks[lowercaseDayString(day)]
						: null,
				autoCheck,
				notes: notes[day],
			};
		});

	const tableOptions: MUIDataTableOptions = {
		download: false,
		filter: false,
		print: false,
		search: false,
		pagination: false,
		selectableRows: 'none',
		elevation: 0,
		isRowSelectable: () => false,
		tableBodyHeight: '100%',
		textLabels: {
			body: {
				noMatch: loading ? (
					<LoadingDots style={{ minHeight: '170px' }} />
				) : (
					'Sorry, no matching records found'
				),
			},
		},
		customFooter: () => (
			<TimesheetFooter
				activities={loading ? [] : activities}
				generalNotes={notes['All']}
			/>
		),
	};

	const customHeaderRender = (
		options: CustomHeadLabelRenderOptions,
	): JSX.Element => (
		<Typography fontWeight="bold" whiteSpace="nowrap">
			{options.label}
		</Typography>
	);

	const customBodyRender = (value: string): JSX.Element => (
		<Typography fontSize="small">{value}</Typography>
	);

	const siteLogHoursRender = ({
		In,
		Out,
		formattedLogs,
	}: TimesheetTableRowTimesheet['siteLogs']): JSX.Element => {
		return formattedLogs !== null ? (
			<SiteLogPicturesTooltip
				signInURL={In?.url ?? defaultSiteLogURL}
				signOutURL={Out?.url ?? defaultSiteLogURL}>
				<Typography
					fontSize="small"
					sx={{ textDecoration: 'underline dotted' }}>
					{formattedLogs}
				</Typography>
			</SiteLogPicturesTooltip>
		) : (
			<></>
		);
	};

	const timesheetHoursRender = (
		activities: TimesheetTableRowTimesheet['activities'],
	): JSX.Element[] =>
		activities.map((activity) => (
			<Typography key={activity.id} fontSize="small">
				{`${activity.hours}h ${activity.activity.name}`}
			</Typography>
		));

	const autoCheckRender = (autoCheck: boolean | null): JSX.Element => (
		<Box justifyContent="center" alignItems="center" display="flex">
			{autoCheck === null ? (
				<RemoveCircleOutlineOutlinedIcon
					color="disabled"
					fontSize="small"
				/>
			) : autoCheck ? (
				<CheckCircleIcon color="success" fontSize="small" />
			) : (
				<CancelOutlinedIcon color="error" fontSize="small" />
			)}
		</Box>
	);

	const notesRender = (
		dayNotes: TimesheetTableRowTimesheet['notes'],
	): JSX.Element => <NotesTooltip notes={dayNotes} />;

	const breakHoursRender = (
		breakHours: TimesheetTableRowTimesheet['breaks'] | null,
	): JSX.Element =>
		breakHours === null ? (
			<></>
		) : (
			<Typography
				fontSize="small"
				textAlign="center">{`${breakHours.break}h`}</Typography>
		);

	const columnHeaderStyle = {
		justifyContent: 'start',
		paddingTop: 4,
		paddingBottom: 4,
		paddingLeft: 8,
		paddingRight: 8,
	};
	const columnOptions: MUIDataTableColumnOptions = {
		sort: false,
		setCellHeaderProps: () => ({
			style: columnHeaderStyle,
		}),
		setCellProps: () => ({
			style: {
				verticalAlign: 'top',
				paddingTop: 4,
				paddingBottom: 4,
				paddingLeft: 8,
				paddingRight: 8,
			},
		}),
		customHeadLabelRender: customHeaderRender,
		customBodyRender: customBodyRender,
	};

	const columns: MUIDataTableColumnDef[] = [
		{
			name: 'day',
			label: 'Day',
			options: {
				...columnOptions,
				setCellHeaderProps: () => ({
					style: { ...columnHeaderStyle, width: '10%' },
				}),
				sort: true,
				sortCompare: (order) => (day1, day2) =>
					sortByDayOfWeekCompareFn(day1.data, day2.data, order),
			},
		},
		{
			name: 'siteLogs',
			label: 'Site Log Hours',
			options: {
				...columnOptions,
				setCellHeaderProps: () => ({
					style: { ...columnHeaderStyle, width: '25%' },
				}),
				customBodyRender: siteLogHoursRender,
			},
		},
		{
			name: 'activities',
			label: 'Timesheet Hours',
			options: {
				...columnOptions,
				setCellHeaderProps: () => ({
					style: { ...columnHeaderStyle, width: '25%' },
				}),
				customBodyRender: timesheetHoursRender,
			},
		},
		{
			name: 'breaks',
			label: 'Breaks',
			options: {
				...columnOptions,
				setCellHeaderProps: () => ({
					style: {
						...columnHeaderStyle,
						textAlign: 'center',
						width: '8%',
					},
				}),
				customBodyRender: breakHoursRender,
			},
		},
		{
			name: 'autoCheck',
			label: 'Auto Check',
			options: {
				...columnOptions,
				setCellHeaderProps: () => ({
					style: { ...columnHeaderStyle, width: '5%' },
				}),
				customBodyRender: autoCheckRender,
				sort: true,
				sortCompare: (order) => (check1, check2) => {
					return (
						comparerAutoCheckValue(order, check1.data) -
						comparerAutoCheckValue(order, check2.data)
					);
				},
			},
		},
		{
			name: 'notes',
			label: 'Notes',
			options: {
				...columnOptions,
				setCellHeaderProps: () => ({
					style: {
						...columnHeaderStyle,
						width: '10%',
						textAlign: 'center',
					},
				}),
				customBodyRender: notesRender,
			},
		},
	];

	/**
	 * Mildly Brittle, might need to keep an eye on this whenever upgrading MUI-datatables
	 */
	const timesheetTableThemeOverrides = (baseTheme: Theme): Theme =>
		createTheme({
			...baseTheme,
			components: {
				...baseTheme.components,
				MUIDataTableBodyCell: {
					styleOverrides: loading
						? {
								cellHide: {
									display: 'none !important', // MUI are idiots making me do this they override their own styles in the stackedCommon implementation, so dumb
								},
								stackedCommon: {
									width: '100%',
								},
						  }
						: undefined,
				},
			} as FixMeLater, // aka when MUI sort their types out
		});

	return (
		<ThemeProvider theme={timesheetTableThemeOverrides}>
			<DataTable
				title="" // No title
				tableData={loading ? [] : toTableData()}
				columns={columns}
				customTableOptions={tableOptions}
			/>
		</ThemeProvider>
	);
};

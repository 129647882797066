import {
	Box,
	Stack,
	Tooltip,
	TooltipProps,
	Typography,
	styled,
	tooltipClasses,
} from '@mui/material';
import { DefaultTheme } from '../../../Theme';
import { LoadableImage } from '../../Image/LoadableImage';

type SiteLogPicturesToolTipProps = {
	signInURL: string;
	signOutURL: string;
	children: JSX.Element;
};

// This is how they prefer to do it apparently, having to be so explicit
// cause the styling they apply really mucks with the images
// https://mui.com/material-ui/react-tooltip/#variable-width
const CustomStyleTooltip = styled(({ className, ...props }: TooltipProps) => (
	<Tooltip {...props} classes={{ popper: className }} />
))({
	[`& .${tooltipClasses.tooltip}`]: {
		minWidth: 318,
		maxWidth: 318,
		minHeight: 236,
		padding: 8,
		paddingTop: 0,
		backgroundColor: `${DefaultTheme.palette.neutral.dark}f2`,
	},
});

export const SiteLogPicturesTooltip = ({
	signInURL,
	signOutURL,
	children,
}: SiteLogPicturesToolTipProps): JSX.Element => {
	// Because tooltip sizing sucks
	const imageStyle = {
		minWidth: '135px',
		minHeight: '180px',
		maxHeight: '180px',
		alignItems: 'center',
	};
	const tooltipTitle = (): JSX.Element => (
		<Stack direction="row">
			<Box margin={1}>
				<Typography
					fontWeight="bold"
					textAlign="center"
					marginBottom={1}>
					Sign In
				</Typography>
				<LoadableImage
					src={signInURL}
					srcLoading={false}
					altText="Sign in photo"
					imageStyle={imageStyle}
				/>
			</Box>
			<Box marginLeft={2} margin={1}>
				<Typography
					fontWeight="bold"
					textAlign="center"
					marginBottom={1}>
					Sign Out
				</Typography>
				<LoadableImage
					src={signOutURL}
					srcLoading={false}
					altText="Sign out photo"
					imageStyle={imageStyle}
				/>
			</Box>
		</Stack>
	);
	return (
		<CustomStyleTooltip
			title={tooltipTitle()}
			arrow
			disableInteractive
			style={{ cursor: 'pointer' }}>
			{children}
		</CustomStyleTooltip>
	);
};

import CheckCircleIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import OutboundOutlinedIcon from '@mui/icons-material/OutboundOutlined';
import {
	MenuItem,
	Stack,
	TextField,
	TextFieldProps,
	Typography,
} from '@mui/material';
import { TimesheetStatus } from '../../constants/Timesheet/TimesheetStatus';

const ICON_SIZE = 21; // prevents Select's height from growing
const ICON_CUSTOM_SX = { height: ICON_SIZE, width: ICON_SIZE };
const timesheetStatusSelectIcons: Record<TimesheetStatus, JSX.Element> = {
	[TimesheetStatus.Active]: (
		<ErrorOutlineIcon color="error" sx={ICON_CUSTOM_SX} />
	),
	[TimesheetStatus.Approved]: (
		<CheckCircleIcon color="success" sx={ICON_CUSTOM_SX} />
	),
	[TimesheetStatus.Archived]: (
		<CheckCircleIcon color="disabled" sx={ICON_CUSTOM_SX} />
	),
	[TimesheetStatus.Submitted]: (
		<OutboundOutlinedIcon color="secondary" sx={ICON_CUSTOM_SX} />
	),
};

export type TimesheetStatusSelectProps<
	Status extends TimesheetStatus = TimesheetStatus,
> = {
	value: Status;
	onChange: (status: Status) => void;
	label: string;
	statuses: readonly Status[];
	disabled?: boolean;
	error?: boolean;
	helperText?: TextFieldProps['helperText'];
};

export const TimesheetStatusSelect = function <Status extends TimesheetStatus>({
	value,
	onChange,
	label = 'Status',
	statuses,
	disabled,
	error,
	helperText,
}: TimesheetStatusSelectProps<Status>): JSX.Element {
	return (
		<TextField
			select
			fullWidth
			value={value}
			label={label}
			onChange={(event): void => onChange(event.target.value as Status)}
			disabled={disabled}
			error={error}
			helperText={helperText}
			size="small">
			{statuses.map((status) => (
				<MenuItem key={status} value={status}>
					<Stack direction="row" spacing={1} alignItems="center">
						{timesheetStatusSelectIcons[status]}
						<Typography sx={{ font: 'inherit' }}>
							{status}
						</Typography>
					</Stack>
				</MenuItem>
			))}
		</TextField>
	);
};

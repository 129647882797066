import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import { Avatar, Box, Stack, Typography } from '@mui/material';
import { DefaultTheme } from '../../../../Theme';

export type TimesheetSiteCardProps = {
	context: {
		siteName: string;
	};
};

export const TimesheetSiteCard = ({
	context,
}: TimesheetSiteCardProps): JSX.Element => (
	<Stack direction="row" spacing={2} alignItems="flex-start">
		<Avatar
			sx={{
				backgroundColor: DefaultTheme.palette.tertiary.default,
				color: DefaultTheme.palette.info.contrastText,
			}}>
			<LocationOnOutlinedIcon color="inherit" />
		</Avatar>
		<Box>
			<Typography variant="h7Bold">Site</Typography>
			<Typography fontSize="small">{context.siteName}</Typography>
		</Box>
	</Stack>
);

import ClearIcon from '@mui/icons-material/Clear';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import {
	Autocomplete,
	Box,
	Divider,
	Grid,
	IconButton,
	ListItem,
	Paper,
	Stack,
	TextField,
	Tooltip,
	Typography,
} from '@mui/material';
import { clone } from 'lodash';
import React, { useEffect, useState } from 'react';
import {
	AccountTypeHumanName,
	UserDetails,
} from '../../../../constants/Common';
import firebaseApi from '../../../../firebase/firebaseApi';
import { IntegrationType } from '../../../../models/Integrations/Integration';
import {
	BaseIntegrationLink,
	IntegrationEmployee,
} from '../../../../models/Integrations/IntegrationElements';
import { PayrollType } from '../../../../models/Integrations/PayrollIntegration';
import { LoadingDots } from '../../../Management/subcomponents/LoadingDots';
import SearchInput from '../../../Search/Search';
import { CustomSnackBar } from '../../../SnackBar/SnackBar';
import { ConfirmLinkDialog } from '../../IntegrationUIComponents/ConfirmLinkDialog';
import { ConfirmationDialog } from './ConfirmationDialog';
import { header } from './UnlinkedUsersTable';

type LinkedUsersProps<T extends IntegrationType> = {
	usersList: Record<string, UserDetails>;
	dropdownEmployees: IntegrationEmployee[];
	loading: boolean;
	integrationCompanyID: string;
	userIntegrationAccounts: Record<string, BaseIntegrationLink> | null;
	integrationAccounts: IntegrationEmployee[];
	linkedUsersList: UserDetails[];
	fetchAllPayrollEmployees: (abortSignal: AbortSignal) => Promise<void>;
	integrationCollection: T extends PayrollType
		? 'payrollIntegrations'
		: 'invoicingIntegrations';
};

export const LinkedUsers = <T extends IntegrationType>({
	usersList,
	integrationCompanyID,
	dropdownEmployees,
	loading,
	userIntegrationAccounts,
	integrationAccounts,
	linkedUsersList,
	fetchAllPayrollEmployees,
	integrationCollection,
}: LinkedUsersProps<T>): JSX.Element => {
	const [searchContentData, setSearchContentData] = useState<UserDetails[]>(
		[],
	);
	const [isEditing, setIsEditing] = useState<boolean>(false);
	const [successSnackBarOpen, setSuccessSnackBarOpen] =
		useState<boolean>(false);
	const [integrationConnectionDeleted, setIntegrationConnectionDeleted] =
		useState<boolean>(false);
	const [showConfirmDeleteDialog, setShowConfirmDeleteDialog] =
		useState<boolean>(false);
	const [selectedToDelete, setSelectedToDelete] = useState<string>();
	const [connectionsToUpdate, setConnectionsToUpdate] =
		useState<Record<string, BaseIntegrationLink>>();
	const [showConfirmationDialog, setShowConfirmationDialog] =
		useState<boolean>(false);

	useEffect(() => {
		setSearchContentData(linkedUsersList);
	}, [linkedUsersList]);

	const handleUpdatePayrollConnection = (): void => {
		if (connectionsToUpdate) {
			Object.values(connectionsToUpdate).forEach(async (connection) => {
				const updatedConnection: BaseIntegrationLink = {
					id: connection.id,
					name: connection.name,
					integrationID: connection.integrationID,
					integrationName: connection.integrationName,
				};
				await firebaseApi.updateIntegrationEmployeeLink(
					integrationCollection,
					integrationCompanyID,
					updatedConnection,
				);
				setSuccessSnackBarOpen(true);
				setShowConfirmationDialog(false);
				setIsEditing(false);
			});
		}
	};

	const handleDeletePayrollConnection = async (): Promise<void> => {
		if (selectedToDelete) {
			try {
				await firebaseApi.deleteIntegrationEmployeeLink(
					integrationCollection,
					integrationCompanyID,
					selectedToDelete,
				);
			} catch (error) {
				console.error(error);
			}
			setSuccessSnackBarOpen(true);
			setIntegrationConnectionDeleted(true);
			setShowConfirmDeleteDialog(false);
			setSelectedToDelete(undefined);
		}
	};

	const successSnackBar = (): JSX.Element => (
		<CustomSnackBar
			open={successSnackBarOpen}
			onClose={handleSnackBarClose}
			anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
			snackBarText={
				integrationConnectionDeleted
					? 'Integration Account Link Removed'
					: 'Integration Account Link Updated'
			}
		/>
	);

	const handleSnackBarClose = (
		event?: React.SyntheticEvent | Event,
		reason?: string,
	): void => {
		if (reason === 'clickaway') {
			return;
		}
		setSuccessSnackBarOpen(false);
	};

	const toggleEditing = (): void => {
		if (isEditing) {
			setConnectionsToUpdate(undefined);
		}
		setIsEditing(!isEditing);
	};

	const handleSelectIntegrationAccount = (
		integrationAccount: IntegrationEmployee,
		user: UserDetails,
	): void => {
		const connectedAccount: Record<string, BaseIntegrationLink> = {};
		connectedAccount[user.userID] = {
			id: user.userID,
			name: user.displayName,
			integrationID: integrationAccount.id,
			integrationName: integrationAccount.name,
		};
		setConnectionsToUpdate({ ...connectionsToUpdate, ...connectedAccount });
	};

	const handleDropdownOptions = (userID: string): IntegrationEmployee[] => {
		const updatedDropdown: IntegrationEmployee[] = clone(dropdownEmployees);
		if (userIntegrationAccounts && userIntegrationAccounts[userID]) {
			const currentOption = integrationAccounts.find(
				(employee) =>
					employee.id ===
					userIntegrationAccounts[userID].integrationID,
			);
			if (currentOption) {
				updatedDropdown.push(currentOption);
			}
		}
		return Object.values(updatedDropdown);
	};
	const confirmDeleteDialog = (): JSX.Element => {
		if (!selectedToDelete || !userIntegrationAccounts) return <></>;
		const link = userIntegrationAccounts[selectedToDelete];

		if (!link) return <></>;
		return (
			<ConfirmLinkDialog
				type="Account"
				mappingRemoved={true}
				singleMapping={link}
				open={showConfirmDeleteDialog}
				handleCancel={(): void => setShowConfirmDeleteDialog(false)}
				handleConfirm={handleDeletePayrollConnection}
			/>
		);
	};
	return (
		<>
			{successSnackBar()}
			{confirmDeleteDialog()}
			<ConfirmationDialog
				usersList={usersList}
				integrationAccounts={integrationAccounts}
				setShowConfirmationDialog={setShowConfirmationDialog}
				showConfirmationDialog={showConfirmationDialog}
				handleChanges={handleUpdatePayrollConnection}
				connectionsToSubmit={connectionsToUpdate}
			/>
			<Paper id="boxList" elevation={1}>
				<Stack spacing={3} p={2}>
					<Box display="flex" justifyContent="space-between" p={2}>
						<SearchInput
							data={linkedUsersList}
							onChange={(data): void =>
								setSearchContentData(data)
							}
							field="displayName"
							label="Search"
						/>
						<Stack direction="row" justifyContent="end">
							{isEditing ? (
								<Box>
									<IconButton
										size="large"
										color="primary"
										onClick={(): void => {
											setShowConfirmationDialog(true);
										}}
										disabled={
											connectionsToUpdate === undefined
										}>
										<Tooltip title="Update Links">
											<SaveIcon fontSize="large" />
										</Tooltip>
									</IconButton>
									<IconButton
										size="large"
										color="primary"
										onClick={toggleEditing}>
										<Tooltip title="Stop Editing">
											<ClearIcon fontSize="large" />
										</Tooltip>
									</IconButton>
								</Box>
							) : (
								<IconButton
									size="large"
									color="primary"
									onClick={toggleEditing}>
									<Tooltip title="Edit">
										<EditIcon fontSize="large" />
									</Tooltip>
								</IconButton>
							)}
						</Stack>
					</Box>
					{header(fetchAllPayrollEmployees, isEditing)}
					<Divider orientation="horizontal" />
					{loading ? (
						<LoadingDots />
					) : (
						<>
							{searchContentData.length > 0 && linkedUsersList ? (
								searchContentData.map((user) => (
									<Grid
										key={user.userID}
										container
										id="list"
										alignItems="center"
										justifyContent="space-around"
										width="100%"
										textAlign="center">
										<Grid item xs={2}>
											<Typography>
												{user.displayName}
											</Typography>
										</Grid>
										<Divider
											orientation="vertical"
											flexItem
										/>

										<Grid item xs={2}>
											<Typography>
												{
													AccountTypeHumanName[
														user.accountType
													]
												}
											</Typography>
										</Grid>
										<Divider
											orientation="vertical"
											flexItem
										/>

										<Grid item xs={3}>
											{userIntegrationAccounts &&
												userIntegrationAccounts[
													user.userID
												] &&
												(!isEditing ? (
													<Typography textAlign="center">
														{integrationAccounts.find(
															(employee) =>
																employee.id ===
																userIntegrationAccounts[
																	user.userID
																]
																	?.integrationID,
														)?.name ??
															'Missing from Integration. Please re-map this account.'}
													</Typography>
												) : (
													<Grid
														container
														flexDirection="row"
														alignItems="center"
														justifyContent="space-between"
														flexWrap="nowrap">
														<Autocomplete
															fullWidth
															options={handleDropdownOptions(
																user.userID,
															)}
															getOptionLabel={(
																option,
															): string =>
																option.name
															}
															isOptionEqualToValue={(
																option,
																value,
															): boolean => {
																return (
																	value.name ===
																	option.name
																);
															}}
															renderInput={(
																params,
															): JSX.Element => (
																<TextField
																	{...params}
																	label="Account"
																/>
															)}
															renderOption={(
																props,
																option,
															): JSX.Element => (
																<ListItem
																	{...props}
																	key={
																		option.id
																	}>
																	{
																		option.name
																	}
																</ListItem>
															)}
															onChange={(
																_,
																value,
															): void => {
																if (value) {
																	handleSelectIntegrationAccount(
																		value,
																		user,
																	);
																}
															}}
															disableClearable={
																true
															}
															defaultValue={integrationAccounts.find(
																(employee) =>
																	employee.id ===
																	userIntegrationAccounts[
																		user
																			.userID
																	]
																		?.integrationID,
															)}
														/>
														<Tooltip title="Remove Link">
															<IconButton
																size="small"
																color="primary"
																onClick={(): void => {
																	setSelectedToDelete(
																		user.userID,
																	);
																	setShowConfirmDeleteDialog(
																		true,
																	);
																}}>
																<ClearIcon fontSize="medium" />
															</IconButton>
														</Tooltip>
													</Grid>
												))}
										</Grid>
									</Grid>
								))
							) : (
								<Typography textAlign="center">
									There are no account links to manage
								</Typography>
							)}
						</>
					)}
				</Stack>
			</Paper>
		</>
	);
};

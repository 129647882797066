import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import SaveIcon from '@mui/icons-material/Save';
import SummarizeIcon from '@mui/icons-material/Summarize';
import {
	Box,
	Button,
	Chip,
	CircularProgress,
	Divider,
	Grid,
	IconButton,
	List,
	ListItem,
	Stack,
	SxProps,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableRow,
	Tooltip,
	Typography,
} from '@mui/material';
import { differenceInDays, formatDistance } from 'date-fns';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import {
	LicenseHumanName,
	ResidencyHumanName,
	UserProfile,
	WorkHistory,
} from '../../constants/Common';
import type { FirebaseApi } from '../../firebase/firebaseApi';
import {
	formatShortSpacedDate,
	formatSpacedDate,
} from '../helpers/dateFormatters';
import Image from '../Image/Image';
import { EditButton } from './Common/EditButton';
import { SectionHeader } from './Common/SectionHeader';
import {
	Competencies,
	CompetenciesFirebaseCalls,
} from './Competency/Competencies';
import {
	HandlerEmployeeEdit,
	HandlerEmployeeEditHandle,
} from './HandlerEmployeeEdit';
import {
	HandlerEmployeeProfileActivityTimes,
	hoursRecordToList,
} from './HandlerEmployeeProfileActivityTimes';
import {
	Qualifications,
	QualificationsFirebaseCalls,
} from './Qualifications/Qualifications';

export type EmployeeProfileProps = {
	employee: UserProfile;
	close: () => void;
	isModal?: boolean;
	firebaseApi: Pick<
		FirebaseApi,
		| 'getCompany'
		| 'userProfileWorkHistorySubscription'
		| 'getDownloadUrl'
		| 'getCompanyNoLogoUrl'
		| CompetenciesFirebaseCalls
		| QualificationsFirebaseCalls
	>;
};

const printSx: SxProps = { marginLeft: '-100%', float: 'right' };
type ComponentStates = 'display' | 'load' | 'print' | 'edit'; // edit only applies to top section

export const HandlerEmployeeProfile = ({
	employee,
	close,
	isModal,
	firebaseApi,
}: EmployeeProfileProps): JSX.Element => {
	const stackRef = useRef<HTMLElement | null>(null);

	const [workHistory, setWorkHistory] = useState<WorkHistory[]>([]);
	const [logos, setLogos] = useState<Record<string, string>>({});
	const [pageState, setPageState] = useState<ComponentStates>('display');
	const editRef = useRef<HandlerEmployeeEditHandle>(null); // I hate this, but this is the way if we don't want to bring all the edit state up to this component

	useLayoutEffect(() => {
		if (pageState === 'print') {
			// disabled by tl-2755
			// exportPDF({
			// 	ref: stackRef,
			// 	title: `User Profile - ${employee.displayName}`,
			// 	callback: () => setPageState('display'),
			// });
		}
	}, [employee.displayName, pageState]);

	useEffect(
		() =>
			firebaseApi.userProfileWorkHistorySubscription(
				employee.id,
				(workHistory) => setWorkHistory(workHistory),
			),
		[employee.id, firebaseApi],
	);

	useEffect(() => {
		const fetchCompanyLogos = async (): Promise<void> => {
			const workHistoryContractedToIDs = workHistory.map(
				(history) => history.contractedTo?.id ?? 'noContractedTo',
			);

			const uniqueCompanyIDs = [...new Set(workHistoryContractedToIDs)];
			const noCompanyLogoUrl = await firebaseApi.getCompanyNoLogoUrl();

			const companyLogoPromises = uniqueCompanyIDs.map(async (id) => {
				if (id === 'noContractedTo') return [id, noCompanyLogoUrl];

				const company = await firebaseApi.getCompany(id);
				const downloadURL = company?.logoLocation
					? await firebaseApi.getDownloadUrl(company?.logoLocation)
					: noCompanyLogoUrl;
				return [id, downloadURL];
			});

			setLogos(
				Object.fromEntries(await Promise.all(companyLogoPromises)),
			);
		};
		fetchCompanyLogos();
	}, [workHistory, firebaseApi]);

	const upperSummary = (): JSX.Element => {
		return (
			<Grid container spacing={2} alignItems="flex-start" pb={1}>
				<Grid item xs={9} display="flex" justifyContent="space-between">
					<Typography
						variant="h4"
						whiteSpace="nowrap"
						overflow="hidden"
						textOverflow="ellipsis">
						{employee.displayName}
					</Typography>
				</Grid>
				<Grid item xs={3}>
					<ActionButtons />
				</Grid>
				<Grid container item xs={8} spacing={2}>
					<Grid item xs={12}>
						<Divider />
					</Grid>
					<Grid item xs={6}>
						<Typography>Location</Typography>
					</Grid>
					<Grid item xs={6}>
						<Typography>
							{employee.location ?? 'Unknown'}
						</Typography>
					</Grid>
					<Grid item xs={6}>
						<Typography>Residency</Typography>
					</Grid>
					<Grid item xs={6}>
						<Typography>
							{employee.residency
								? ResidencyHumanName[employee.residency]
								: 'Unknown'}
						</Typography>
					</Grid>
					<Grid item xs={6}>
						<Typography>License</Typography>
					</Grid>
					<Grid item xs={6}>
						<Typography>
							{employee.license
								? LicenseHumanName[employee.license]
								: 'Unknown'}
						</Typography>
					</Grid>
					<Grid item xs={6}>
						<Typography>D.o.B</Typography>
					</Grid>
					<Grid item xs={6}>
						<Typography>
							{employee.dateOfBirth
								? formatSpacedDate(
										employee.dateOfBirth.toDate(),
								  )
								: 'Unknown'}
						</Typography>
					</Grid>
					<Grid item xs={6}>
						<Typography>Transport</Typography>
					</Grid>
					<Grid item xs={6}>
						{employee.transport ? (
							<CheckCircleIcon color="success">
								Yes
							</CheckCircleIcon>
						) : (
							<CancelIcon color="error">No</CancelIcon>
						)}
					</Grid>
					<Grid item xs={12}>
						<Divider />
					</Grid>
					<HandlerEmployeeProfileActivityTimes
						employee={employee}
						isEditing={false}
					/>
				</Grid>
				<Grid
					item
					xs={4}
					display="flex"
					flexDirection="column"
					justifyContent="space-between">
					<Image
						src={employee.profileImg}
						alt={employee.displayName}
					/>
					{pageState !== 'print' &&
						(employee.cv ? (
							<Chip
								icon={<SummarizeIcon />}
								label="CV"
								onClick={(): Window | null =>
									window.open(employee.cv)
								}
								sx={{ margin: 3 }}
							/>
						) : (
							<Chip label="No CV Attached" sx={{ margin: 3 }} />
						))}
				</Grid>
			</Grid>
		);
	};

	const renderWorkHistoryRow = (workHistory: WorkHistory): JSX.Element => {
		const startDate = workHistory.startDate.toDate();
		const endDate = workHistory.endDate.toDate();

		// Prevent showing 'less than a minute' for a user who was only a site for one day
		const workDistance =
			differenceInDays(endDate, startDate) > 0
				? formatDistance(startDate, endDate)
				: '1 day';

		const workDates = `${formatShortSpacedDate(
			startDate,
		)} - ${formatShortSpacedDate(endDate)}`;

		return (
			<ListItem key={workHistory.site.id}>
				<Stack
					width="100%"
					direction="row"
					spacing={1}
					alignContent="center"
					divider={<Divider orientation="vertical" flexItem />}>
					<Image
						style={{ width: '20%' }}
						src={
							logos[
								workHistory.contractedTo?.id ?? 'noContractedTo'
							]
						}
						alt={
							workHistory.contractedTo
								? `${workHistory.contractedTo?.name} logo`
								: 'Company Logo'
						}
					/>
					<Grid
						sx={{ width: '40%' }}
						container
						alignContent="flex-start"
						spacing={1}>
						<Grid item xs={12}>
							<Typography variant="h6">
								{workHistory.site.name}
							</Typography>
						</Grid>
						<Grid item xs={12}>
							{workHistory.contractedTo?.name}
						</Grid>
						<Grid item xs={12}>
							<Typography>{workDates}</Typography>
							<Typography>{workDistance}</Typography>
						</Grid>
					</Grid>
					<Grid sx={{ width: '40%' }} container spacing={2}>
						<TableContainer>
							<Table size="small">
								<TableBody>
									{hoursRecordToList(
										workHistory.activityHours,
									).map(([key, value]) => {
										return (
											<TableRow key={key}>
												<TableCell>
													<Typography variant="overline">
														{value.activityName}
													</Typography>
												</TableCell>
												<TableCell align="right">
													<Typography variant="body2">
														{value.hoursWorked}
													</Typography>
												</TableCell>
											</TableRow>
										);
									})}
								</TableBody>
							</Table>
						</TableContainer>
					</Grid>
				</Stack>
			</ListItem>
		);
	};

	const renderQualifications = (): JSX.Element => (
		<Qualifications
			userID={employee.id}
			isPrinting={pageState === 'print'}
			firebaseApi={firebaseApi}
		/>
	);

	const renderCompetencies = (): JSX.Element => (
		<Competencies
			userID={employee.id}
			isPrinting={pageState === 'print'}
			firebaseApi={firebaseApi}
		/>
	);

	const historyList = (): JSX.Element => (
		<SectionHeader
			title="Work History"
			editing={false}
			printing={pageState === 'print'}>
			<List>
				{workHistory
					.sort(
						(workHistoryA, workHistoryB) =>
							workHistoryB.endDate.seconds -
							workHistoryA.endDate.seconds,
					)
					.map((i) => renderWorkHistoryRow(i))}
			</List>
		</SectionHeader>
	);

	const ActionButtons = (): JSX.Element => {
		if (pageState === 'load' || pageState === 'print') {
			return <CircularProgress sx={printSx} />;
		} else if (pageState === 'edit' && isModal) {
			const save = async (): Promise<void> => {
				if (editRef.current) {
					setPageState('load');
					await editRef.current.save();
					setPageState('display');
				}
			};
			return (
				<Box sx={printSx} textAlign="right" width="100%">
					<Button
						variant="outlined"
						sx={{ mr: 2 }}
						onClick={(): void => setPageState('display')}>
						Cancel
					</Button>
					<Button
						color="primary"
						variant="contained"
						endIcon={<SaveIcon />}
						onClick={save}>
						Save
					</Button>
				</Box>
			);
		} else {
			return (
				<Box sx={printSx}>
					<EditButton onClick={(): void => setPageState('edit')} />
					<Tooltip title="Print">
						<IconButton
							color="primary"
							onClick={(): void => setPageState('print')}>
							<PictureAsPdfIcon />
						</IconButton>
					</Tooltip>
					{isModal && (
						<Tooltip title="Close">
							<IconButton onClick={close}>
								<CloseIcon />
							</IconButton>
						</Tooltip>
					)}
				</Box>
			);
		}
	};

	return (
		<>
			<Stack width="100%" overflow="auto" ref={stackRef}>
				{pageState === 'edit' ? (
					<HandlerEmployeeEdit
						employee={employee}
						onFinish={(): void => setPageState('display')}
						ref={editRef}>
						<ActionButtons />
					</HandlerEmployeeEdit>
				) : (
					upperSummary()
				)}
				{renderQualifications()}
				{renderCompetencies()}
				{historyList()}
			</Stack>
		</>
	);
};

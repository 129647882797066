import { FormatListBulletedOutlined } from '@mui/icons-material';
import {
	Box,
	Card,
	CardHeader,
	Divider,
	List,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Stack,
	Typography,
} from '@mui/material';
import { Timesheet } from '../../../../constants/Timesheet/Timesheet';
import {
	MultiStageStatus,
	TimesheetStatus,
} from '../../../../constants/Timesheet/TimesheetStatus';
import { statusIcon } from '../../../../constants/Timesheet/TimesheetUtilities';

export type TimesheetsStatusListProps = {
	timesheetId: string;
	timesheets: Timesheet[];
	setTimesheet: (timesheet: Timesheet) => void;
	isReview: boolean;
};

const TimesheetStatusRow = (
	timesheet: Timesheet,
	timesheetId: string,
	onClick: () => void,
): JSX.Element => {
	const status =
		timesheet?.preApproval &&
		timesheet.timesheetStatus === TimesheetStatus.Submitted
			? MultiStageStatus.PreApproved
			: timesheet.timesheetStatus;
	return (
		<ListItemButton
			key={timesheet.id}
			selected={timesheet.id === timesheetId}
			onClick={onClick}
			divider>
			<ListItemIcon>{statusIcon(status)}</ListItemIcon>
			<ListItemText
				primary={timesheet.employee.name}
				primaryTypographyProps={{
					textOverflow: 'ellipsis',
					whiteSpace: 'nowrap',
					overflow: 'hidden',
				}}
			/>
		</ListItemButton>
	);
};

const renderNoTimesheetDisplay = (isReview: boolean): JSX.Element => {
	const sharedStyes = {
		width: '100%',
		height: '100%',
		justifyContent: 'center',
		alignItems: 'center',
		textAlign: 'center',
	};
	return (
		<Stack sx={sharedStyes}>
			<FormatListBulletedOutlined
				sx={{
					fontSize: 40,
				}}
			/>
			<Typography>{`No Timesheets to ${
				isReview ? 'review' : 'display'
			} this week`}</Typography>
		</Stack>
	);
};

const navigationButtonHeight = '36.5px';

export const TimesheetsStatusList = ({
	timesheets,
	timesheetId,
	setTimesheet,
	isReview,
}: TimesheetsStatusListProps): JSX.Element => {
	return (
		<Card elevation={0} sx={{ height: '100%' }}>
			<CardHeader
				title={
					<>
						<Typography
							variant="inherit"
							sx={{ height: navigationButtonHeight }}>
							Timesheets
						</Typography>
					</>
				}
				sx={{ pb: 0, pt: 1 }}
			/>
			<Divider sx={{ width: '100%', mt: 1 }} />
			<Box
				sx={{
					height: 'calc(100% - 55px)',
					overflowY: 'scroll',
					padding: 0,
				}}>
				{timesheets.length > 0 ? (
					<List>
						{timesheets.map((timesheet) =>
							TimesheetStatusRow(timesheet, timesheetId, () =>
								setTimesheet(timesheet),
							),
						)}
					</List>
				) : (
					renderNoTimesheetDisplay(isReview)
				)}
			</Box>
		</Card>
	);
};

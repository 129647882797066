import { Autocomplete, MenuItem, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { ExplicitAny } from '../../constants/AnyTypes';
import {
	accountTypes,
	Company,
	CompanyTypes,
	UserDetails,
} from '../../constants/Common';
import type { FirebaseApi } from '../../firebase/firebaseApi';
import { useUserDetailsContext } from '../../providers/UserProvider';
import { sortByField, sortObjectByField } from '../helpers/sortHelpers';

type CompanyAutoCompleteProps = {
	onChange: (value: UserDetails['contractedTo'] | null) => void;
	value: string | null;
	label: string;
	error: boolean;
	helperText?: string;
	disabled: boolean;
	firebaseApi: Pick<FirebaseApi, 'companiesSubscriptionByType'>;
	recentClientIDs: string[];
	includeUserCompany?: boolean;
};

enum CompanyGroup {
	Recent = 'Recent',
	Other = 'Other',
}

type CompanyWithGroup = Company & {
	group: CompanyGroup;
};

/**
 * @deprecated due to the internal use of firebase reads
 * Prefer using the SimpleCompanyAutocomplete where possible instead
 */
export const CompanyAutoComplete = ({
	label,
	onChange,
	value,
	error,
	helperText,
	disabled,
	firebaseApi,
	recentClientIDs,
	includeUserCompany = false,
}: CompanyAutoCompleteProps): JSX.Element => {
	const userDetails = useUserDetailsContext();

	const [companies, setCompanies] = useState<
		Record<string, CompanyWithGroup>
	>({});

	const hasClients = userDetails?.accountType === accountTypes.handler;

	useEffect(() => {
		if (hasClients) {
			return firebaseApi.companiesSubscriptionByType(
				CompanyTypes.construction,
				(companies) => {
					const sortedCompanies = sortByField(companies, 'name');
					const companiesMapping: Record<string, CompanyWithGroup> =
						{};
					const recentCompaniesMapping: Record<
						string,
						CompanyWithGroup
					> = {};

					sortedCompanies.forEach((company) => {
						if (recentClientIDs.includes(company.id)) {
							recentCompaniesMapping[company.id] = {
								...company,
								group: CompanyGroup.Recent,
							};
						} else {
							companiesMapping[company.id] = {
								...company,
								group: CompanyGroup.Other,
							};
						}
					});

					sortObjectByField(recentCompaniesMapping, 'name');
					sortObjectByField(companiesMapping, 'name');
					const allCompanies: Record<string, CompanyWithGroup> = {
						...recentCompaniesMapping,
						...companiesMapping,
					};
					if (includeUserCompany) {
						const userCompany: CompanyWithGroup = {
							id: userDetails.companyID,
							name: userDetails.company,
							companyType: CompanyTypes.recruitment,
							group: CompanyGroup.Other,
						};
						allCompanies[userDetails.companyID] = userCompany;
					}
					setCompanies(allCompanies);
				},
			);
		} else {
			if (!userDetails) return;
			// Management can only set the client to their own company so there's no need to pull company data
			// Here it is assumed that all non-handler users companytype is construction
			const userCompany: Company = {
				id: userDetails.companyID,
				name: userDetails.company,
				companyType: CompanyTypes.construction,
			};
			setCompanies({
				[userDetails.companyID]: {
					...userCompany,
					group: CompanyGroup.Other,
				},
			});
		}
	}, [
		firebaseApi,
		hasClients,
		includeUserCompany,
		recentClientIDs,
		userDetails,
	]);

	const handleOnChange = (
		_: React.SyntheticEvent,
		value: CompanyWithGroup | null,
	): void => {
		onChange(
			value
				? {
						id: value.id,
						name: value.name,
				  }
				: value,
		);
	};

	const hasRecentClients = Object.values(companies).some(
		(company) => company.group === CompanyGroup.Recent,
	);

	const hideCompanyListOption = (company: CompanyWithGroup): boolean =>
		company.id === userDetails?.companyID &&
		userDetails.accountType === accountTypes.handler &&
		includeUserCompany;

	return (
		<Autocomplete
			data-testid="company-autocomplete"
			value={value ? companies[value] ?? null : null}
			options={Object.values(companies)}
			getOptionLabel={(option): string => option.name ?? ''}
			groupBy={
				hasClients && hasRecentClients
					? (option: CompanyWithGroup): string => String(option.group)
					: undefined
			}
			fullWidth
			onChange={handleOnChange}
			isOptionEqualToValue={(option, value): boolean => option === value}
			renderOption={(
				props: ExplicitAny,
				option: CompanyWithGroup,
			): JSX.Element | undefined =>
				// We to not display the user's company in the list of companies if they are recruitment
				// as we do not want the user manually setting a recruitment company as a client
				hideCompanyListOption(option) ? undefined : (
					<MenuItem
						{...props}
						label={option.name}
						variant="standard"
						key={option.id}>
						{option ? option.name : 'No Company'}
					</MenuItem>
				)
			}
			disabled={disabled}
			renderInput={(params): JSX.Element => (
				<TextField
					{...params}
					label={label}
					inputProps={{
						...params.inputProps,
					}}
					error={error}
					helperText={helperText}
				/>
			)}
		/>
	);
};

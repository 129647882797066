import {
	accountApprovalsMap,
	AccountsAccountType,
} from '../../../constants/Accounts';
import {
	AccountType,
	accountTypes,
	Company,
	CompanyTypes,
	Site,
	UserDetails,
} from '../../../constants/Common';
import { UserOptionsActionTypes, UserOptionsActions } from './actions';

export type UserOptionsAccountUpdateFields = Pick<
	UserDetails,
	| 'site'
	| 'siteID'
	| 'siteCompany'
	| 'siteCompanyID'
	| 'contractedTo'
	| 'accountType'
	| 'disabledLeave'
	| 'disableTimesheetApproval'
>;

export type UserOptionsState = {
	user: UserOptionsAccountUpdateFields;
	initialUser: UserOptionsAccountUpdateFields;
	loading: boolean;
	accountsUserAccountType: AccountsAccountType;
	selectableAccountTypes: AccountType[];
	authorizedActions: Record<
		| 'canChangeContractedTo'
		| 'canChangeApproveTimesheets'
		| 'isRecruitmentSite',
		boolean
	>;
};

export const createInitialUserOptionsState = (initArg: {
	user: UserOptionsAccountUpdateFields;
	companies: Record<string, Company>;
	sites: Record<string, Site>;
	accountsUserAccountType: AccountsAccountType;
}): UserOptionsState => {
	const canChangeContractedTo =
		initArg.accountsUserAccountType === accountTypes.handler;
	const canChangeApproveTimesheets =
		initArg.accountsUserAccountType === accountTypes.seniorManagement &&
		initArg.user.accountType === accountTypes.management;
	const isRecruitmentSite =
		initArg.sites[initArg.user.siteID] &&
		initArg.sites[initArg.user.siteID].companyType ===
			CompanyTypes.recruitment;

	const newUserFields: UserOptionsAccountUpdateFields = {
		site: initArg.user.site,
		siteID: initArg.user.siteID,
		siteCompany: initArg.user.siteCompany,
		siteCompanyID: initArg.user.siteCompanyID,
		contractedTo: initArg.user.contractedTo,
		accountType: initArg.user.accountType,
		disabledLeave: initArg.user.disabledLeave,
	};
	if (initArg.user.disableTimesheetApproval) {
		newUserFields.disableTimesheetApproval =
			initArg.user.disableTimesheetApproval;
	}

	return {
		user: newUserFields,
		initialUser: newUserFields,
		accountsUserAccountType: initArg.accountsUserAccountType,
		selectableAccountTypes:
			accountApprovalsMap[initArg.accountsUserAccountType],
		loading: false,
		authorizedActions: {
			canChangeContractedTo,
			canChangeApproveTimesheets,
			isRecruitmentSite,
		},
	};
};

export const getUpdates = (
	initialUserState: UserOptionsAccountUpdateFields,
	updatedUserFields: UserOptionsAccountUpdateFields,
): Partial<UserOptionsAccountUpdateFields> => {
	// Compare each field and add to fieldsToUpdate if it differs
	const fieldsToUpdate: Partial<UserOptionsAccountUpdateFields> = {};

	if (initialUserState.siteID !== updatedUserFields.siteID) {
		fieldsToUpdate.siteID = updatedUserFields.siteID;
		fieldsToUpdate.site = updatedUserFields.site;
		fieldsToUpdate.siteCompanyID = updatedUserFields.siteCompanyID;
		fieldsToUpdate.siteCompany = updatedUserFields.siteCompany;
	}

	if (
		initialUserState.contractedTo?.id !== updatedUserFields.contractedTo?.id
	) {
		fieldsToUpdate.contractedTo = updatedUserFields.contractedTo;
	}

	if (initialUserState.accountType !== updatedUserFields.accountType) {
		fieldsToUpdate.accountType = updatedUserFields.accountType;
	}

	if (initialUserState.disabledLeave !== updatedUserFields.disabledLeave) {
		fieldsToUpdate.disabledLeave = updatedUserFields.disabledLeave;
	}

	if (
		initialUserState.disableTimesheetApproval !==
		updatedUserFields.disableTimesheetApproval
	) {
		fieldsToUpdate.disableTimesheetApproval =
			updatedUserFields.disableTimesheetApproval;
	}

	return fieldsToUpdate;
};

export const userOptionsDialogReducer = (
	state: UserOptionsState,
	action: UserOptionsActions,
): UserOptionsState => {
	switch (action.type) {
		case UserOptionsActionTypes.UPDATE_SITE: {
			const isRecruitmentSite =
				action.payload.site?.companyType === CompanyTypes.recruitment;
			const changedFromRecruitmentSite =
				state.authorizedActions.isRecruitmentSite && !isRecruitmentSite;

			const updatedSiteFields: Pick<
				UserOptionsState['user'],
				'site' | 'siteID' | 'siteCompany' | 'siteCompanyID'
			> = action.payload.site
				? {
						siteID: action.payload.site.id,
						site: action.payload.site.name,
						siteCompany: action.payload.site.company,
						siteCompanyID: action.payload.site.companyID,
				  }
				: {
						siteID: '',
						site: '',
						siteCompany: '',
						siteCompanyID: '',
				  };

			return {
				...state,
				user: {
					...state.user,
					...updatedSiteFields,
					// users are always contracted to the site company for recruitment sites
					// we remove contractedTo when changing off a recruitment site
					contractedTo: isRecruitmentSite
						? {
								id: updatedSiteFields.siteCompanyID,
								name: updatedSiteFields.siteCompany,
						  }
						: changedFromRecruitmentSite
						? null
						: state.user.contractedTo,
				},
				authorizedActions: {
					...state.authorizedActions,
					isRecruitmentSite,
				},
			};
		}
		case UserOptionsActionTypes.UPDATE_ACCOUNT_TYPE:
			return {
				...state,
				user: {
					...state.user,
					accountType: action.payload.accountType,
					// If the user is not management we always disable timesheet approval
					disableTimesheetApproval:
						action.payload.accountType !==
							accountTypes.management &&
						state.user.disableTimesheetApproval
							? false
							: state.user.disableTimesheetApproval,
				},
				authorizedActions: {
					...state.authorizedActions,
					canChangeApproveTimesheets:
						state.accountsUserAccountType ===
							accountTypes.seniorManagement &&
						action.payload.accountType === accountTypes.management,
				},
			};
		case UserOptionsActionTypes.UPDATE_CONTRACTED_TO:
			return {
				...state,
				user: {
					...state.user,
					contractedTo: action.payload.contractedTo,
				},
			};
		case UserOptionsActionTypes.TOGGLE_DISABLED_LEAVE:
			return {
				...state,
				user: {
					...state.user,
					disabledLeave: action.payload.disabledLeave,
				},
			};
		case UserOptionsActionTypes.TOGGLE_DISABLE_TIMESHEET_APPROVAL:
			return {
				...state,
				user: {
					...state.user,
					disableTimesheetApproval:
						action.payload.disableTimesheetApproval,
				},
			};
		case UserOptionsActionTypes.UPDATE_LOADING:
			return {
				...state,
				loading: action.payload.loading,
			};
		default:
			return state;
	}
};

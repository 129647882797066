import { Button, Card, CardContent, Grid } from '@mui/material';
import { endOfWeek, startOfWeek } from 'date-fns';
import firebase from 'firebase';
import { cloneDeep } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
	Activity,
	DayString,
	TimesheetActivity,
	UserProps,
	accountTypes,
	lowercaseDayString,
} from '../../constants/Common';
import { Timesheet } from '../../constants/Timesheet/Timesheet';
import { TimesheetStatus } from '../../constants/Timesheet/TimesheetStatus';
import { FirebaseApi } from '../../firebase/firebaseApi';
import { useAbortController } from '../../hooks/useAbortController';
import { useUserAuthContext } from '../../providers/UserProvider';
import { formatActivityDisplayDay } from '../helpers/dateFormatters';
import {
	AllowDateOrTimestamp,
	getDayOfWeekDate,
} from '../helpers/dateUtilities';
import { LoadingDots } from '../Management/subcomponents/LoadingDots';
import { validateTimesheetEntries } from './HandleActivityFunctions';
import { getTimesheetActions } from './timesheetActions';
import TimesheetDisplayContent from './TimesheetDisplayContent';

/**
 * @deprecated This is the timesheets display timesheeet type and should not be used
 */
export type TimesheetType =
	| 'history'
	| 'review'
	| 'approved'
	| 'archived'
	| 'current'
	| 'submitted'
	| 'active';

type TimesheetDisplayProps = UserProps & {
	timesheet: Timesheet;
	weekEnding: [Date, (date: Date) => void];
	type: TimesheetType;
	firebaseApi: Pick<
		FirebaseApi,
		| 'deleteActivities'
		| 'deleteTimesheet'
		| 'updateTimesheetActivities'
		| 'setActivities'
		| 'getCompany'
		| 'getSite'
		| 'activitySubscription'
		| 'getDownloadUrl'
		| 'preApproveTimesheet'
	>;
	// basically used for new Timesheet review page
	compactHeader?: boolean;
};

/**
 * @deprecated This is the old timesheets display WeekActivities type and should not be used
 */
export type WeekActivities = Record<
	DayString,
	{
		activities: Record<string, Activity>;
	}
>;

/**
 * @deprecated This is an old timesheets display view type and should not be used
 */
export type TotalError = {
	totalError?: string;
};

/**
 * @deprecated This is an old timesheets display view type and should not be used
 */
export type TimesheetError = {
	typeError?: string;
	totalError?: string;
};

/**
 * Little heavy handed here but this component needs the rework and the old timesheets display is at the heart
 * @deprecated This is the old timesheets display view component and should not be used
 */
export const TimesheetDisplay = ({
	timesheet,
	userDetails,
	type,
	weekEnding,
	firebaseApi,
	compactHeader,
}: TimesheetDisplayProps): JSX.Element => {
	const abortSignal = useAbortController();
	const user = useUserAuthContext();

	const initialWeek: WeekActivities = useMemo(
		() => ({
			Monday: { activities: {} },
			Tuesday: { activities: {} },
			Wednesday: { activities: {} },
			Thursday: { activities: {} },
			Friday: { activities: {} },
			Saturday: { activities: {} },
			Sunday: { activities: {} },
		}),
		[],
	);

	const [logEndDate, setLogEndDate] = weekEnding;

	const [activities, setActivities] = useState<Record<string, Activity>>({});
	const [updatedWeekActivities, setUpdatedWeekActivities] =
		useState<WeekActivities>(initialWeek);
	const [weekActivities, setWeekActivities] =
		useState<WeekActivities>(initialWeek);

	const [logoLocation, setLogoLocation] = useState<string | undefined>('');
	const [logoURL, setLogoURL] = useState('');

	const [loading, setLoading] = useState(true);
	const [isEditing, setIsEditing] = useState(false);

	const [toDeleteActivitiesList, setToDeleteActivitiesList] = useState<
		string[]
	>([]);

	const [toAddActivitiesList, setToAddActivitiesList] = useState<
		Record<string, Activity>
	>({});

	const [deleteTimesheet, setDeleteTimesheet] = useState(false);

	const [site, setSite] = useState<string | null>(null);
	const [activityTypeMap, setActivityTypeMap] = useState<
		Record<string, TimesheetActivity>
	>({});

	const [totalErrorMap, setTotalErrorMap] = useState<
		Partial<Record<DayString, TotalError>>
	>({});
	const [errorMap, setErrorMap] = useState<
		Partial<Record<string, TimesheetError>>
	>({});

	const [shouldClearUpdates, setShouldClearUpdates] = useState(false);

	const [newTimesheetWeekEnding, setNewTimesheetWeekEnding] = useState(
		timesheet ? timesheet.weekEnding.toDate() : endOfWeek(logEndDate),
	);

	const companyID = timesheet.contractedTo?.id;

	const clearUpdates = useCallback(() => {
		setUpdatedWeekActivities(weekActivities);
		setToDeleteActivitiesList([]);
		setToAddActivitiesList({});
	}, [weekActivities]);

	useEffect(() => {
		firebaseApi.getSite(timesheet?.site.id).then((site) => {
			if (site) {
				setActivityTypeMap(
					Object.fromEntries(
						site.timesheetActivitiesV2.map((item) => {
							return [item.id, item];
						}),
					),
				);
				setSite(site.id);
			}
		});
	}, [type, timesheet?.site.id, firebaseApi]);

	useEffect(() => {
		clearUpdates();
		setIsEditing(false);
	}, [timesheet, clearUpdates]);

	useEffect(() => {
		if (!companyID) return;
		firebaseApi.getCompany(companyID).then((company) => {
			if (company) {
				setLogoLocation(company.logoLocation);
			}
		});
	}, [companyID, firebaseApi]);

	useEffect(() => {
		if (logoLocation !== undefined && logoLocation !== '') {
			const fetchCompanyLogo = async (
				logoLocation: string,
			): Promise<void> => {
				const url = await firebaseApi.getDownloadUrl(logoLocation);
				setLogoURL(url);
			};
			fetchCompanyLogo(logoLocation);
		}
	}, [firebaseApi, logoLocation]);

	useEffect(() => {
		if (!timesheet) return;
		setLoading(true);
		const activitiesSubscription = firebaseApi.activitySubscription(
			timesheet.id,
			(allActivities) => {
				setActivities(allActivities);
				setLoading(false);
			},
		);

		return (): void => {
			activitiesSubscription();
		};
	}, [firebaseApi, timesheet]);

	useEffect(() => {
		const activityItems = cloneDeep(activities);
		const weekActivitiesObj: WeekActivities = cloneDeep(initialWeek);

		Object.values(activityItems).forEach((activityItem) => {
			const day = weekActivitiesObj[activityItem.day];
			// Add to activities list
			day.activities[activityItem.id] = activityItem;
			weekActivitiesObj[activityItem.day] = day;
		});

		setWeekActivities(weekActivitiesObj);
		setUpdatedWeekActivities(weekActivitiesObj);
	}, [activities, initialWeek]);

	useEffect(() => {
		if (!timesheet) return;
		// If the timesheet week does not equal the new timesheet week the timesheet
		// week must be updated as well as its associated activities with the correct dates.
		if (
			newTimesheetWeekEnding.getTime() !==
			timesheet.weekEnding.toDate().getTime()
		) {
			const updatedTimesheet: Pick<
				Timesheet,
				'id' | 'week' | 'lastEditedBy' | 'weekEnding'
			> = {
				id: timesheet.id,
				week: firebase.firestore.Timestamp.fromDate(
					startOfWeek(newTimesheetWeekEnding),
				),
				weekEnding: firebase.firestore.Timestamp.fromDate(
					newTimesheetWeekEnding,
				),
				lastEditedBy: {
					name: userDetails.displayName,
					id: userDetails.userID,
				},
			};

			const updatedActivities: Pick<
				AllowDateOrTimestamp<Activity>,
				'id' | 'week' | 'date' | 'displayDay'
			>[] = Object.entries(activities).map(([id, activity]) => {
				const newActivityDate = getDayOfWeekDate(
					newTimesheetWeekEnding,
					activity.day,
				);
				return {
					id,
					week: newTimesheetWeekEnding,
					date: newActivityDate,
					displayDay: formatActivityDisplayDay(newActivityDate),
				};
			});

			setLogEndDate(newTimesheetWeekEnding);

			firebaseApi.updateTimesheetActivities(
				updatedTimesheet,
				updatedActivities,
			);
		}
	}, [
		activities,
		newTimesheetWeekEnding,
		setLogEndDate,
		timesheet,
		userDetails.displayName,
		userDetails.userID,
		firebaseApi,
	]);

	useEffect(() => {
		// Delete the timesheet and its activities when the state has been
		// set to true.
		if (!timesheet || deleteTimesheet === false || !user) return;
		firebaseApi.deleteTimesheet(abortSignal, user, timesheet.id);
		setDeleteTimesheet(false);
	}, [
		activities,
		deleteTimesheet,
		timesheet,
		firebaseApi,
		user,
		abortSignal,
	]);

	useEffect(() => {
		if (shouldClearUpdates) {
			clearUpdates();
			setShouldClearUpdates(false);
		}
	}, [shouldClearUpdates, clearUpdates]);

	const publishChanges = async (): Promise<void> => {
		const toDeleteActivities = [...toDeleteActivitiesList];

		const toSetActivities = Object.values(toAddActivitiesList).filter(
			(activity: Activity | undefined) => activity && activity?.hours > 0,
		);

		const [weekContentsList] = Object.values(updatedWeekActivities).reduce<
			[Activity[]]
		>(
			([allActivities], { activities }) => {
				allActivities.push(...Object.values(activities));
				return [allActivities];
			},
			[[]],
		);

		const toUpdateActivities: Activity[] = [];
		for (const item of weekContentsList) {
			const activity = cloneDeep(item);
			if (!activity) {
				continue;
			} else if (
				activity.hours > 0 &&
				toAddActivitiesList[activity.id] === undefined
			) {
				toUpdateActivities.push(activity);
			} else {
				toDeleteActivities.push(activity.id);
			}
		}

		const updatedTimesheet = cloneDeep(timesheet);
		let billableTotal = 0;

		Object.entries(updatedWeekActivities).forEach(([day, data]) => {
			const activityDayTotal = Object.values(data.activities).reduce(
				(previous, current) => (previous += current.hours),
				0,
			);
			const field = lowercaseDayString(day as DayString);

			updatedTimesheet.hours[field].billable = activityDayTotal;
			billableTotal += activityDayTotal;
		});

		updatedTimesheet.hours.total.billable = billableTotal;

		updatedTimesheet.cost.billable =
			updatedTimesheet.contract.chargeOutRate * billableTotal;

		updatedTimesheet.lastEditedBy = {
			id: userDetails.userID,
			name: userDetails.displayName,
		};

		await firebaseApi.updateTimesheetActivities(
			updatedTimesheet,
			toUpdateActivities,
		);
		await firebaseApi.setActivities(toSetActivities);
		await firebaseApi.deleteActivities(toDeleteActivities);
		setShouldClearUpdates(true);
		setIsEditing(false);
	};

	const handleWeekEndEditing = (): void => {
		!isEditing ? setIsEditing(true) : setIsEditing(false);
	};

	const handleSaveEditing = async (): Promise<void> => {
		setErrorMap({});
		setTotalErrorMap({});
		if (validateTimesheetEntries(updatedWeekActivities, setErrorMap)) {
			setIsEditing(false);
			publishChanges();
		}
	};

	const handleCancelEditing = (): void => {
		setIsEditing(false);
		clearUpdates();
		setErrorMap({});
		setTotalErrorMap({});
	};

	const timesheetAction = (): JSX.Element => {
		const getAction = getTimesheetActions(userDetails, firebaseApi);

		if (
			timesheet.timesheetStatus === TimesheetStatus.Approved ||
			timesheet.timesheetStatus === TimesheetStatus.Archived
		) {
			return <></>;
		}

		const preApproved =
			timesheet.preApproval !== undefined &&
			timesheet.timesheetStatus === TimesheetStatus.Submitted;

		const action = getAction(
			timesheet.timesheetStatus,
			preApproved,
			type === 'current',
		);

		const showSubmitButton = !(
			timesheet.preApproval &&
			(userDetails.disableTimesheetApproval ||
				userDetails.accountType === accountTypes.handler)
		);
		const showActionButtons =
			showSubmitButton && action && !action?.disabled;

		return showActionButtons ? (
			<Button
				sx={{ maxWidth: '160px' }}
				disabled={action.disabled}
				variant="contained"
				onClick={(): Promise<void> =>
					action.onClick(timesheet, activities)
				}
				fullWidth>
				{action.buttonText}
			</Button>
		) : (
			<></>
		);
	};

	if (timesheet === undefined) {
		return <></>;
	} else {
		return (
			<>
				{loading ? (
					<LoadingDots />
				) : (
					<Card elevation={0}>
						<CardContent>
							<Grid container spacing={2} justifyContent="center">
								<Grid item xs={12}>
									<TimesheetDisplayContent
										timesheetAction={timesheetAction()}
										isEditing={isEditing}
										status={timesheet.timesheetStatus}
										payrollStatus={timesheet.payrollStatus}
										name={timesheet.employee.name}
										reviewer={timesheet.reviewer?.name}
										reviewedAt={timesheet.reviewedAt?.toDate()}
										contractedTo={
											timesheet.contractedTo?.name ?? ''
										}
										hoursBillable={
											timesheet.hours.total.billable
										}
										type={type}
										preApproval={timesheet.preApproval}
										logoURL={logoURL}
										handleWeekEndEditing={
											handleWeekEndEditing
										}
										handleSaveEditing={handleSaveEditing}
										handleCancelEditing={
											handleCancelEditing
										}
										userCompany={timesheet.employer.name}
										week={timesheet.weekEnding.toDate()}
										site={timesheet.site.name}
										weekActivities={weekActivities}
										updatedWeekActivities={
											updatedWeekActivities
										}
										activityTypeMap={activityTypeMap}
										errorMap={errorMap}
										setErrorMap={setErrorMap}
										totalErrorMap={totalErrorMap}
										setNewTimesheetWeek={
											setNewTimesheetWeekEnding
										}
										setDeleteTimesheet={setDeleteTimesheet}
										newTimesheetWeek={
											newTimesheetWeekEnding
										}
										toAddActivitiesList={
											toAddActivitiesList
										}
										toDeleteActivitiesList={
											toDeleteActivitiesList
										}
										setToAddActivitiesList={
											setToAddActivitiesList
										}
										setUpdatedWeekActivities={
											setUpdatedWeekActivities
										}
										setToDeleteActivitiesList={
											setToDeleteActivitiesList
										}
										selectedSite={site}
										entriesAddToDay={{
											week: timesheet.week,
											weekEnding: timesheet.weekEnding,
											employerName:
												timesheet.employer.name,
											employerID: timesheet.employer.id,
											siteCompany: timesheet.site.company,
											siteCompanyID:
												timesheet.site.companyID,
											siteID: timesheet.site.id,
											siteName: timesheet.site.name,
											status: timesheet.timesheetStatus,
											timesheetID: timesheet.id,
											workerID: timesheet.employee.id,
											workerName: timesheet.employee.name,
											rate: timesheet.contract
												.chargeOutRate,
										}}
										compactHeader={compactHeader}
									/>
								</Grid>
							</Grid>
						</CardContent>
					</Card>
				)}
			</>
		);
	}
};

import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import {
	Grid,
	IconButton,
	ListSubheader,
	MenuItem,
	TextField,
	Tooltip,
	Typography,
} from '@mui/material';
import { cloneDeep } from 'lodash';
import { Dispatch, SetStateAction } from 'react';
import {
	Activity,
	DayString,
	EntriesAddToDay,
	TimesheetActivity,
} from '../../constants/Common';
import { roundHours } from '../../constants/Timesheet/TimesheetUtilities';
import {
	handleAddToDay,
	handleDeleteActivity,
	handleUpdateActivity,
} from './HandleActivityFunctions';
import { TimesheetError, TotalError, WeekActivities } from './TimesheetDisplay';
import { IncrementedTimeSelect } from './Timesheets/Details/EditTimesheet/IncrementedTimeSelect';

type TimesheetDisplayContentActivitiesProps = {
	isPDF: boolean;
	isEditing: boolean;
	weekActivities: WeekActivities;
	updatedWeekActivities: WeekActivities;
	activityTypeMap: Record<string, TimesheetActivity>;
	errorMap: Partial<Record<string, TimesheetError>>;
	setErrorMap: Dispatch<
		SetStateAction<Partial<Record<string, TimesheetError>>>
	>;
	totalErrorMap?: Partial<Record<DayString, TotalError>>;
	toAddActivitiesList: Record<string, Activity>;
	toDeleteActivitiesList: string[];
	setToAddActivitiesList: Dispatch<SetStateAction<Record<string, Activity>>>;
	setUpdatedWeekActivities: Dispatch<SetStateAction<WeekActivities>>;
	setToDeleteActivitiesList: Dispatch<SetStateAction<string[]>>;
	selectedSite: string | null;
	entriesAddToDay: EntriesAddToDay;
	modifyActivitiesDisabled: boolean;
};

/**
 * @deprecated This is the old timesheets display view and should not be used
 */
export default function TimesheetDisplayContentActivities({
	isPDF,
	weekActivities,
	isEditing,
	totalErrorMap,
	updatedWeekActivities,
	errorMap,
	setErrorMap,
	activityTypeMap,
	toAddActivitiesList,
	toDeleteActivitiesList,
	setToAddActivitiesList,
	setUpdatedWeekActivities,
	setToDeleteActivitiesList,
	selectedSite,
	entriesAddToDay,
	modifyActivitiesDisabled,
}: TimesheetDisplayContentActivitiesProps): JSX.Element {
	const renderDayActivities = (
		day: DayString,
		index: number,
		activity: Activity,
		isPDF: boolean,
		isEditing: boolean,
		handleUpdateActivity: (
			id: string,
			day: DayString,
			field: 'hours' | 'id',
			value: string,
			updatedWeekActivities: WeekActivities,
			toAddActivitiesList: Record<string, Activity>,
			toDeleteActivitiesList: string[],
			activityTypeMap: Record<string, TimesheetActivity>,
			setToAddActivitiesList: Dispatch<
				SetStateAction<Record<string, Activity>>
			>,
			setUpdatedWeekActivities: Dispatch<SetStateAction<WeekActivities>>,
			setToDeleteActivitiesList: Dispatch<SetStateAction<string[]>>,
			setErrorMap: Dispatch<
				SetStateAction<Partial<Record<string, TimesheetError>>>
			>,
		) => void,
		errorMap: Partial<Record<string, TimesheetError>>,
		setErrorMap: Dispatch<
			SetStateAction<Partial<Record<string, TimesheetError>>>
		>,
		activityTypeMap: Record<string, TimesheetActivity>,
	): JSX.Element => {
		const value = activity.activity?.name;

		const id = activity.activity?.id;

		return (
			<Grid
				key={index}
				container
				justifyContent="space-between"
				sx={{
					borderBottom: '1px solid rgb(240, 240, 240)',
				}}>
				<Grid item xs={4}>
					{isEditing && !isPDF ? (
						<TextField
							fullWidth
							value={id}
							label="Activity"
							disabled={modifyActivitiesDisabled}
							onChange={(event): void => {
								handleUpdateActivity(
									activity.id,
									day,
									'id',
									event.target.value,
									updatedWeekActivities,
									toAddActivitiesList,
									toDeleteActivitiesList,
									activityTypeMap,
									setToAddActivitiesList,
									setUpdatedWeekActivities,
									setToDeleteActivitiesList,
									setErrorMap,
								);
							}}
							margin="dense"
							size="small"
							select
							error={
								errorMap[activity.id]?.typeError !== undefined
							}
							helperText={errorMap[activity.id]?.typeError}>
							<ListSubheader>Activities</ListSubheader>
							{selectedSite === null ? (
								<MenuItem
									key="noSite"
									value="noSite"
									disabled={true}>
									No Site Selected
								</MenuItem>
							) : (
								Object.values(activityTypeMap).map((option) => (
									<MenuItem key={option.id} value={option.id}>
										{option.activityName}
									</MenuItem>
								))
							)}
						</TextField>
					) : (
						<Typography variant="body2">{value}</Typography>
					)}
				</Grid>
				<Grid item xs={3} display="flex">
					{isEditing && !isPDF ? (
						<IncrementedTimeSelect
							maxHours={18}
							hours={Number(activity.hours)}
							disabled={modifyActivitiesDisabled}
							setHours={(value): void =>
								handleUpdateActivity(
									activity.id,
									day,
									'hours',
									value.toString(),
									updatedWeekActivities,
									toAddActivitiesList,
									toDeleteActivitiesList,
									activityTypeMap,
									setToAddActivitiesList,
									setUpdatedWeekActivities,
									setToDeleteActivitiesList,
									setErrorMap,
								)
							}
							InputProps={{
								error:
									errorMap[activity.id]?.totalError !==
									undefined,
								helperText: errorMap[activity.id]?.totalError,
								margin: 'dense',
							}}
						/>
					) : (
						<Typography
							sx={{ width: '100%' }}
							variant="body2"
							textAlign="right">
							{roundHours(activity.hours)}
						</Typography>
					)}
				</Grid>
				<Grid item xs={1} display="flex" justifyContent="flex-end">
					{isEditing && !isPDF && (
						<Tooltip title="Delete">
							{
								// Required span: https://mui.com/material-ui/react-tooltip/#disabled-elements
							}
							<span>
								<IconButton
									size="small"
									color="primary"
									disabled={modifyActivitiesDisabled}
									onClick={(): void =>
										handleDeleteActivity(
											day,
											activity.id,
											updatedWeekActivities,
											toAddActivitiesList,
											toDeleteActivitiesList,
											setToAddActivitiesList,
											setUpdatedWeekActivities,
											setToDeleteActivitiesList,
										)
									}>
									<ClearIcon fontSize="small" />
								</IconButton>
							</span>
						</Tooltip>
					)}
				</Grid>
			</Grid>
		);
	};
	return (
		<Grid item container xs={12} spacing={1}>
			{Object.entries(weekActivities).map(
				([dayField, dayActivities], index) => {
					const day = dayField as DayString;
					const activityHours = Object.values(
						dayActivities.activities,
					).reduce(
						(prevTotal, currentActivity) =>
							prevTotal + currentActivity.hours,
						0,
					);

					return (
						<Grid
							item
							container
							key={index}
							justifyContent="center">
							<Grid
								item
								container
								justifyContent="space-between"
								xs={12}
								sx={{
									borderBottom:
										'1px solid rgb(240, 240, 240)',
								}}
								display="flex">
								<Grid item container>
									<Grid item xs={10}>
										<Typography
											alignSelf="center"
											variant="subtitle1">
											{day}
										</Typography>
									</Grid>
									<Grid
										alignSelf="center"
										justifyContent="flex-end"
										item
										xs={isEditing ? 1 : 2}>
										<Typography
											color={
												totalErrorMap
													? totalErrorMap[day]
															?.totalError &&
													  'error'
													: 'none'
											}
											textAlign="right"
											variant="subtitle1">
											{roundHours(activityHours)}
										</Typography>
									</Grid>
									{isEditing && !isPDF && (
										<Grid item xs={1}>
											<Tooltip title="Add">
												{
													// Required span: https://mui.com/material-ui/react-tooltip/#disabled-elements
												}
												<span>
													<IconButton
														size="small"
														color="primary"
														disabled={
															modifyActivitiesDisabled
														}
														name="addIconButton"
														data-testid={`${day}-add-button`}
														onClick={(): void =>
															handleAddToDay(
																day,
																updatedWeekActivities,
																toAddActivitiesList,
																entriesAddToDay,
																setUpdatedWeekActivities,
																setToAddActivitiesList,
															)
														}>
														<AddIcon />
													</IconButton>
												</span>
											</Tooltip>
										</Grid>
									)}
								</Grid>
							</Grid>
							<Grid item container xs={12}>
								{Object.values(
									cloneDeep(
										updatedWeekActivities[day].activities,
									),
								).map((activity, index) =>
									renderDayActivities(
										day,
										index,
										activity,
										isPDF,
										isEditing,
										handleUpdateActivity,
										errorMap,
										setErrorMap,
										activityTypeMap,
									),
								)}
							</Grid>
						</Grid>
					);
				},
			)}
		</Grid>
	);
}

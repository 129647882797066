import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Divider, Stack, Tab } from '@mui/material';
import { endOfWeek, startOfWeek } from 'date-fns';
import { MUIDataTableColumnDef, MUIDataTableOptions } from 'mui-datatables';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Site, UserProps } from '../../../constants/Common';
import { Timesheet } from '../../../constants/Timesheet/Timesheet';
import { TimesheetStatus } from '../../../constants/Timesheet/TimesheetStatus';
import firebaseApi from '../../../firebase/firebaseApi';
import { useFeatureFlagContext } from '../../../providers/featureFlags/Provider';
import { DateDataTable } from '../../DataTable/DateDataTable';
import { dateWithinCurrentWeeksRange } from '../../helpers/dateUtilities';
import { AddTimesheetDialog, DuplicateType } from './AddTimesheetDialog';
import { SingleTimesheetView } from './SingleTimesheetView';
import { SingleTimesheetViewDeprecated } from './SingleTimesheetViewDeprecated';

/**
 * @deprecated This is an old timesheets display view type and should not be used
 */
export type MyTimesheetsDisplayType = 'history' | 'current';

const titles = ['Current Timesheets', 'Timesheets History'];
const firebaseFilters: Record<MyTimesheetsDisplayType, TimesheetStatus[]> = {
	current: [
		TimesheetStatus.Submitted,
		TimesheetStatus.Approved,
		TimesheetStatus.Active,
	],
	history: [TimesheetStatus.Approved, TimesheetStatus.Archived],
};
const types = Object.keys(firebaseFilters) as (keyof typeof firebaseFilters)[];
const defaultTableData = types.reduce((defaultData, filterCategory) => {
	defaultData[filterCategory] = [];
	return defaultData;
}, {} as Record<MyTimesheetsDisplayType, Timesheet[]>);

export const MyTimesheets = ({ userDetails }: UserProps): JSX.Element => {
	const [tab, setTab] = useState<string>('0');
	const [tableData, setTableData] =
		useState<Record<MyTimesheetsDisplayType, Timesheet[]>>(
			defaultTableData,
		);
	const [selected, setSelected] = useState<number>(0);
	const [loading, setLoading] = useState(true);
	const [weekEnding, setWeekEnding] = useState<Date>(endOfWeek(new Date()));
	const [sites, setSites] = useState<Record<string, Site>>({});
	const [open, setOpen] = React.useState(false);

	const featureFlags = useFeatureFlagContext();
	const createTimesheetsV3 = featureFlags.get('enableCreateMyTimesheetsV3');

	useEffect(() => {
		setLoading(true);
		setTableData({ ...defaultTableData });
		const weekStart = startOfWeek(weekEnding);
		const subscriptions: (() => void)[] = [];
		Object.entries(firebaseFilters).forEach(
			([filterKey, filter], index) => {
				const timesheetSubscription =
					firebaseApi.timesheetsByWeekUserStatus(
						weekStart,
						weekEnding,
						userDetails.userID,
						filter,
						(timesheets) => {
							setTableData((prevState) => ({
								...prevState,
								[filterKey]: timesheets ?? [],
							}));
							if (index === types.length - 1) setLoading(false);
						},
					);
				subscriptions.push(timesheetSubscription);
			},
		);
		return () => {
			subscriptions.forEach((sub) => sub());
		};
	}, [
		userDetails.siteID,
		weekEnding,
		userDetails.accountType,
		userDetails.userID,
	]);

	useEffect(() => {
		setSelected(0);
	}, [tab]);

	useEffect(() => {
		const sitesSubscription = firebaseApi.activeSitesByCompanySubscription(
			userDetails.companyID,
			(sites) => {
				setSites(sites);
			},
		);
		return sitesSubscription;
	}, [userDetails.companyID, userDetails.recentSites]);

	const cellWidth = '25%';
	const currentColumns: MUIDataTableColumnDef[] = [
		{
			label: 'Site',
			name: 'name',
			options: {
				setCellHeaderProps: () => ({
					style: { width: cellWidth },
				}),
			},
		},
		{
			label: 'Date Submitted',
			name: 'date',
			options: {
				setCellHeaderProps: () => ({
					style: { width: cellWidth },
				}),
			},
		},
		{
			label: 'Status',
			name: 'status',
			options: {
				setCellHeaderProps: () => ({
					style: { width: cellWidth },
				}),
			},
		},
	];

	const historyColumns: MUIDataTableColumnDef[] = [
		{
			label: 'Site',
			name: 'name',
			options: {
				setCellHeaderProps: () => ({
					style: { width: cellWidth },
				}),
			},
		},
		{
			label: 'Date Submitted',
			name: 'date',
			options: {
				setCellHeaderProps: () => ({
					style: { width: cellWidth },
				}),
			},
		},
		{
			label: 'Hours Submitted',
			name: 'hours',
			options: {
				setCellHeaderProps: () => ({
					style: { width: cellWidth },
				}),
			},
		},
	];

	const tableLoadingOpt: MUIDataTableOptions = {
		textLabels: {
			body: {
				noMatch: loading
					? 'Loading'
					: 'Sorry, no matching timesheets found',
			},
		},
		tableBodyHeight: 'calc(100vh - 298px)',
	};

	const handleTabChange = (_: React.SyntheticEvent, value: string): void => {
		setTab(value);
	};

	const handleClickOpen = (): void => {
		setOpen(true);
	};

	const handleDialogClose = (): void => {
		setOpen(false);
	};

	const goToTimesheet = (duplicate: {
		type: Exclude<DuplicateType, DuplicateType.None>;
		index: number;
	}): void => {
		if (duplicate.type === DuplicateType.Current) {
			setTab('0');
		} else {
			setTab('1');
		}
		setSelected(duplicate.index);
	};

	return (
		<TabContext value={tab}>
			<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
				<TabList onChange={handleTabChange}>
					{titles.map((title, index) => (
						<Tab
							label={title}
							value={index.toString()}
							key={index}
						/>
					))}
				</TabList>
			</Box>
			{types.map((type, index) => (
				<TabPanel
					value={index.toString()}
					sx={{ paddingX: 0 }}
					key={index}>
					<Stack
						direction={{
							xs: 'column',
							sm: 'column',
							md: 'column',
							lg: 'row',
						}}
						divider={
							<Divider
								sx={{ ml: 1, mr: 1 }}
								orientation="vertical"
								flexItem
							/>
						}
						spacing={2}>
						<Box flex="1">
							{type === 'current' ? (
								<CurrentDateDataTable
									title={titles[index]}
									timesheets={tableData[type]}
									columns={currentColumns}
									weekEnding={weekEnding}
									setWeekEnding={setWeekEnding}
									selected={selected}
									setSelected={setSelected}
									tableOptions={tableLoadingOpt}
									handleClickOpen={handleClickOpen}
								/>
							) : (
								<HistoryDateDataTable
									title={titles[index]}
									timesheets={tableData[type]}
									columns={historyColumns}
									weekEnding={weekEnding}
									setWeekEnding={setWeekEnding}
									selected={selected}
									setSelected={setSelected}
									tableOptions={tableLoadingOpt}
									handleClickOpen={handleClickOpen}
								/>
							)}
							<AddTimesheetDialog
								open={open}
								loading={loading}
								handleClose={handleDialogClose}
								goToTimesheet={goToTimesheet}
								timesheets={tableData}
								sites={sites}
								userDetails={userDetails}
								setWeek={setWeekEnding}
								weekEnding={endOfWeek(weekEnding)}
								setSelected={setSelected}
								firebaseApi={firebaseApi}
							/>
						</Box>
						{createTimesheetsV3 ? (
							<SingleTimesheetView />
						) : (
							<SingleTimesheetViewDeprecated
								timesheet={tableData[type][selected]}
								userDetails={userDetails}
								displayType={type}
								weekEnding={[weekEnding, setWeekEnding]}
								loading={loading}
								firebaseApi={firebaseApi}
							/>
						)}
					</Stack>
				</TabPanel>
			))}
		</TabContext>
	);
};

type MyTimesheetsTableProps = {
	title: React.ReactNode;
	timesheets: Timesheet[];
	columns: MUIDataTableColumnDef[];
	weekEnding: Date;
	setWeekEnding: Dispatch<SetStateAction<Date>>;
	selected: number;
	setSelected: Dispatch<SetStateAction<number>>;
	tableOptions: MUIDataTableOptions;
	handleClickOpen?: () => void;
};

const CurrentDateDataTable = ({
	title,
	timesheets,
	columns,
	weekEnding,
	setWeekEnding,
	selected,
	setSelected,
	tableOptions,
	handleClickOpen,
}: MyTimesheetsTableProps): JSX.Element => {
	return (
		<DateDataTable
			title={title}
			tableData={timesheets.map((timesheet) => ({
				name: timesheet.site.name,
				date: timesheet.dateSubmitted?.toDate()?.toDateString() ?? '-',
				status: timesheet.timesheetStatus,
			}))}
			columns={columns}
			date={[weekEnding, setWeekEnding]}
			selection={[selected, setSelected]}
			customTableOptions={tableOptions}
			onClick={handleClickOpen}
			onClickDisabled={!dateWithinCurrentWeeksRange(weekEnding)}
			type="current"
			allowFuture
			weekEnding
			showWeekRange={false}
		/>
	);
};

const HistoryDateDataTable = ({
	title,
	timesheets,
	columns,
	weekEnding,
	setWeekEnding,
	selected,
	setSelected,
	tableOptions,
}: MyTimesheetsTableProps): JSX.Element => {
	return (
		<DateDataTable
			title={title}
			tableData={timesheets.map((timesheet) => ({
				name: timesheet.site.name,
				date: timesheet.dateSubmitted?.toDate()?.toDateString() ?? '-',
				hours: timesheet.hours.total.billable,
			}))}
			columns={columns}
			date={[weekEnding, setWeekEnding]}
			selection={[selected, setSelected]}
			customTableOptions={tableOptions}
			type="current"
			allowFuture
			weekEnding
			showWeekRange={false}
		/>
	);
};

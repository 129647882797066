import { Box, Tab, Tabs } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { Link, Outlet, useMatch } from 'react-router-dom';
import { accountTypes, Company, UserProps } from '../../../constants/Common';
import firebaseApi from '../../../firebase/firebaseApi';
import { LoadingDots } from '../subcomponents/LoadingDots';

export const optionsAccountType = [
	accountTypes.management,
	accountTypes.seniorManagement,
	accountTypes.handler,
] as const;
export type OptionsAccountType = (typeof optionsAccountType)[number];

const isOptionsAccountType = (
	accountType: string,
): accountType is OptionsAccountType => {
	return optionsAccountType.includes(
		accountType as unknown as OptionsAccountType,
	);
};

const getTitles = (
	accountType: OptionsAccountType,
	canEditSite: boolean,
): string[] => {
	switch (accountType) {
		case accountTypes.management: {
			return ['site details', 'new site', 'kiosks'];
		}
		case accountTypes.seniorManagement: {
			return ['site details', 'new site', 'company details', 'kiosks'];
		}
		case accountTypes.handler: {
			const titles = ['company details'];

			if (canEditSite) {
				titles.unshift('site details', 'new site');
			}

			return titles;
		}
	}
};

export const Options = ({ userDetails }: UserProps): JSX.Element => {
	if (!isOptionsAccountType(userDetails.accountType)) {
		throw new Error(
			`Unauthorised. Account type ${userDetails.accountType} cannot access options.`,
		);
	}

	const pathMatch = useMatch('/options/:tab');

	const [company, setCompany] = useState<Company>();

	const canEditSites =
		!!company?.canCreateSites &&
		(company.id === userDetails.siteCompanyID ||
			userDetails.siteCompanyID === '');
	const accountType = userDetails.accountType;

	const titles = useMemo(
		() => getTitles(accountType, canEditSites),
		[accountType, canEditSites],
	);

	useEffect(() => {
		return firebaseApi.companySubscription(
			userDetails.companyID,
			(company) => {
				setCompany(company);
			},
		);
	}, [userDetails.companyID]);

	return (
		<>
			<Box
				sx={{
					borderBottom: 1,
					borderColor: 'divider',
				}}>
				{pathMatch?.params?.tab ? (
					<Tabs
						variant="scrollable"
						scrollButtons="auto"
						value={pathMatch?.params?.tab?.replace(/-/g, ' ')}>
						{titles.map((title, index) => (
							<Tab
								label={title}
								value={title}
								key={index}
								component={Link}
								to={`${title.replace(/\s+/g, '-')}`}
							/>
						))}
					</Tabs>
				) : (
					<LoadingDots />
				)}
			</Box>
			<Outlet />
		</>
	);
};

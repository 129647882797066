import AddIcon from '@mui/icons-material/Add';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Typography } from '@mui/material';
import { MUIDataTableColumn, MUIDataTableOptions } from 'mui-datatables';
import { useEffect, useState } from 'react';
import { CloudFunctionApi } from '../../../cloudfunctions';
import { Guest, UserDetails, accountTypes } from '../../../constants/Common';
import {
	InducteeType,
	InductionEntry,
	inducteeType,
} from '../../../constants/InductionEntry';
import {
	SafetyCourse,
	SafetyCourseStatus,
	SafetyCourseType,
	SafetyCourseValidationResponse,
	WorkerTypes,
	safetyCourseTypeNames,
} from '../../../constants/SafetyCourse';
import {
	getSafetyCourseStatus,
	responseToSafetyCourse,
	safetyCourseToResponse,
} from '../../../constants/SafetyCourseUtilities';
import { User } from '../../../firebase/firebase';
import { FirebaseApi } from '../../../firebase/firebaseApi';
import { DateDataTableWithID } from '../../DataTable/DateDataTableWithID';
import { formatSlashedDate } from '../../helpers/dateFormatters';
import { sortByField } from '../../helpers/sortHelpers';
import { LoadingDots } from '../../Management/subcomponents/LoadingDots';
import { SafetyCourseStatusChip } from '../SafetyCourses/SafetyCourseStatusChip/SafetyCourseStatusChip';
import { InductionsTableTheme } from './InductionsTableWrapper';
import {
	NewInductionDialog,
	NewInductionDialogFirebaseCalls,
} from './NewInduction/NewInductionDialog/NewInductionDialog';

type InductionTableData = {
	id: string;
	name: string;
	inducteeType: InducteeType;
	site: string;
	company: string;
	inductionDate: Date;
	safetyCourse: string;
	courseStatus: SafetyCourseStatus | null;
	courseExpiry: string;
};

export type InductionTableProps = {
	userDetails: UserDetails;
	firebaseApi: Pick<
		FirebaseApi,
		| 'fetchInductionsByCompany'
		| 'fetchInductionsBySite'
		| 'findDuplicateSafetyCourse'
		| 'createGuest'
		| 'deleteGuest'
		| 'getInductionsBySafetyCourseIDType'
		| 'getSafetyCoursesByWorkerCourseType'
		| NewInductionDialogFirebaseCalls
	>;
	cloudFunctionApi: Pick<
		CloudFunctionApi,
		| 'validateSafetyCourse'
		| 'createInduction'
		| 'updateInduction'
		| 'createSafetyCourse'
		| 'updateSafetyCourse'
	>;
	user: User;
	abortSignal: AbortSignal;
};

export const Inductions = ({
	userDetails,
	firebaseApi,
	cloudFunctionApi,
	user,
	abortSignal,
}: InductionTableProps): JSX.Element => {
	const [inductions, setInductions] = useState<
		Record<string, InductionEntry>
	>({});
	const [tableInductionData, setTableInductionData] = useState<
		InductionTableData[]
	>([]);
	const [loading, setLoading] = useState(true);
	const [modalOpen, setModalOpen] = useState(false);
	const [loadingMap, setLoadingMap] = useState<
		Record<InductionEntry['id'], boolean>
	>({});

	const title = 'Inductions';
	const noMatchTableText = loading ? (
		<LoadingDots />
	) : (
		'Sorry, no inductions found'
	);
	const numCells = 8;
	const cellWidthCalc = (ratio: number): string =>
		`${(100 / numCells) * ratio}%`;
	const showCompanyInductions =
		userDetails.accountType === accountTypes.seniorManagement;
	const showSiteInductions =
		userDetails.accountType === accountTypes.management;

	useEffect(() => {
		const mapInductionEntryToTableData = (
			entry: InductionEntry,
		): InductionTableData => {
			const courseStatus = entry.safetyCourse
				? getSafetyCourseStatus(
						safetyCourseToResponse(entry.safetyCourse),
				  )
				: null;

			const expiry = entry.safetyCourse?.expiryDate
				? formatSlashedDate(entry.safetyCourse?.expiryDate.toDate())
				: entry.safetyCourse
				? 'N/A'
				: '-';

			return {
				id: entry.id,
				name: entry.inductee.name,
				inducteeType: entry.inductee.type,
				site: entry.site.name,
				company: entry.inductee.company,
				inductionDate: entry.dateCreated.toDate(),
				safetyCourse: entry.safetyCourse
					? safetyCourseTypeNames[entry.safetyCourse.type] ??
					  'No Course Added'
					: 'No Course Added',
				courseStatus: courseStatus,
				courseExpiry: expiry,
			};
		};

		const setInductionData = (
			data: Record<string, InductionEntry>,
		): void => {
			setInductions(data);
			const tableData = Object.values(data).map(
				mapInductionEntryToTableData,
			);
			const sortedTableData = sortByField(tableData, 'name');
			setTableInductionData(sortedTableData);
			setLoading(false);
		};

		if (showCompanyInductions) {
			return firebaseApi.fetchInductionsByCompany(
				userDetails.companyID,
				setInductionData,
			);
		} else if (showSiteInductions) {
			return firebaseApi.fetchInductionsBySite(
				userDetails.siteID,
				setInductionData,
			);
		}
	}, [
		firebaseApi,
		userDetails.companyID,
		userDetails.accountType,
		userDetails.siteID,
		showCompanyInductions,
		showSiteInductions,
	]);

	const tableOptions: MUIDataTableOptions = {
		download: false,
		print: false,
		elevation: 1,
		tableBodyHeight: 'calc(100vh - 302px)',
		viewColumns: false,
		selectableRowsHideCheckboxes: true,
		selectToolbarPlacement: 'none',
		textLabels: {
			body: {
				noMatch: noMatchTableText,
			},
		},
		customToolbar: () => <AddInductionButton />,
	};

	const columns: MUIDataTableColumn[] = [
		{
			name: 'name',
			label: 'Name',
			options: {
				setCellHeaderProps: () => ({
					style: {
						width: cellWidthCalc(1.5),
					},
				}),
			},
		},
		{
			name: 'inducteeType',
			label: 'Inductee Type',
			options: {
				setCellHeaderProps: () => ({
					style: {
						width: cellWidthCalc(1),
					},
				}),
			},
		},
		{
			name: 'site',
			label: 'Site',
			options: {
				setCellHeaderProps: () => ({
					style: {
						width: cellWidthCalc(1),
					},
				}),
			},
		},
		{
			name: 'company',
			label: 'Company',
			options: {
				setCellHeaderProps: () => ({
					style: {
						width: cellWidthCalc(1),
					},
				}),
			},
		},
		{
			name: 'inductionDate',
			label: 'Induction Date',
			options: {
				customBodyRender: (
					value: InductionTableData['inductionDate'],
				) => formatSlashedDate(value),
				setCellHeaderProps: () => ({
					style: {
						width: cellWidthCalc(1),
					},
				}),
			},
		},
		{
			name: 'safetyCourse',
			label: 'Safety Course',
			options: {
				setCellHeaderProps: () => ({
					style: {
						width: cellWidthCalc(1),
					},
				}),
			},
		},
		{
			name: 'courseStatus',
			label: 'Course Status',
			options: {
				customBodyRender: (
					status: InductionTableData['courseStatus'],
				): JSX.Element => (
					<Box display="flex" justifyContent="center">
						{status ? (
							<SafetyCourseStatusChip status={status} />
						) : (
							'-'
						)}
					</Box>
				),
				setCellHeaderProps: () => ({
					style: {
						width: cellWidthCalc(1),
					},
				}),
			},
		},
		{
			name: 'courseExpiry',
			label: 'Course Expiry',
			options: {
				setCellHeaderProps: () => ({
					style: {
						width: cellWidthCalc(1),
					},
				}),
			},
		},
		{
			name: 'id',
			label: 'Options',
			options: {
				filter: false,
				sort: false,
				searchable: false,
				customBodyRender: (id: string): JSX.Element =>
					renderOptionButtons(id),
				setCellHeaderProps: () => ({
					style: {
						width: cellWidthCalc(1.5),
					},
				}),
			},
		},
	];

	const getOrCreateSafetyCourse = async (
		inducteeID: string,
		type: InducteeType,
		safetyCourseID: string,
		safetyCourseType: SafetyCourseType,
	): Promise<SafetyCourse | undefined> => {
		let safetyCourse = await findDuplicateSafetyCourse(
			safetyCourseID,
			safetyCourseType,
		);

		// if no duplicate safety course is found we create a new one
		if (!safetyCourse && type === inducteeType.Employee) {
			safetyCourse = await cloudFunctionApi.createSafetyCourse(
				abortSignal,
				user,
				{
					workerType: WorkerTypes.Employee,
					workerID: inducteeID,
					safetyCourseType,
					safetyCourseID,
				},
			);
		}

		return safetyCourse;
	};

	const createInduction = async (
		inducteeID: string,
		type: InducteeType,
		siteID: string,
		// optional prop indicates if induction will be created with a safety course
		safetyCourseValidationResponse?: SafetyCourseValidationResponse,
	): Promise<boolean> => {
		let course: SafetyCourse['course'] | undefined;

		// Attempt to find existing safety course or create a new one if not found for employees
		if (safetyCourseValidationResponse && type === inducteeType.Employee) {
			const safetyCourse = await getOrCreateSafetyCourse(
				inducteeID,
				type,
				safetyCourseValidationResponse.id,
				safetyCourseValidationResponse.type,
			);
			// if the safety course get/creation fails we error
			if (!safetyCourse) {
				return false;
			} else {
				course = safetyCourse.course;
			}
		}
		// if the safety course belongs to a guest we create the course from the response
		// we do not save guest safety courses in our db
		else if (
			safetyCourseValidationResponse &&
			type === inducteeType.Guest
		) {
			course = responseToSafetyCourse(safetyCourseValidationResponse);
		}

		const inductionCreated = await cloudFunctionApi.createInduction(
			abortSignal,
			user,
			inducteeID,
			type,
			siteID,
			course,
		);
		return !!inductionCreated;
	};

	const refreshInduction = async (inductionID: string): Promise<void> => {
		const induction = inductions[inductionID];

		// can't update an induction if there is no safety course
		if (!induction || !induction.safetyCourse) {
			return;
		}

		setLoadingMap((prev) => ({ ...prev, [inductionID]: true }));

		let updatedSafetyCourse: SafetyCourse['course'] | undefined;
		if (induction.inductee.type === inducteeType.Employee) {
			const safetyCourse = await cloudFunctionApi.updateSafetyCourse(
				abortSignal,
				user,
				{
					workerID: induction.inductee.id,
					workerType: WorkerTypes.Employee,
					safetyCourseType: induction.safetyCourse.type,
					safetyCourseID: induction.safetyCourse.id,
				},
			);
			updatedSafetyCourse = safetyCourse?.course;
		} else {
			const safetyCourseResponse =
				await cloudFunctionApi.validateSafetyCourse(
					abortSignal,
					user,
					induction.safetyCourse.type,
					induction.safetyCourse.id,
				);
			if (safetyCourseResponse)
				updatedSafetyCourse =
					responseToSafetyCourse(safetyCourseResponse);
		}

		// can't update an induction without a safety course
		if (updatedSafetyCourse) {
			await cloudFunctionApi.updateInduction(
				abortSignal,
				user,
				induction.id,
				updatedSafetyCourse,
			);
		}

		setLoadingMap((prev) => {
			const { [inductionID]: _, ...rest } = prev;
			return rest;
		});
	};

	const saveGuestInduction = async (
		guestName: string,
		company: string,
		siteID: string,
		siteName: string,
		mobileNumber: string,
		safetyCourseValidationResponse?: SafetyCourseValidationResponse,
	): Promise<boolean> => {
		const newGuest: Omit<Guest, 'id'> = {
			displayName: guestName,
			company: company,
			siteID: siteID,
			site: siteName,
			signedIn: false,
			mobileNumber: mobileNumber,
		};
		const guestID = await firebaseApi.createGuest(newGuest);

		const success = await createInduction(
			guestID,
			inducteeType.Guest,
			siteID,
			safetyCourseValidationResponse,
		);

		// delete the new guest if the request fails
		if (!success) {
			await firebaseApi.deleteGuest(guestID);
		}

		return success;
	};

	const saveExistingInduction = async (
		workerType: InducteeType,
		workerID: string,
		siteID: string,
		safetyCourseValidationResponse?: SafetyCourseValidationResponse,
	): Promise<boolean> => {
		const success = await createInduction(
			workerID,
			workerType,
			siteID,
			safetyCourseValidationResponse,
		);
		return success;
	};

	const validateSafetyCourse = async (
		courseID: string,
		courseType: SafetyCourseType,
	): Promise<SafetyCourseValidationResponse | undefined> =>
		await cloudFunctionApi.validateSafetyCourse(
			abortSignal,
			user,
			courseType,
			courseID,
		);

	const findDuplicateSafetyCourse = async (
		courseID: string,
		courseType: SafetyCourseType,
	): Promise<SafetyCourse | undefined> =>
		await firebaseApi.findDuplicateSafetyCourse(courseID, courseType);

	const validateExistingSafetyCourse = async (
		safetyCourse: SafetyCourse,
		inducteeID: string,
	): Promise<SafetyCourse | undefined> => {
		// if a duplicate safety course is found we check if it has an associated induction
		const safetyCourseInductions =
			await firebaseApi.getInductionsBySafetyCourseIDType(
				safetyCourse.course.id,
				safetyCourse.course.type,
			);

		const safetyCourseInduction = safetyCourseInductions.find(
			(induction) => induction.inductee.type === inducteeType.Employee,
		);

		// if an existing induction safety course is found belonging to a user we error, we allow duplicates for inductions belonging to guests
		if (safetyCourseInduction?.inductee.type === inducteeType.Employee) {
			return;
		}

		// if the existing safety course worker ID does not match the inductee ID for employees we error
		if (
			safetyCourse.worker.id !== inducteeID &&
			safetyCourse.worker.type === WorkerTypes.Employee
		) {
			return;
		}
		return safetyCourse;
	};

	const AddInductionButton = (): JSX.Element => (
		<Button
			endIcon={<AddIcon />}
			variant="contained"
			onClick={(): void => setModalOpen(true)}
			disableElevation
			sx={{ ml: 1 }}>
			New Induction
		</Button>
	);

	const renderOptionButtons = (id: string): JSX.Element => {
		const loading = loadingMap[id] || false;

		const handleRefreshOption = (): Promise<void> => refreshInduction(id);

		return (
			<Box justifyContent="center" width="100%">
				<LoadingButton
					loading={loading}
					variant="contained"
					fullWidth
					onClick={handleRefreshOption}
					disabled={
						!inductions[id].safetyCourse ||
						inductions[id].safetyCourse?.type ===
							SafetyCourseType.SiteSafe
					}>
					Refresh
				</LoadingButton>
			</Box>
		);
	};

	return (
		<>
			<InductionsTableTheme>
				<DateDataTableWithID
					title={<Typography variant="h4">{title}</Typography>}
					columns={columns}
					tableData={tableInductionData}
					customTableOptions={tableOptions}
				/>
			</InductionsTableTheme>
			<NewInductionDialog
				userDetails={userDetails}
				modalOpen={modalOpen}
				setModalOpen={setModalOpen}
				exisitingInductions={Object.values(inductions)}
				firebaseApi={firebaseApi}
				saveGuestInduction={saveGuestInduction}
				saveExistingInducteeInduction={saveExistingInduction}
				validateSafetyCourse={validateSafetyCourse}
				findDuplicateSafetyCourse={findDuplicateSafetyCourse}
				validateExistingSafetyCourse={validateExistingSafetyCourse}
			/>
		</>
	);
};

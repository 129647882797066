import { Box, Grid, Typography } from '@mui/material';
import { Timesheet } from '../../../../constants/Timesheet/Timesheet';
import {
	MultiStageStatus,
	TimesheetStatus,
} from '../../../../constants/Timesheet/TimesheetStatus';
import {
	formatSpacedDate,
	formatSpacedDateTime,
} from '../../../helpers/dateFormatters';
import { LoadableImage } from '../../../Image/LoadableImage';
import { TimesheetStatusChip } from '../../TimesheetStatusChip';
import { ReviewerCard, ReviewStatus } from './ReviewerCard';
import { TimesheetEmployerCard } from './TimesheetEmployerCard';
import { TimesheetHeaderItem } from './TimesheetHeaderItem';
import { TimesheetSiteCard } from './TimesheetSiteCard';

export type TimesheetHeaderProps = {
	timesheetHeaderFields: {
		employeeName: Timesheet['employee']['name'];
		employerName: Timesheet['employer']['name'];
		contractedToCompany:
			| NonNullable<Timesheet['contractedTo']>['name']
			| null;
		reviewer: Timesheet['reviewer'];
		reviewedAt: Timesheet['reviewedAt'];
		status: Timesheet['timesheetStatus'];
		siteName: Timesheet['site']['name'];
		week: Timesheet['week'];
		preApproval: Timesheet['preApproval'];
	};
	companyLogo: string;
	companyLogoLoading: boolean;
};

export const TimesheetHeader = ({
	timesheetHeaderFields,
	companyLogo,
	companyLogoLoading,
}: TimesheetHeaderProps): JSX.Element => {
	const status =
		timesheetHeaderFields?.preApproval &&
		timesheetHeaderFields.status === TimesheetStatus.Submitted
			? MultiStageStatus.PreApproved
			: timesheetHeaderFields.status;

	return (
		<Box display="flex" paddingX={2} marginY={1}>
			<Grid container data-testid="timesheet-header" rowGap={1}>
				<Grid item container spacing={1}>
					<TimesheetHeaderItem
						justifyContent={{
							xs: 'flex-start',
							sm: 'flex-start',
							md: 'flex-start',
						}}
						breakpoints={{ xs: 12, sm: 5, md: 5 }}>
						<Typography variant="h5Bold">
							{timesheetHeaderFields.employeeName}
						</Typography>
					</TimesheetHeaderItem>
					<TimesheetHeaderItem
						justifyContent={{
							xs: 'flex-start',
							sm: 'flex-start',
							md: 'flex-start',
						}}
						breakpoints={{ xs: 12, sm: 4, md: 4 }}>
						<Typography variant="h5Bold">
							{`WE ${formatSpacedDate(
								timesheetHeaderFields.week.toDate(),
							)}`}
						</Typography>
					</TimesheetHeaderItem>
					<TimesheetHeaderItem
						justifyContent={{
							xs: 'flex-start',
							sm: 'flex-end',
							md: 'flex-end',
						}}
						breakpoints={{ xs: 12, sm: 3, md: 3 }}>
						<TimesheetStatusChip status={status} />
					</TimesheetHeaderItem>
				</Grid>
				<Grid
					item
					container
					spacing={1}
					direction="row"
					justifyContent="flex-start">
					<Grid
						item
						container
						xs={12}
						sm={12}
						md={10}
						justifyItems="flex-start"
						rowGap={1}>
						<Grid
							item
							container
							xs={12}
							sm={12}
							md={12}
							alignItems="start"
							direction="row"
							spacing={1}>
							<TimesheetHeaderItem
								breakpoints={{ xs: 12, sm: 5, md: 6 }}>
								<TimesheetSiteCard
									context={{
										siteName:
											timesheetHeaderFields.siteName,
									}}
								/>
							</TimesheetHeaderItem>
							<TimesheetHeaderItem
								justifyContent={{
									xs: 'flex-start',
									sm: 'flex-start',
									md: 'flex-start',
								}}
								breakpoints={{ xs: 12, sm: 5, md: 6 }}>
								<TimesheetEmployerCard
									context={{
										employerName:
											timesheetHeaderFields.employerName,
									}}
								/>
							</TimesheetHeaderItem>
						</Grid>
						<Grid
							item
							container
							xs={12}
							sm={12}
							md={12}
							alignItems="start"
							direction="row"
							spacing={1}>
							<TimesheetHeaderItem
								breakpoints={{ xs: 12, sm: 5, md: 6 }}>
								<ReviewerCard
									review={
										timesheetHeaderFields.reviewer &&
										timesheetHeaderFields.reviewedAt
											? {
													status: ReviewStatus.Reviewed,
													name: timesheetHeaderFields
														.reviewer.name,
													reviewedAt:
														formatSpacedDateTime(
															timesheetHeaderFields.reviewedAt.toDate(),
														),
											  }
											: {
													status: ReviewStatus.Pending,
													pendingReviewCompany:
														timesheetHeaderFields.contractedToCompany,
											  }
									}
								/>
							</TimesheetHeaderItem>
							{timesheetHeaderFields.preApproval ? (
								<TimesheetHeaderItem
									justifyContent={{
										xs: 'flex-start',
										sm: 'flex-start',
										md: 'flex-start',
									}}
									breakpoints={{ xs: 12, sm: 5, md: 6 }}>
									<ReviewerCard
										review={{
											status: ReviewStatus.PreApproved,
											name: timesheetHeaderFields
												.preApproval.reviewer.name,
											reviewedAt: formatSpacedDateTime(
												timesheetHeaderFields.preApproval.reviewedAt.toDate(),
											),
											hours: timesheetHeaderFields
												.preApproval.hours.total
												.billable,
										}}
									/>
								</TimesheetHeaderItem>
							) : (
								<></>
							)}
						</Grid>
					</Grid>
					<TimesheetHeaderItem
						justifyContent={{
							xs: 'center',
							sm: 'center',
							md: 'flex-end',
						}}
						breakpoints={{ xs: 12, sm: 12, md: 2 }}>
						<LoadableImage
							src={companyLogo}
							srcLoading={companyLogoLoading}
							altText="Company Logo"
							imageStyle={{
								maxWidth: '102px',
							}}
						/>
					</TimesheetHeaderItem>
				</Grid>
			</Grid>
		</Box>
	);
};

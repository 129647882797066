import BusinessIcon from '@mui/icons-material/Business';
import { Avatar, Box, Stack, Typography } from '@mui/material';
import { DefaultTheme } from '../../../../Theme';

export type TimesheetEmployerCardProps = {
	context: {
		employerName: string;
	};
};

export const TimesheetEmployerCard = ({
	context,
}: TimesheetEmployerCardProps): JSX.Element => (
	<Stack direction="row" spacing={2} alignItems="flex-start">
		<Avatar
			sx={{
				backgroundColor: DefaultTheme.palette.tertiary.default,
				color: DefaultTheme.palette.info.contrastText,
			}}>
			<BusinessIcon color="inherit" />
		</Avatar>
		<Box>
			<Typography variant="h7Bold">Employer</Typography>
			<Typography fontSize="small">{context.employerName}</Typography>
		</Box>
	</Stack>
);

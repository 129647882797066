import { Box, Tab, Tabs } from '@mui/material';
import { startOfWeek } from 'date-fns';
import {
	Dispatch,
	SetStateAction,
	useCallback,
	useEffect,
	useMemo,
} from 'react';
import { Link, useMatch, useSearchParams } from 'react-router-dom';
import { CloudFunctionApi } from '../../../cloudfunctions';
import {
	accountTypes,
	assertAccountType,
	Company,
	emptyFunction,
	Site,
	UserDetails,
	UserProps,
} from '../../../constants/Common';
import {
	getTimesheetConfig,
	InvoiceStatuses,
	ProjectTrackingStatuses,
	TempTimesheet,
	Timesheet,
	TimesheetAccountType,
	timesheetAccountType,
	TimesheetPayrollStatuses,
} from '../../../constants/Timesheet/Timesheet';
import type { FirebaseApi } from '../../../firebase/firebaseApi';
import { useCompanySubscriptionContext } from '../../../providers/CompanySubscriptionProvider';
import { formatSearchParamsDate } from '../../helpers/dateFormatters';
import { sortBySubField, sortObjectByField } from '../../helpers/sortHelpers';

export type TimesheetsFirebaseCalls =
	| 'activeSitesByCompanySubscription'
	| 'subscribeTimesheetUsersByCompany'
	| 'subscribeTimesheetUsersByContracted'
	| 'subscribeUsersByContractedSite'
	| 'timesheetsByWeekContractedStatus'
	| 'timesheetsByWeekEmployerStatus'
	| 'timesheetsByWeekSiteContractedStatus'
	| 'companiesSubscription';

type TimesheetsProps = UserProps & {
	firebaseApi: Pick<FirebaseApi, TimesheetsFirebaseCalls>;
	setTimesheetData: Dispatch<SetStateAction<(Timesheet | TempTimesheet)[]>>;
	setSites: Dispatch<SetStateAction<Record<string, Site>>>;
	setCompanies: Dispatch<SetStateAction<Record<string, Company>>>;
	setUsers: Dispatch<SetStateAction<Record<string, UserDetails>>>;
	users: Record<string, UserDetails>;
	weekEnding: Date;
	weekString: string;
	setLoading: Dispatch<boolean>;
	cloudFunctionApi: Pick<
		CloudFunctionApi,
		'sendTimesheetReminderNotification'
	>;
};

export const Timesheets = ({
	userDetails,
	firebaseApi,
	setTimesheetData,
	setSites,
	setCompanies,
	setUsers,
	users,
	weekEnding,
	weekString,
	setLoading,
}: TimesheetsProps): JSX.Element => {
	const accountType = userDetails.accountType;
	assertAccountType<TimesheetAccountType>(accountType, timesheetAccountType);
	const companyContext = useCompanySubscriptionContext();
	const config = useMemo(
		() =>
			getTimesheetConfig(
				accountType,
				companyContext.company?.canCreateSites &&
					companyContext.company.id === userDetails.siteCompanyID,
			),
		[
			accountType,
			companyContext.company?.canCreateSites,
			companyContext.company?.id,
			userDetails.siteCompanyID,
		],
	);

	const firebaseFilter = config.firebaseFilters.current;
	const titles = config.titles;
	const fetchByCompany = accountType === accountTypes.handler;
	const fetchBySite = accountType === accountTypes.management;
	const fetchByContractedTo = accountType === accountTypes.seniorManagement;

	const pathMatch = useMatch('/timesheets/:tab');
	const [searchParams] = useSearchParams();

	const setTimesheetDataCallback = useCallback(
		(sheets: (Timesheet | TempTimesheet)[]) => {
			// Inclued employees without timesheets for current and previous week
			if (weekString === 'This Week' || weekString === 'Last Week') {
				// First find what users don't have timesheets
				const userIDs = Object.keys(users);

				const timesheetUsersIDs: string[] = sheets.map(
					(timesheet) => timesheet.employee.id,
				);
				const usersWithoutTimesheets = userIDs.filter(
					(id) => !timesheetUsersIDs.includes(id),
				);
				// Add missing users to timesheets array for the table
				usersWithoutTimesheets.forEach((userID) =>
					// Cast needed as this is this is an incomplete timesheet
					// Is this the best way to do this?
					sheets.push(emptyTimesheetEntry(users[userID], weekEnding)),
				);
			}
			const sortedTimesheets = sortBySubField(sheets, 'employee', 'name');
			setTimesheetData(sortedTimesheets);
			setLoading(false);
		},
		[weekString, setTimesheetData, setLoading, users, weekEnding],
	);

	useEffect(() => {
		const handleUsers = (users: Record<string, UserDetails>): void => {
			// Where no users are returned
			if (Object.keys(users).length === 0) {
				setUsers({});
				setTimesheetData([]); // hack to make things refresh properly when the last user is removed from a site/company
				setLoading(false);
			} else {
				setUsers(sortObjectByField(users, 'displayName'));
			}
		};

		if (fetchByCompany) {
			return firebaseApi.subscribeTimesheetUsersByCompany(
				userDetails.companyID,
				handleUsers,
			);
		} else if (fetchBySite) {
			return firebaseApi.subscribeUsersByContractedSite(
				userDetails.companyID,
				userDetails.siteID,
				handleUsers,
			);
		} else if (fetchByContractedTo) {
			return firebaseApi.subscribeTimesheetUsersByContracted(
				userDetails.companyID,
				handleUsers,
			);
		}
		return emptyFunction;
	}, [
		userDetails.companyID,
		userDetails.siteID,
		firebaseApi,
		fetchByCompany,
		fetchBySite,
		fetchByContractedTo,
		setLoading,
		setUsers,
		setTimesheetData,
	]);

	useEffect(() => {
		return firebaseApi.activeSitesByCompanySubscription(
			userDetails.companyID,
			(sites) => setSites(sortObjectByField(sites, 'name')),
		);
	}, [userDetails.companyID, firebaseApi, setSites]);

	useEffect(() => {
		return firebaseApi.companiesSubscription((snapshot) => {
			const companiesData = snapshot.docs.reduce<Record<string, Company>>(
				(allCompanies, doc) => {
					allCompanies[doc.id] = doc.data();
					return allCompanies;
				},
				{},
			);
			setCompanies(companiesData);
		});
	}, [userDetails.companyID, firebaseApi, setCompanies, setLoading]);

	useEffect(() => {
		if (!firebaseFilter) {
			return;
		}
		setLoading(true);
		setTimesheetData([]);
		const weekStart = startOfWeek(weekEnding);
		/**
		 * According to https://tradelegion.atlassian.net/l/c/0rRrJPjH
		 *
		 * recuiter (handler) sees own company timesheets
		 * admin people (payroll) sees own company timesheets
		 * senior management sees all contractedTo timesheets
		 * management sees all contractTo timesheets on their site
		 */
		if (fetchByCompany) {
			return firebaseApi.timesheetsByWeekEmployerStatus(
				weekStart,
				weekEnding,
				userDetails.companyID,
				firebaseFilter,
				(timesheets: Timesheet[]): void =>
					setTimesheetDataCallback(timesheets),
			);
		} else if (fetchBySite) {
			return firebaseApi.timesheetsByWeekSiteContractedStatus(
				weekStart,
				weekEnding,
				userDetails.siteID,
				userDetails.companyID,
				firebaseFilter,
				(timesheets: Timesheet[]): void =>
					setTimesheetDataCallback(timesheets),
			);
		} else if (fetchByContractedTo) {
			return firebaseApi.timesheetsByWeekContractedStatus(
				weekStart,
				weekEnding,
				userDetails.companyID,
				firebaseFilter,
				(timesheets: Timesheet[]): void =>
					setTimesheetDataCallback(timesheets),
			);
		}
	}, [
		userDetails.siteID,
		weekEnding,
		firebaseFilter,
		userDetails.companyID,
		setTimesheetDataCallback,
		fetchByCompany,
		fetchBySite,
		fetchByContractedTo,
		setLoading,
		setTimesheetData,
		firebaseApi,
	]);

	return (
		<Box
			sx={{
				borderBottom: 1,
				borderColor: 'divider',
			}}>
			<Tabs
				variant="scrollable"
				scrollButtons="auto"
				value={pathMatch?.params?.tab?.replace(/-/g, ' ')}>
				{titles.map((title, index) => (
					<Tab
						label={title}
						value={title}
						key={index}
						component={Link}
						to={`${title.replace(/\s+/g, '-')}?${searchParams}`}
					/>
				))}
			</Tabs>
		</Box>
	);
};

// Not sure this is the way to go about this but essentially there needs to be
// an empty timesheet for employees that haven't submitted one for the data table
const emptyTimesheetEntry = (user: UserDetails, week: Date): TempTimesheet => ({
	id: `${user.userID}-${formatSearchParamsDate(week)}`,
	employee: {
		name: user.displayName,
		id: user.userID,
		paid: false,
		type: 'Not Selected',
	},
	employer: { name: user.company, id: user.companyID },
	site: {
		companyID: user.companyID,
		company: user.company,
		id: user.siteID,
		name: user.site,
	},
	hours: {
		monday: { billable: 0, break: 0 },
		tuesday: { billable: 0, break: 0 },
		wednesday: { billable: 0, break: 0 },
		thursday: { billable: 0, break: 0 },
		friday: { billable: 0, break: 0 },
		saturday: { billable: 0, break: 0 },
		sunday: { billable: 0, break: 0 },
		total: { billable: 0, break: 0 },
	},
	dateSubmitted: null,
	contractedTo: user.contractedTo,
	payrollStatus: TimesheetPayrollStatuses.Unsent,
	projectTrackingStatus: ProjectTrackingStatuses.Unsent,
	invoiceStatus: InvoiceStatuses.Unsent,
	timesheetStatus: null,
});

import { Typography } from '@mui/material';
import { MUIDataTableOptions } from 'mui-datatables';
import { useCallback, useEffect, useState } from 'react';
import {
	accountApprovalsMap,
	AccountsAccountType,
	accountsAccountType,
} from '../../constants/Accounts';
import {
	AccountType,
	AccountTypeHumanName,
	Company,
	CompanyTypes,
	Site,
	UserDetails,
	accountTypes,
	assertAccountType,
} from '../../constants/Common';
import { FirebaseApi } from '../../firebase/firebaseApi';
import { DataTable } from '../DataTable/DataTable';
import { AccountsTableTheme } from '../Documents/TableWrappers/AccountsTableWrapper';
import { contractedToCustomTableSort } from '../helpers/muiDataTableCustomSorts';
import { sortByField, sortObjectByField } from '../helpers/sortHelpers';
import { LoadingDots } from '../Management/subcomponents/LoadingDots';
import { UsersOptionsDialog } from './UserOptionsDialog/UsersOptionsDialog';
import { UsersOptions } from './UsersOptions';

export type UsersProps = {
	userDetails: UserDetails;
	firebaseApi: Pick<
		FirebaseApi,
		| 'subscribeNonKioskUsersByCompany'
		| 'companiesSubscriptionByType'
		| 'activeSitesByCompanySubscription'
		| 'activeSitesByCompanyTypeSubscription'
		| 'updateUserAccountDetails'
		| 'updateUserProfileCompany'
		| 'updateUserDetailsCompanyInfo'
	>;
};

export const Users = ({
	userDetails,
	firebaseApi,
}: UsersProps): JSX.Element => {
	const accountType = userDetails.accountType;
	assertAccountType<AccountsAccountType>(accountType, accountsAccountType);

	const [selectedUserID, setSelectedUserID] = useState<string>('');
	const [users, setUsers] = useState<Record<string, UserDetails>>({});
	const [sites, setSites] = useState<Record<string, Site>>({});
	const [companies, setCompanies] = useState<Record<string, Company>>({});
	const [loading, setLoading] = useState(true);
	const [modalOpen, setModalOpen] = useState(false);

	const canManageAllUsersInCompany =
		accountType === accountTypes.handler ||
		accountType === accountTypes.seniorManagement;
	const showContractedTo = accountType === accountTypes.handler;
	const noMatchTableText = loading ? (
		<LoadingDots />
	) : (
		<Typography>
			{`There are no users for your ${
				canManageAllUsersInCompany ? 'company' : 'site'
			}`}
		</Typography>
	);
	const numCells = 5;
	const cellWidth = { width: `${100 / numCells}%` };
	const setCellHeaderProps = (): {
		style: {
			width: string;
		};
	} => ({
		style: { ...cellWidth },
	});

	const columns = [
		{
			name: 'displayName',
			label: 'Name',
			options: {
				setCellHeaderProps,
			},
		},
		{
			name: 'accountType',
			label: 'Account Type',
			options: {
				setCellHeaderProps,
				customBodyRender: (accountType: AccountType): JSX.Element => (
					<>{AccountTypeHumanName[accountType]}</>
				),
			},
		},
		{
			name: 'site',
			label: 'Site',
			options: {
				setCellHeaderProps,
			},
		},
		{
			name: showContractedTo ? 'contractedTo' : 'company',
			label: showContractedTo ? 'Client' : 'Company',
			options: {
				setCellHeaderProps,
				customBodyRender: (
					company:
						| UserDetails['contractedTo']
						| UserDetails['company'],
				) => (typeof company === 'string' ? company : company?.name),
				sortCompare: contractedToCustomTableSort,
			},
		},
		{
			name: 'userID',
			label: 'Options',
			options: {
				sort: false,
				filter: false,
				searchable: false,
				setCellHeaderProps,
				customBodyRender: (userID: string): JSX.Element => (
					<UsersOptions
						modalOpen={modalOpen && selectedUserID === userID}
						setModalOpen={setModalOpen}
						setSelected={(userID: string): void =>
							setSelectedUserID(userID)
						}
						accountType={accountType}
						existingUser={users[userID]}
						canRemoveUser={
							canManageAllUsersInCompany &&
							userID !== userDetails.userID &&
							users[userID]?.companyID === userDetails.companyID
						}
						sites={sites}
						firebaseApi={firebaseApi}
					/>
				),
			},
		},
	];

	const tableOptions: MUIDataTableOptions = {
		download: false,
		print: false,
		elevation: 1,
		tableBodyHeight: 'calc(100vh - 302px)',
		viewColumns: false,
		selectToolbarPlacement: 'none',
		textLabels: {
			body: {
				noMatch: noMatchTableText,
			},
		},
	};

	const setApprovedUsersCallback = useCallback(
		(newUsers: UserDetails[]): void => {
			const filteredUsers = newUsers.filter((user) =>
				accountApprovalsMap[accountType].includes(user.accountType),
			);
			const sortedUsers = sortByField(filteredUsers, 'displayName');
			const usersRecord = sortedUsers.reduce<Record<string, UserDetails>>(
				(allUsers, user) => {
					allUsers[user.userID] = user;
					return allUsers;
				},
				{},
			);
			setUsers(usersRecord);
			setLoading(false);
		},
		[accountType],
	);

	useEffect(() => {
		return firebaseApi.subscribeNonKioskUsersByCompany(
			userDetails.companyID,
			setApprovedUsersCallback,
		);
	}, [firebaseApi, setApprovedUsersCallback, userDetails]);

	useEffect(() => {
		const companiesSubscription = firebaseApi.companiesSubscriptionByType(
			CompanyTypes.construction,
			(companiesList) => {
				const companiesMap = companiesList.reduce(
					(companyRecords, company) => {
						companyRecords[company.id] = company;
						return companyRecords;
					},
					{} as Record<string, Company>,
				);
				setCompanies(sortObjectByField(companiesMap, 'name'));
			},
		);
		if (!showContractedTo) {
			return firebaseApi.activeSitesByCompanySubscription(
				userDetails.companyID,
				(siteMap) => {
					setSites(sortObjectByField(siteMap, 'name'));
				},
			);
		} else {
			const companySitesSubscription =
				firebaseApi.activeSitesByCompanySubscription(
					userDetails.companyID,
					(siteMap) => {
						setSites((prevState) => ({
							...prevState,
							...sortObjectByField(siteMap, 'name'),
						}));
					},
				);
			const constructionSites =
				firebaseApi.activeSitesByCompanyTypeSubscription(
					CompanyTypes.construction,
					(siteMap) => {
						setSites((prevState) => ({
							...prevState,
							...sortObjectByField(siteMap, 'name'),
						}));
					},
				);
			return (): void => {
				constructionSites();
				companySitesSubscription();
				companiesSubscription();
			};
		}
	}, [
		firebaseApi,
		showContractedTo,
		userDetails.accountType,
		userDetails.companyID,
	]);

	const handleCloseDialog = (): void => {
		setModalOpen(false);
		setSelectedUserID('');
	};

	return (
		<>
			<AccountsTableTheme centerColumnHeader={5}>
				<DataTable
					tableData={Object.values(users)}
					columns={columns}
					title={`${userDetails.company} Users`}
					customTableOptions={tableOptions}
				/>
			</AccountsTableTheme>
			{users[selectedUserID] && (
				<UsersOptionsDialog
					modalOpen={modalOpen}
					handleCloseDialog={handleCloseDialog}
					accountsUser={{
						isCurrentSelected:
							selectedUserID === userDetails.userID,
						companyID: userDetails.companyID,
						accountType: accountType,
					}}
					selectedUser={users[selectedUserID]}
					companies={companies}
					sites={sites}
					firebaseApi={firebaseApi}
				/>
			)}
		</>
	);
};

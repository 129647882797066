import {
	Autocomplete,
	MenuItem,
	TextField,
	TextFieldProps,
} from '@mui/material';
import React from 'react';
import { FixMeLater } from '../../constants/AnyTypes';
import { Site } from '../../constants/Common';
import {
	GroupBy,
	sortObjectByField,
	sortObjectByGroupBy,
} from '../helpers/sortHelpers';

export type SiteSelectDropdownProps<
	GroupByOrder extends string[] = [
		SiteSelectGroup.RecentSites,
		SiteSelectGroup.Sites,
	],
> = {
	onChange: (event: React.SyntheticEvent, value: Site | null) => void;
	value: Site | null;
	sites: Record<string, Site>;
	error: boolean;
	disabled?: boolean;
	fullWidth?: boolean;
	size?: 'small' | 'medium';
	disableClearable?: boolean;
	inputLabel?: string;
	placeHolder?: string;
	required?: boolean;
	helperText?: TextFieldProps['helperText'];
	groupBy?: GroupBy<GroupByOrder, Site>;
};

export enum SiteSelectGroup {
	RecentSites = 'Recent Sites',
	Sites = 'Sites',
}

export const SiteSelectDropdown = function <
	GroupByOrder extends string[] = [
		SiteSelectGroup.RecentSites,
		SiteSelectGroup.Sites,
	],
>({
	onChange,
	value,
	sites,
	error,
	placeHolder,
	groupBy,
	disabled = false,
	fullWidth = true,
	disableClearable = false,
	size = 'medium',
	inputLabel = 'Select Site',
	required = false,
	helperText = 'You must select a site',
}: SiteSelectDropdownProps<GroupByOrder>): JSX.Element {
	// Construct sorted site object list with their provided site grouping
	let sortedSites = sortObjectByField(sites, 'name');
	if (groupBy) {
		sortedSites = sortObjectByGroupBy(
			sortedSites,
			groupBy.getGroup,
			groupBy.order,
		);
	}
	const keyGroupBy = groupBy?.getGroup ?? ((): string => '');

	return (
		<Autocomplete
			options={Object.values(sortedSites)}
			getOptionLabel={(option): string => option.name}
			groupBy={groupBy?.getGroup}
			fullWidth={fullWidth}
			disabled={disabled}
			disableClearable={disableClearable}
			onChange={onChange}
			value={sortedSites[value?.id ?? ''] ?? null}
			isOptionEqualToValue={(option, value): boolean =>
				option.id === value.id
			}
			renderOption={(props: FixMeLater, option: Site): JSX.Element => (
				<MenuItem
					{...props}
					label={option.name}
					variant="standard"
					key={option.id + keyGroupBy(option)}>
					{option.name || 'No Site'}
				</MenuItem>
			)}
			renderInput={(params): JSX.Element => (
				<TextField
					{...params}
					label={inputLabel}
					size={size}
					required={required}
					fullWidth={fullWidth}
					inputProps={{
						...params.inputProps,
					}}
					error={error}
					helperText={error ? helperText : null}
					placeholder={placeHolder}
				/>
			)}
		/>
	);
};

import { CompanyTypes, Site, SiteStatus } from '../Common';

export const emptySite = (): Site => ({
	id: '',
	address: '',
	allowAppSignIn: false,
	autoSignOutTime: -1,
	city: '',
	company: '',
	companyID: '',
	companyType: CompanyTypes.construction,
	createdBy: '',
	createdByID: '',
	hasInductions: false,
	jobNumber: '',
	name: '',
	region: '',
	safetyCourseRequiredForInduction: false,
	showCovidWarning: false,
	siteContact: '',
	siteContactNumber: '',
	startTime: 0,
	status: SiteStatus.Active,
	timesheetActivitiesV2: [],
});

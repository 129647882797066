import { LoadingButton } from '@mui/lab';
import { Box, TextField, Tooltip } from '@mui/material';
import { differenceInDays, startOfToday, startOfWeek } from 'date-fns';
import firebase from 'firebase';
import { MUIDataTableColumnDef, MUIDataTableOptions } from 'mui-datatables';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import type { CloudFunctionApi } from '../../cloudfunctions';
import { onDownload } from '../../constants/CsvExport';
import { Leave, ReviewStatus, leaveLabels } from '../../constants/Leave';
import { LeaveNote } from '../../constants/Note';
import type { FirebaseApi } from '../../firebase/firebaseApi';
import { useAbortController } from '../../hooks/useAbortController';
import { useUserDetailsContext } from '../../providers/UserProvider';
import { DataTable } from '../DataTable/DataTable';
import { DateDataTable } from '../DataTable/DateDataTable';
import { formatSlashedDate } from '../helpers/dateFormatters';
import {
	leaveDurationCustomTableSort,
	nameCustomTableSort,
} from '../helpers/muiDataTableCustomSorts';
import { ReviewStatusIcon } from './ReviewStatusIcon';

type LeaveReviewTableProps = {
	title: 'Reviewed' | 'Unreviewed';
	allLeaveEntries: Leave[];
	startDate?: [Date, Dispatch<SetStateAction<Date>>];
	changeStartDate: (date: Date) => void;
	leaveNotes: Record<string, LeaveNote[]>;
	user?: firebase.User | null;
	firebaseApi: Pick<FirebaseApi, 'reviewLeave'>;
	cloudFunctionApi: Pick<CloudFunctionApi, 'createLeaveNote'>;
};

export const LeaveReviewTable = ({
	title,
	allLeaveEntries,
	changeStartDate,
	leaveNotes,
	user,
	firebaseApi,
	cloudFunctionApi,
}: LeaveReviewTableProps): JSX.Element => {
	const userDetails = useUserDetailsContext();
	const [leaveEntries, setTimesheetEntries] = useState<Leave[]>([]);
	const [isLoading, setIsLoading] = useState<boolean[]>([]);
	const [startDate, setStartDate] = useState<Date>(
		startOfWeek(startOfToday()),
	);
	const [toSubmitNotes, setToSubmitNotes] = useState<Record<string, string>>(
		{},
	);

	const abortSignal = useAbortController();
	const isReviewedLeave = title === 'Reviewed';

	const columns: MUIDataTableColumnDef[] = [
		{
			name: 'employee',
			label: 'Name',
			options: {
				setCellHeaderProps: () => ({
					style: { width: '20%' },
				}),
				customBodyRender: (value: Leave['employee']) => value.name,
				sortCompare: nameCustomTableSort,
			},
		},
		{
			name: 'startDate',
			label: 'Leave Period',
			options: {
				setCellHeaderProps: () => ({
					style: { width: '10%' },
				}),
				customBodyRenderLite: (dataIndex): string => {
					const leave = leaveEntries[dataIndex];
					if (!leave) {
						return '';
					}
					const formattedStartDate = formatSlashedDate(
						leave.startDate.toDate(),
					);
					return leave.endDate
						? `${formattedStartDate} - ${formatSlashedDate(
								leave.endDate.toDate(),
						  )}`
						: `${formattedStartDate}`;
				},
			},
		},
		{
			name: 'hours',
			label: 'Duration',
			options: {
				setCellHeaderProps: () => ({
					style: { width: '7%' },
				}),
				filter: false,
				sortCompare: leaveDurationCustomTableSort(leaveEntries),
				customBodyRenderLite: (dataIndex): string => {
					const leave = leaveEntries[dataIndex];
					if (!leave) {
						return '';
					} else if (leave.hours) {
						return `${leave.hours} Hours`;
					} else if (leave.endDate) {
						const days = differenceInDays(
							leave.endDate?.toDate(),
							leave.startDate.toDate(),
						);
						return days === 0 ? '1 Day' : `${days + 1} Days`;
					} else {
						throw new Error(
							`Leave <${leave.id}> has no hours or end date`,
						);
					}
				},
			},
		},
		{
			name: 'leaveType',
			label: 'Type',
			options: {
				setCellHeaderProps: () => ({
					style: { width: '10%' },
				}),
				customBodyRender: (value: Leave['leaveType']) =>
					leaveLabels[value],
			},
		},
		{
			name: 'employee',
			label: 'Employee Note',
			options: {
				setCellHeaderProps: () => ({
					style: { width: '15%' },
				}),
				customBodyRenderLite: (dataIndex): string => {
					const leave = leaveEntries[dataIndex];

					if (!leave || !leaveNotes[leave.id]) return '';
					leaveNotes[leave.id];

					const leaveNote = leaveNotes[leave.id].find(
						(note) => note.user.id === leave.employee.id,
					);
					return leaveNote?.note ?? '';
				},
			},
		},
		...(isReviewedLeave
			? [
					{
						name: 'reviewer',
						label: 'Reviewed By',
						options: {
							customBodyRender: (value: Leave['reviewer']) =>
								value?.name,
						},
					},
					{
						name: 'note',
						label: 'Reviewer Note',
						options: {
							setCellHeaderProps: () => ({
								style: { width: '15%' },
							}),
							customBodyRenderLite: (
								dataIndex: number,
							): string => {
								const leave = leaveEntries[dataIndex];

								if (!leave || !leaveNotes[leave.id]) return '';

								const leaveNote = leaveNotes[leave.id].find(
									(note) =>
										leave.reviewer &&
										note.user.id === leave.reviewer.id,
								);
								return leaveNote?.note ?? '';
							},
						},
					},
					{
						name: 'reviewStatus',
						label: 'Review Status',
						options: {
							customBodyRender: (
								value: Leave['reviewStatus'],
							) => (
								<Box width="100%" textAlign="center">
									<ReviewStatusIcon reviewStatus={value} />
								</Box>
							),
						},
					},
			  ]
			: [
					{
						name: 'id',
						label: 'Reviewer Note',
						options: {
							setCellHeaderProps: () => ({
								style: { width: '25%' },
							}),
							customBodyRender: (
								leaveID: Leave['id'],
							): JSX.Element => {
								return (
									<TextField
										fullWidth
										multiline
										size="small"
										value={toSubmitNotes[leaveID] ?? ''}
										onChange={(event): void =>
											setToSubmitNotes((prev) => ({
												...prev,
												[leaveID]: event.target.value,
											}))
										}
									/>
								);
							},
						},
					},
					{
						name: 'Options',
						options: {
							filter: false,
							sort: false,
							customBodyRenderLite: (index: number) => (
								<Box
									display="flex"
									justifyContent="space-around">
									<Tooltip title="Deny Leave">
										<LoadingButton
											variant="outlined"
											fullWidth
											loading={isLoading[index]}
											onClick={async (): Promise<void> =>
												await handleLeaveUpdate(
													index,
													ReviewStatus.Disputed,
												)
											}
											style={{
												marginRight: 4,
												width: '102px',
											}}>
											Deny
										</LoadingButton>
									</Tooltip>
									<Tooltip title="Approve Leave">
										<LoadingButton
											variant="contained"
											loading={isLoading[index]}
											onClick={async (): Promise<void> =>
												await handleLeaveUpdate(
													index,
													ReviewStatus.Approved,
												)
											}
											style={{
												marginLeft: 4,
												width: '102px',
											}}>
											Approve
										</LoadingButton>
									</Tooltip>
								</Box>
							),
						},
					},
			  ]),
	];

	const tableOptions: MUIDataTableOptions = {
		elevation: 1,
		tableBodyHeight: 'calc(100vh - 310px)',
		viewColumns: false,
		selectableRowsOnClick: false,
		selectableRowsHideCheckboxes: true,
		selectToolbarPlacement: 'none',
		onDownload: onDownload(
			columns,
			title === 'Unreviewed' ? [5, 6] : undefined,
		),
	};

	useEffect(() => {
		changeStartDate(startDate);
	}, [startDate, changeStartDate]);

	useEffect(() => {
		setTimesheetEntries(allLeaveEntries);
	}, [allLeaveEntries]);

	useEffect(() => {
		setIsLoading(new Array(leaveEntries.length).fill(false));
	}, [leaveEntries.length]);

	const handleLeaveUpdate = async (
		index: number,
		reviewStatus: NonNullable<ReviewStatus>,
	): Promise<void> => {
		if (!userDetails) return;

		setIsLoading((prev) => {
			prev[index] = true;
			return [...prev]; // gotta change the array memory reference 🙃
		});

		const id = leaveEntries[index].id;
		const leaveNote = toSubmitNotes[id];

		if (leaveNote && user) {
			await cloudFunctionApi.createLeaveNote(
				abortSignal,
				user,
				id,
				leaveNote,
			);
		}
		await firebaseApi.reviewLeave(id, reviewStatus, userDetails);

		setIsLoading((prev) => {
			prev[index] = false;
			return [...prev];
		});
	};

	return isReviewedLeave ? (
		<DateDataTable
			title={title}
			tableData={allLeaveEntries}
			columns={columns}
			date={[startDate, setStartDate]}
			customTableOptions={tableOptions}
		/>
	) : (
		<DataTable
			title={title}
			tableData={allLeaveEntries}
			columns={columns}
			customTableOptions={tableOptions}
		/>
	);
};

/* eslint-disable @typescript-eslint/consistent-type-definitions */
import {
	ComponentsOverrides,
	createTheme,
	responsiveFontSizes,
} from '@mui/material/styles';
import type {} from '@mui/x-date-pickers/themeAugmentation';

declare module '@mui/material/styles' {
	interface Theme {
		status: {
			danger: string;
		};
	}
	// allow configuration using `createTheme`
	interface ThemeOptions {
		status?: {
			danger?: string;
		};
	}
	interface Palette {
		neutral: Palette['primary'];
		backgroundLight: Palette['background'];
		backgroundHeader: Palette['background'];
		border: Palette['primary'];
		textLandingPage: Palette['primary'];
		tertiary: Palette['background'];
	}
	// allow configuration using `createTheme`
	interface PaletteOptions {
		neutral?: PaletteOptions['primary'];
		backgroundLight?: PaletteOptions['background'];
		backgroundHeader?: PaletteOptions['background'];
		border?: PaletteOptions['primary'];
		textLandingPage?: PaletteOptions['primary'];
		tertiary?: PaletteOptions['background'];
	}

	interface TypographyVariants {
		h3Bold: React.CSSProperties;
		h5Bold: React.CSSProperties;
		h6Bold: React.CSSProperties;
		h7Bold: React.CSSProperties;
		body1Bold: React.CSSProperties;
		subtitle1Bold: React.CSSProperties;
	}

	// allow configuration using `createTheme`
	interface TypographyVariantsOptions {
		h3Bold?: React.CSSProperties;
		h5Bold?: React.CSSProperties;
		h6Bold?: React.CSSProperties;
		h7Bold: React.CSSProperties;
		body1Bold?: React.CSSProperties;
		subtitle1Bold?: React.CSSProperties;
	}

	interface Components {
		MUIDataTableHeadCell?: {
			styleOverrides?: ComponentsOverrides['MUIDataTableHeadCell'];
		};
	}
}

declare module '@mui/material/styles/overrides' {
	interface ComponentNameToClassKey {
		MUIDataTableHeadCell:
			| 'root'
			| 'contentWrapper'
			| 'data'
			| 'dragCursor'
			| 'fixedHeader'
			| 'hintIconAlone'
			| 'hintIconWithSortIcon'
			| 'mypopper'
			| 'sortAction'
			| 'sortActive'
			| 'sortLabelRoot'
			| 'toolButton'
			| 'tooltip';
	}
}

declare module '@mui/material/Button' {
	interface ButtonPropsColorOverrides {
		neutral: true;
	}
}

declare module '@mui/material/Chip' {
	interface ChipPropsColorOverrides {
		neutral: true;
	}
}
declare module '@mui/material/SvgIcon' {
	interface SvgIconPropsColorOverrides {
		neutral: true;
	}
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
	interface TypographyPropsVariantOverrides {
		h3Bold: true;
		h5Bold: true;
		h6Bold: true;
		h7Bold: true;
		body1Bold: true;
		subtitle1Bold: true;
	}
}

export const DefaultTheme = responsiveFontSizes(
	createTheme({
		palette: {
			primary: {
				main: '#e24f0b',
				contrastText: '#FFFFFF',
			},
			secondary: {
				main: '#0b9ee2',
				light: '#85CEF0',
			},
			tertiary: {
				default: '#1a3644',
			},
			error: {
				main: '#e20b32',
				contrastText: '#FFFFFF',
			},
			info: {
				main: '#90caf9',
				contrastText: '#FFFFFF',
			},
			warning: {
				main: '#e2ba0b',
			},
			success: {
				main: '#9ee20b',
				contrastText: '#FFFFFF',
			},
			neutral: {
				main: '#969696',
				light: '#F1F1F1',
				dark: '#404040',
				contrastText: '#FFFFFF',
			},
			background: {
				default: 'rgba(214, 89, 0, 0.05)',
			},
			backgroundLight: {
				default: '#FCF6F2',
			},
			backgroundHeader: {
				default: 'rgba(255,255,255)',
			},
			border: {
				main: '#E0E0E0',
				dark: '#CCC',
			},
			textLandingPage: {
				main: '#d65600',
			},
		},
		typography: {
			body1Bold: {
				fontWeight: 'bold',
				fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
				fontSize: '1rem',
				lineHeight: 1.5,
				letterSpacing: '0.00938em',
			},
			h3Bold: {
				fontWeight: 'bold',
				fontSize: 'xx-large',
			},
			h5Bold: {
				fontWeight: 'bold',
				fontSize: 'large',
			},
			h6Bold: {
				fontWeight: 'bold',
				fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
				fontSize: '1.25rem',
				lineHeight: 1.6,
				letterSpacing: '0.0075em',
				display: 'block',
			},
			h7Bold: {
				lineHeight: 1.5,
				display: 'block',
				fontWeight: 'bold',
				fontSize: 'small',
			},
			subtitle1Bold: {
				fontWeight: 'bold',
				fontSize: 'subtitle1',
			},
		},
		components: {
			MuiInputLabel: {
				styleOverrides: {
					root: {
						maxWidth: 'calc(100% - 48px)',
					},
				},
			},
			MuiAlert: {
				styleOverrides: {
					filledInfo: ({ ownerState, theme }) => ({
						...(ownerState.severity === 'info' && {
							color: theme.palette.info.contrastText,
							alignItems: 'center',
							'.MuiAlert-action': { paddingTop: 0 },
						}),
					}),
					standardError: ({ ownerState, theme }) => ({
						...(ownerState.severity === 'error' && {
							color: theme.palette.error.contrastText,
							alignItems: 'center',
							'.MuiAlert-action': { paddingTop: 0 },
						}),
					}),
				},
			},
		},
	}),
);

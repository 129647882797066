import ClearIcon from '@mui/icons-material/Clear';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import TodayIcon from '@mui/icons-material/Today';
import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogTitle,
	Grid,
	IconButton,
	Tooltip,
	Typography,
} from '@mui/material';
import { endOfWeek } from 'date-fns';
import { Dispatch, SetStateAction, useRef, useState } from 'react';
import {
	Activity,
	DayString,
	EntriesAddToDay,
	TimesheetActivity,
} from '../../constants/Common';
import {
	Timesheet,
	TimesheetPayrollStatus,
} from '../../constants/Timesheet/Timesheet';
import { TimesheetStatus } from '../../constants/Timesheet/TimesheetStatus';
import { roundHours } from '../../constants/Timesheet/TimesheetUtilities';
import DateWeekSelector from '../DateWeekSelector/DateWeekSelector';
import { formatSpacedDate } from '../helpers/dateFormatters';
import { PayrollStatusIcon } from './PayrollStatusIcon';
import {
	TimesheetError,
	TimesheetType,
	TotalError,
	WeekActivities,
} from './TimesheetDisplay';
import TimesheetDisplayContentActivities from './TimesheetDisplayContentActivities';

type TimesheetDisplayContentProps = {
	isEditing: boolean;

	// Timesheet info
	status: TimesheetStatus;
	payrollStatus: TimesheetPayrollStatus;
	name: string;
	reviewer?: string;
	reviewedAt?: Date;
	hoursBillable: number;
	type: TimesheetType;
	preApproval: Timesheet['preApproval'];
	//

	logoURL: string;
	handleWeekEndEditing: () => void;
	handleSaveEditing: () => void;
	handleCancelEditing: () => void;
	timesheetAction: JSX.Element;
	contractedTo: string;
	userCompany: string;
	week: Date;
	site: string;

	// Needed for Activities
	weekActivities: WeekActivities;
	updatedWeekActivities: WeekActivities;
	activityTypeMap: Record<string, TimesheetActivity>;
	errorMap: Partial<Record<string, TimesheetError>>;
	setErrorMap: Dispatch<
		SetStateAction<Partial<Record<string, TimesheetError>>>
	>;
	totalErrorMap?: Partial<Record<DayString, TotalError>>;
	toAddActivitiesList: Record<string, Activity>;
	toDeleteActivitiesList: string[];
	setToAddActivitiesList: Dispatch<SetStateAction<Record<string, Activity>>>;
	setUpdatedWeekActivities: Dispatch<SetStateAction<WeekActivities>>;
	setToDeleteActivitiesList: Dispatch<SetStateAction<string[]>>;
	selectedSite: string | null;
	entriesAddToDay: EntriesAddToDay;

	// Needed for Dialogs
	setNewTimesheetWeek: (date: Date) => void;
	setDeleteTimesheet: (toDelete: boolean) => void;
	newTimesheetWeek: Date;

	// A new prop just so we can alter the layout for a single usage? Oh boy!
	compactHeader?: boolean;
};

/**
 * Little heavy handed here but this component needs the rework and the old timesheets display is at the heart
 * @deprecated This is the old timesheets display view and should not be used
 */
export default function TimesheetDisplayContent({
	timesheetAction,
	isEditing,
	status,
	payrollStatus,
	name,
	reviewer,
	reviewedAt,
	hoursBillable,
	type,
	preApproval,
	logoURL,
	handleWeekEndEditing: handleStartEditing,
	handleSaveEditing,
	handleCancelEditing,
	contractedTo,
	userCompany,
	week,
	site,
	weekActivities,
	updatedWeekActivities,
	activityTypeMap,
	errorMap,
	setErrorMap,
	totalErrorMap,
	setNewTimesheetWeek,
	setDeleteTimesheet,
	newTimesheetWeek,
	toAddActivitiesList,
	toDeleteActivitiesList,
	setToAddActivitiesList,
	setUpdatedWeekActivities,
	setToDeleteActivitiesList,
	selectedSite,
	entriesAddToDay,
	compactHeader,
}: TimesheetDisplayContentProps): JSX.Element {
	const pdfExportRef = useRef(null);

	const [weekDialogOpen, setWeekDialogOpen] = useState(false);
	const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

	const handleTimesheetWeekChange = (date: Date | null): void => {
		setWeekDialogOpen(false);
		setNewTimesheetWeek((date && endOfWeek(date)) ?? endOfWeek(new Date()));
	};

	const handleDeleteTimesheet = (): void => {
		setDeleteDialogOpen(false);
		setDeleteTimesheet(true);
	};

	const timesheetOptionsHeader = (
		type: string,
		isEditing: boolean,
		setDialogOpen: (open: boolean) => void,
		setDeleteDialogOpen: (open: boolean) => void,
		handleSaveEditing: () => void,
		handleCancelEditing: () => void,
		handleStartEditing: () => void,
	): JSX.Element => (
		<Grid
			xs={12}
			item
			justifyContent="right"
			container
			borderBottom={compactHeader ? 0 : 1}
			marginBottom={1}
			borderColor="divider">
			<Grid item container alignItems="center" flex="1">
				{!compactHeader && timesheetAction}
			</Grid>
			<Grid item container justifyContent="flex-end" flex="2">
				{((type === 'review' && status === TimesheetStatus.Submitted) ||
					(type === 'current' &&
						status === TimesheetStatus.Active)) &&
					(isEditing ? (
						<>
							{!compactHeader &&
								editTimesheetButtons(
									setDialogOpen,
									setDeleteDialogOpen,
									handleSaveEditing,
								)}
							<Tooltip title="Clear Changes">
								<IconButton
									size="large"
									color="primary"
									onClick={handleCancelEditing}>
									<ClearIcon fontSize="large" />
								</IconButton>
							</Tooltip>
						</>
					) : (
						<Tooltip title="Edit Activities">
							<IconButton
								size="large"
								color="primary"
								onClick={handleStartEditing}>
								<EditIcon fontSize="large" />
							</IconButton>
						</Tooltip>
					))}

				<PayrollStatusIcon status={payrollStatus} />
			</Grid>
			{compactHeader && (
				<Grid item container justifyContent="flex-end" xs={10}>
					{((type === 'review' &&
						status === TimesheetStatus.Submitted) ||
						(type === 'current' &&
							status === TimesheetStatus.Active)) &&
						isEditing &&
						editTimesheetButtons(
							setDialogOpen,
							setDeleteDialogOpen,
							handleSaveEditing,
						)}
				</Grid>
			)}
		</Grid>
	);

	const editTimesheetButtons = (
		setDialogOpen: (open: boolean) => void,
		setDeleteDialogOpen: (open: boolean) => void,
		handleSaveEditing: () => void,
	): JSX.Element => (
		<>
			<Tooltip title="Change Week">
				<IconButton
					size="large"
					color="primary"
					onClick={(): void => setDialogOpen(true)}>
					<TodayIcon fontSize="large" />
				</IconButton>
			</Tooltip>
			<Tooltip title="Delete Timesheet">
				<IconButton
					size="large"
					color="primary"
					onClick={(): void => setDeleteDialogOpen(true)}>
					<DeleteIcon fontSize="large" />
				</IconButton>
			</Tooltip>
			<Tooltip title="Save Changes">
				<IconButton
					size="large"
					color="primary"
					onClick={handleSaveEditing}>
					<SaveIcon fontSize="large" />
				</IconButton>
			</Tooltip>
		</>
	);

	const timesheetDisplayHeader = (
		logoURL: string,
		name: string,
		company: string,
		week: Date,
		site: string,
		isPDF: boolean,
	): JSX.Element => (
		<Grid item container xs={12} marginBottom={1}>
			<Grid item container xs={9} justifyContent="space-between">
				<Grid item xs={12}>
					<Typography variant="h6" noWrap>
						{name}
					</Typography>
				</Grid>
				<Grid item border="1px soild red" xs={12}>
					<Typography variant="h6">{company}</Typography>
				</Grid>
				<Grid item id="week" xs={12}>
					<Typography variant="subtitle2">
						{`WE ${formatSpacedDate(week)}`}
					</Typography>
				</Grid>
				<Grid item id="site" xs={12}>
					<Typography variant="subtitle2">{site}</Typography>
				</Grid>
			</Grid>
			<Grid item xs={3} textAlign="right">
				{!isPDF &&
					compactHeader &&
					timesheetOptionsHeader(
						type,
						isEditing,
						setWeekDialogOpen,
						setDeleteDialogOpen,
						handleSaveEditing,
						handleCancelEditing,
						handleStartEditing,
					)}
				<Box
					component="img"
					src={logoURL}
					alt="Company Logo"
					sx={{
						height: 'auto',
						width: '100%',
					}}
				/>
			</Grid>
		</Grid>
	);

	const renderDeleteDialog = (): JSX.Element => (
		<Dialog
			open={deleteDialogOpen}
			onClose={(): void => setDeleteDialogOpen(false)}>
			<DialogTitle
				sx={{
					display: 'flex',
					justifyContent: 'space-between',
				}}>
				Delete Timesheet?
			</DialogTitle>
			<DialogActions>
				<Button
					fullWidth
					variant="outlined"
					onClick={(): void => setDeleteDialogOpen(false)}>
					Cancel
				</Button>
				<Button
					fullWidth
					variant="contained"
					color="primary"
					onClick={handleDeleteTimesheet}>
					Confirm
				</Button>
			</DialogActions>
		</Dialog>
	);

	const renderWeekDialog = (): JSX.Element => (
		<Dialog
			open={weekDialogOpen}
			onClose={(): void => setWeekDialogOpen(false)}>
			<DialogTitle
				sx={{
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center',
				}}>
				Timesheet Week
				<IconButton
					size="small"
					color="primary"
					onClick={(): void => setWeekDialogOpen(false)}>
					<ClearIcon fontSize="medium" />
				</IconButton>
			</DialogTitle>
			<DialogActions>
				<DateWeekSelector
					date={endOfWeek(newTimesheetWeek)}
					onChange={handleTimesheetWeekChange}
					allowFuture
					weekEnding
				/>
			</DialogActions>
		</Dialog>
	);

	const renderContent = (isPDF: boolean): JSX.Element => (
		<Box>
			<Grid container justifyContent="space-between">
				{!isPDF &&
					!compactHeader &&
					timesheetOptionsHeader(
						type,
						isEditing,
						setWeekDialogOpen,
						setDeleteDialogOpen,
						handleSaveEditing,
						handleCancelEditing,
						handleStartEditing,
					)}
				{timesheetDisplayHeader(
					logoURL,
					name,
					contractedTo,
					week,
					site,
					isPDF,
				)}
				<Grid item xs={12}>
					<Typography variant="subtitle2">
						Status - {status}
					</Typography>
				</Grid>
				<Grid item xs={12}>
					<Typography variant="subtitle2">
						Employer - {userCompany}
					</Typography>
				</Grid>
				{preApproval && (
					<Grid item xs={12}>
						<Typography variant="subtitle2">
							{`${preApproval.reviewer.name} Pre Approved ${preApproval.hours.total.billable} hours`}
						</Typography>
					</Grid>
				)}
				{reviewer && (
					<Grid item xs={12}>
						<Typography variant="subtitle2">
							{`Reviewed By - ${reviewer}`}
						</Typography>
					</Grid>
				)}
				{reviewedAt && (
					<Grid item xs={12}>
						<Typography variant="subtitle2">
							{`Reviewed At - ${reviewedAt.toLocaleDateString()}`}
						</Typography>
					</Grid>
				)}
				<Grid sx={{ mt: 1 }} item xs={12}>
					<TimesheetDisplayContentActivities
						isPDF={isPDF}
						weekActivities={weekActivities}
						isEditing={isEditing}
						totalErrorMap={totalErrorMap}
						updatedWeekActivities={updatedWeekActivities}
						errorMap={errorMap}
						setErrorMap={setErrorMap}
						activityTypeMap={activityTypeMap}
						toAddActivitiesList={toAddActivitiesList}
						toDeleteActivitiesList={toDeleteActivitiesList}
						setToAddActivitiesList={setToAddActivitiesList}
						setUpdatedWeekActivities={setUpdatedWeekActivities}
						setToDeleteActivitiesList={setToDeleteActivitiesList}
						selectedSite={selectedSite}
						entriesAddToDay={entriesAddToDay}
						modifyActivitiesDisabled={false}
					/>
				</Grid>
				<Grid
					item
					xs={12}
					container
					justifyContent="space-between"
					sx={{ mt: 3 }}>
					<Typography variant="h6">Hours Worked</Typography>
					<Typography variant="h6">
						{roundHours(hoursBillable) ?? 'No data'}
					</Typography>
				</Grid>
				<Grid
					item
					xs={12}
					container
					justifyContent="space-between"
					sx={{ mt: 3 }}>
					<Typography variant="h6">Total Hours</Typography>
					<Typography variant="h6">
						{roundHours(hoursBillable) ?? 'No data'}
					</Typography>
				</Grid>
			</Grid>
		</Box>
	);

	return (
		<>
			{renderContent(false)}
			{renderDeleteDialog()}
			{renderWeekDialog()}
			<Box
				sx={{
					position: 'absolute',
					left: '-6500px',
					top: 0,
				}}>
				{/* PDF Content */}
				<Box ref={pdfExportRef}>{renderContent(true)}</Box>
			</Box>
		</>
	);
}

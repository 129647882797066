import { Typography } from '@mui/material';
import { getTimeForHours } from '../../../../../constants/stringUtilities';
import { TimesheetSiteLog } from '../../../../../constants/Timesheet/Timesheet';
import { SiteLogPicturesTooltip } from '../../SiteLogPicturesTooltip';

export type SiteLogCellProps = {
	siteLog: {
		In: TimesheetSiteLog | null;
		Out: TimesheetSiteLog | null;
		formattedLogs: string | null;
	};
};

export const SiteLogCell = ({ siteLog }: SiteLogCellProps): JSX.Element => {
	return siteLog.formattedLogs ? (
		<SiteLogPicturesTooltip
			signInURL={siteLog.In?.url ?? ''}
			signOutURL={siteLog.Out?.url ?? ''}>
			<Typography
				sx={{
					fontSize: 'small',
					textWrap: 'pretty',
					textDecoration: 'underline dotted',
					textAlign: { xs: 'right', md: 'center' },
				}}>
				{siteLog.formattedLogs}
			</Typography>
		</SiteLogPicturesTooltip>
	) : (
		<Typography
			sx={{
				fontSize: 'small',
				textWrap: 'pretty',
				textAlign: { xs: 'right', md: 'center' },
			}}>
			{getTimeForHours(0)}
		</Typography>
	);
};

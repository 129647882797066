import { LoadingButton } from '@mui/lab';
import {
	Autocomplete,
	AutocompleteRenderInputParams,
	Box,
	Divider,
	Stack,
	TextField,
	Typography,
} from '@mui/material';
import { ChangeEvent, memo, useEffect, useMemo, useState } from 'react';
import { FixMeLater } from '../../../../constants/AnyTypes';
import {
	SafetyCourseType,
	SafetyCourseValidationResponse,
	SafetyCourseValidationStatus,
	safetyCourseTypeNames,
	safetyCourseTypes,
} from '../../../../constants/SafetyCourse';
import { getSafetyCourseStatusWithPending } from '../../../../constants/SafetyCourseUtilities';
import { safeCourseIDNumberFormat } from '../../../helpers/numberFormats';
import { LoadingDots } from '../../../Management/subcomponents/LoadingDots';
import { SafetyCourseDuplicateAlert } from '../SafetyCourseDuplicateAlert/SafetyCourseDuplicateAlert';
import { SafetyCourseValidationInfo } from '../SafetyCourseStatusInfo/SafetyCourseStatusInfo';

export type SafetyCourseValidationCardProps = {
	validateSafetyCourse: (
		courseType: SafetyCourseType,
		courseID: string,
	) => Promise<void>;
	safetyCourse?: SafetyCourseValidationResponse | null;
	duplicateAlert?: string;
	showHeader?: boolean;
};

const style = {
	idTextField: {
		'& .MuiOutlinedInput-root': {
			'& fieldset': {
				borderTopRightRadius: 0,
				borderBottomRightRadius: 0,
			},
		},
	},
	loadingButton: {
		borderTopLeftRadius: 0,
		borderBottomLeftRadius: 0,
	},
};

type SafetyCourseData = Omit<SafetyCourseValidationResponse, 'type'> & {
	id: SafetyCourseValidationResponse['id'];
	type: SafetyCourseValidationResponse['type'] | null;
	validationID: SafetyCourseValidationResponse['id'];
	name: SafetyCourseValidationResponse['name'];
	expiryDate: SafetyCourseValidationResponse['expiryDate'];
	status: SafetyCourseValidationStatus;
};

const SafetyCourseValidationCardImpl = ({
	validateSafetyCourse,
	safetyCourse,
	duplicateAlert,
	showHeader = true,
}: SafetyCourseValidationCardProps): JSX.Element => {
	const [safetyCourseID, setSafetyCourseID] = useState<string>(
		safetyCourse?.id || '',
	);
	const [safetyCourseType, setSafetyCourseType] =
		useState<SafetyCourseType | null>(safetyCourse?.type || null);
	const [loading, setLoading] = useState(false);

	const safetyCourseData: SafetyCourseData = {
		id: safetyCourseID,
		type: safetyCourseType,
		validationID: safetyCourse?.id || '',
		name: safetyCourse?.name || '',
		expiryDate: safetyCourse ? safetyCourse.expiryDate : null,
		status: getSafetyCourseStatusWithPending(safetyCourse),
	};

	useEffect(() => {
		if (duplicateAlert) {
			setSafetyCourseID('');
			setSafetyCourseType(null);
		}
	}, [duplicateAlert]);

	const verifyCourse = async (): Promise<void> => {
		if (!safetyCourseData.type || !safetyCourseData.id) return;
		setLoading(true);
		await validateSafetyCourse(
			safetyCourseData.type,
			safetyCourseData.id.trim(),
		);
		setLoading(false);
	};

	const handleCourseIdChange = (
		event: ChangeEvent<HTMLInputElement>,
	): void => {
		setSafetyCourseID(event.target.value);
	};

	const typeAutocomplete = useMemo(() => {
		const handleOnChange = (
			_: React.SyntheticEvent,
			value: SafetyCourseType | null,
		): void => {
			if (value) {
				setSafetyCourseType(value);
			}
		};

		const renderOptionLabel = (option: SafetyCourseType): string => {
			const overrideNames = {
				...safetyCourseTypeNames,
				SiteSafe: 'Site Safe - Currently Unavailable',
			};
			return overrideNames[option];
		};

		const isOptionEqual = (
			option: SafetyCourseType,
			value: SafetyCourseType,
		): boolean => option === value;

		const renderInput = (
			params: AutocompleteRenderInputParams,
		): JSX.Element => (
			<TextField {...params} label="Safety Course Type" error={false} />
		);

		return (
			<Autocomplete
				fullWidth
				options={safetyCourseTypes}
				getOptionLabel={renderOptionLabel}
				getOptionDisabled={(option): boolean =>
					option === SafetyCourseType.SiteSafe
				}
				value={safetyCourseData.type}
				onChange={handleOnChange}
				isOptionEqualToValue={isOptionEqual}
				renderInput={renderInput}
				size="small"
			/>
		);
	}, [safetyCourseData.type]);

	return (
		<Box>
			<Stack spacing={2} divider={<Divider />}>
				<Stack spacing={2}>
					{showHeader && (
						<Typography variant="subtitle1">
							Safety Course Details
						</Typography>
					)}
					{duplicateAlert && (
						<SafetyCourseDuplicateAlert
							alertMessage={duplicateAlert}
						/>
					)}
					{typeAutocomplete}
					<Box display="flex">
						<TextField
							label="Safety Course ID"
							variant="outlined"
							value={safetyCourseData.id}
							onChange={handleCourseIdChange}
							InputProps={{
								inputComponent:
									safeCourseIDNumberFormat as FixMeLater,
								inputProps: {
									format:
										safetyCourseData.type ===
										SafetyCourseType.ConstructSafe
											? '###-####-###'
											: undefined,
								},
							}}
							fullWidth
							size="small"
							sx={style.idTextField}
						/>
						<LoadingButton
							variant="outlined"
							onClick={verifyCourse}
							disableElevation
							disabled={
								safetyCourseData.id === '' ||
								safetyCourseData.type === null ||
								safetyCourseData.type ===
									SafetyCourseType.SiteSafe
							}
							loading={loading}
							sx={style.loadingButton}>
							Verify
						</LoadingButton>
					</Box>
				</Stack>
				<Box display="flex" alignItems="center">
					{loading ? (
						<LoadingDots />
					) : (
						<SafetyCourseValidationInfo
							status={safetyCourseData.status}
							safetyCourseID={safetyCourseData.validationID}
							courseName={safetyCourseData.name}
							courseExpiry={safetyCourseData.expiryDate}
						/>
					)}
				</Box>
			</Stack>
		</Box>
	);
};

export const SafetyCourseValidationCard = memo(SafetyCourseValidationCardImpl);

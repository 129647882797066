import { Box } from '@mui/material';
import { MUIDataTableColumnDef, MUIDataTableOptions } from 'mui-datatables';
import {
	ReactElement,
	useState,
	useEffect,
	Dispatch,
	SetStateAction,
} from 'react';
import { UserProps, Site, Activity } from '../../../constants/Common';
import { FirebaseApi } from '../../../firebase/firebaseApi';
import { DataTable } from '../../DataTable/DataTable';
import DateRangeSelector from '../../DateRangeSelector/DateRangeSelector';
import { formatSlashedDate } from '../../helpers/dateFormatters';
import { LoadingDots } from '../../Management/subcomponents/LoadingDots';
import { SiteSelectDropdown } from '../../SiteSelectDropdown/SiteSelectDropdown';
import { ReportConfigureHeader } from '../ReportConfigureHeader';
import { ReportsStyleWrapper } from '../ReportsStyleWrapper';
import { ReportsTableTheme } from '../ReportsTableTheme';
import {
	FetchButtonState,
	ProjectTrackingReportFirebaseCalls,
} from '../StateManagement/models';

type SimplifiedActivity = {
	activityName: string;
	workerName: string;
	hours: number;
	employerName: string;
};

type ActivityWorkingCache = {
	[timesheetId: string]: {
		[activityId: string]: SimplifiedActivity;
	};
};

export type ProjectTrackingReportProps = UserProps & {
	startDateContext: { date: Date; setDate: Dispatch<SetStateAction<Date>> };
	endDateContext: { date: Date; setDate: Dispatch<SetStateAction<Date>> };
	firebaseApi: Pick<FirebaseApi, ProjectTrackingReportFirebaseCalls>;
};

export const ProjectTrackingReport = ({
	startDateContext,
	endDateContext,
	userDetails,
	firebaseApi,
}: ProjectTrackingReportProps): ReactElement => {
	const [tempStartDate, setTempStartDate] = useState<Date>(
		startDateContext.date,
	);
	const [fetchButtonText, setFetchButtonText] =
		useState<FetchButtonState>('Update');
	const [tempEndDate, setTempEndDate] = useState<Date>(endDateContext.date);
	const [tableHeader, setTableHeader] = useState('Project Tracking Report');
	const [activities, setActivities] = useState<SimplifiedActivity[]>([]);
	const [site, setSite] = useState<string>(userDetails.siteID);
	const [sites, setSites] = useState<Record<string, Site>>({});
	const [initialLoad, setInitialLoad] = useState(true);
	const [shouldUpdate, setShouldUpdate] = useState(false);
	const [loading, setLoading] = useState(false);
	const canSelectSite = userDetails.accountType === 'seniorManagement';
	const noMatchTableText = initialLoad ? (
		'Configure report information above and press update'
	) : loading ? (
		<LoadingDots />
	) : (
		'Sorry, no matching activity logs found'
	);

	useEffect(() => {
		return firebaseApi.sitesByCompanySubscription(
			userDetails.companyID,
			(sites) => setSites(sites),
		);
	}, [userDetails.companyID, firebaseApi]);

	useEffect(() => {
		setTempStartDate(startDateContext.date);
	}, [startDateContext.date]);

	useEffect(() => {
		setTempEndDate(endDateContext.date);
	}, [endDateContext.date]);

	useEffect(() => {
		const getActivities = async (): Promise<void> => {
			setLoading(true);
			const rawActivities =
				await firebaseApi.getActivitiesBySiteDateRange(
					site,
					startDateContext.date,
					endDateContext.date,
				);
			/** Simplify Activity shape and add up hours for the same person doing the same activity across the period.
			 * eg. 9 hours labour on Mon + 9 hours labour on Tue = 18 hours labour */
			const workingActivities = rawActivities.reduce(
				(accumulator: ActivityWorkingCache, activity: Activity) => {
					if (!accumulator[activity.timesheetID]) {
						// Create empty object if we haven't encountered the timesheet before
						accumulator[activity.timesheetID] = {};
					}
					if (
						!accumulator[activity.timesheetID][activity.activity.id]
					) {
						// Add initial details for this activity
						accumulator[activity.timesheetID][
							activity.activity.id
						] = {
							activityName: activity.activity.name,
							workerName: activity.workerName,
							hours: activity.hours,
							employerName: activity.employerName,
						};
					} else {
						// Add additional hours for this period to the timesheet
						accumulator[activity.timesheetID][
							activity.activity.id
						].hours += activity.hours;
					}
					return accumulator;
				},
				{},
			);
			setActivities(
				Object.values(workingActivities).flatMap(Object.values),
			);
			setLoading(false);
			setTableHeader(
				`Project Tracking Report for ${
					sites[site].name
				} from ${formatSlashedDate(
					startDateContext.date,
				)} - ${formatSlashedDate(endDateContext.date)}`,
			);
			setShouldUpdate(false);
		};
		if (shouldUpdate) {
			setActivities([]);
			getActivities();
		}
	}, [
		shouldUpdate,
		firebaseApi,
		startDateContext.date,
		endDateContext.date,
		sites,
		site,
		initialLoad,
	]);

	const cols: MUIDataTableColumnDef[] = [
		{
			label: 'Activity',
			name: 'activityName',
		},
		{
			label: 'Employer',
			name: 'employerName',
		},
		{
			label: 'Name',
			name: 'workerName',
		},
		{
			label: 'Hours',
			name: 'hours',
			options: {
				filter: false,
			},
		},
	];

	const customTableOptions: Partial<MUIDataTableOptions> = {
		filter: true,
		tableBodyHeight: 'calc(100vh - 424px)',
		isRowSelectable: () => false,
		textLabels: {
			body: {
				noMatch: noMatchTableText,
			},
		},
		downloadOptions: {
			filename: tableHeader
				.replace(new RegExp('/', 'g'), '-')
				.replace(/\s/g, '_'),
		},
	};

	const handleUpdateReport = (): void => {
		if (fetchButtonText === 'Refresh') {
			setShouldUpdate(true);
		} else {
			if (initialLoad) setInitialLoad(false);
			setFetchButtonText('Refresh');
			startDateContext.setDate(tempStartDate);
			endDateContext.setDate(tempEndDate);
			setShouldUpdate(true);
		}
	};

	const selectSite = (
		<SiteSelectDropdown
			onChange={(_, value): void => {
				if (value) {
					setSite(value.id);
				}
			}}
			inputLabel="Select Site (required)"
			disableClearable
			required={true}
			value={sites[site] ?? null}
			sites={sites}
			size="small"
			error={false}
		/>
	);

	const renderDateSelector: JSX.Element = (
		<DateRangeSelector
			startDateUseState={[tempStartDate, setTempStartDate]}
			endDateUseState={[tempEndDate, setTempEndDate]}
			textFieldProps={{
				size: 'small',
				fullWidth: true,
			}}
			maxDateRangeDays={365}
		/>
	);

	const renderReportHeader: JSX.Element = (
		<ReportConfigureHeader
			loading={loading}
			updateOnClick={handleUpdateReport}
			updateButtonText={fetchButtonText}
		/>
	);

	return (
		<Box flex="1">
			<ReportsStyleWrapper
				title={renderReportHeader}
				componentList={[
					{
						gridSize: canSelectSite ? 5 : 12,
						component: renderDateSelector,
						id: 'dateSelector',
					},
					...(canSelectSite
						? [
								{
									gridSize: 7,
									component: selectSite,
									id: 'statusAutoComplete',
								},
						  ]
						: []),
				]}
			/>
			<ReportsTableTheme>
				<DataTable
					title={tableHeader}
					tableData={activities}
					customTableOptions={customTableOptions}
					columns={cols}
				/>
			</ReportsTableTheme>
		</Box>
	);
};

import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import { LoadingButton } from '@mui/lab';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid,
	MenuItem,
	Stack,
	Switch,
	TextField,
	Tooltip,
	Typography,
} from '@mui/material';
import { useMemo, useReducer } from 'react';
import { AccountsAccountType } from '../../../constants/Accounts';
import {
	AccountType,
	AccountTypeHumanName,
	Company,
	CompanyTypes,
	Site,
	UserDetails,
} from '../../../constants/Common';
import { FirebaseApi } from '../../../firebase/firebaseApi';
import {
	CompanySelectGroup,
	SimpleCompanyAutocomplete,
} from '../../Autocomplete/SimpleCompanyAutocomplete';
import { SitesAutocomplete } from '../../Autocomplete/SitesAutocomplete';
import {
	toggleDisableTimesheetApproval,
	toggleDisabledLeave,
	updateAccountType,
	updateContractedTo,
	updateLoading,
	updateSite,
} from './actions';
import {
	createInitialUserOptionsState,
	getUpdates,
	userOptionsDialogReducer,
} from './reducer';

export type UsersOptionsDialogProps = {
	accountsUser: {
		isCurrentSelected: boolean;
		accountType: AccountsAccountType;
		companyID: string;
	};
	selectedUser: UserDetails;
	companies: Record<string, Company>;
	sites: Record<string, Site>;
	modalOpen: boolean;
	handleCloseDialog: () => void;
	firebaseApi: Pick<FirebaseApi, 'updateUserAccountDetails'>;
};

export const UsersOptionsDialog = ({
	accountsUser,
	selectedUser,
	companies,
	sites,
	modalOpen,
	handleCloseDialog,
	firebaseApi,
}: UsersOptionsDialogProps): JSX.Element => {
	const [state, dispatch] = useReducer(
		userOptionsDialogReducer,
		{
			user: selectedUser,
			companies,
			sites,
			accountsUserAccountType: accountsUser.accountType,
		},
		createInitialUserOptionsState,
	);

	const companyOptions = useMemo(() => {
		return state.authorizedActions.isRecruitmentSite &&
			state.user.contractedTo?.id
			? {
					[state.user.contractedTo.id]: {
						id: state.user.siteCompanyID,
						name: state.user.siteCompany,
						companyType: CompanyTypes.recruitment,
					},
			  }
			: companies;
	}, [
		companies,
		state.authorizedActions.isRecruitmentSite,
		state.user.contractedTo?.id,
		state.user.siteCompany,
		state.user.siteCompanyID,
	]);

	// Limited to construction companies so user company will be in the list
	const hasMultiStageApproval =
		!!companyOptions[accountsUser.companyID]?.multiStageApproval;

	const handleSave = async (): Promise<void> => {
		const fieldsToUpdate = getUpdates(state.initialUser, state.user);
		if (Object.keys(fieldsToUpdate).length > 0) {
			dispatch(updateLoading(true));
			await firebaseApi.updateUserAccountDetails(
				selectedUser.userID,
				fieldsToUpdate,
			);
			dispatch(updateLoading(false));
		}
		handleCloseDialog();
	};

	return (
		<Dialog open={modalOpen} onClose={handleCloseDialog} fullWidth>
			<DialogTitle>Edit {selectedUser.displayName} Details</DialogTitle>
			<DialogContent>
				<Grid container alignItems="center" spacing={2} p={1}>
					{state.authorizedActions.canChangeContractedTo && (
						<Grid item xs={12}>
							<SimpleCompanyAutocomplete
								label="Contracted To"
								value={state.user.contractedTo?.id ?? null}
								companies={companyOptions}
								onChange={(contractedTo): void =>
									dispatch(updateContractedTo(contractedTo))
								}
								groupBy={{
									getGroup: (
										option: Company,
									): CompanySelectGroup =>
										selectedUser.recentClients.some(
											(client) => client.id === option.id,
										)
											? CompanySelectGroup.RecentClients
											: CompanySelectGroup.Clients,
									order: [
										CompanySelectGroup.RecentClients,
										CompanySelectGroup.Clients,
									],
								}}
								disabled={
									state.authorizedActions.isRecruitmentSite ||
									state.loading
								}
								size="medium"
							/>
						</Grid>
					)}
					<Grid item xs={12}>
						<SitesAutocomplete
							label="Site"
							value={
								state.user.siteID === ''
									? null
									: state.user.siteID
							}
							sites={sites}
							onChange={(value): void =>
								dispatch(updateSite(sites[value] ?? null))
							}
							disabled={state.loading}
						/>
					</Grid>
					<Grid item xs={12}>
						<TextField
							select
							fullWidth
							label="Account Type"
							value={state.user.accountType}
							disabled={
								accountsUser.isCurrentSelected || state.loading
							}
							onChange={(event): void =>
								dispatch(
									updateAccountType(
										event.target.value as AccountType,
									),
								)
							}>
							{state.selectableAccountTypes.map((option) => (
								<MenuItem key={option} value={option}>
									{AccountTypeHumanName[option]}
								</MenuItem>
							))}
						</TextField>
					</Grid>
					<Grid item xs={8}>
						<Typography>Leave Requests</Typography>
					</Grid>
					<Grid item display="flex" justifyContent="flex-end" xs={4}>
						<Switch
							checked={!state.user.disabledLeave}
							onClick={(): void =>
								dispatch(
									toggleDisabledLeave(
										!state.user.disabledLeave,
									),
								)
							}
							disabled={state.loading}
						/>
					</Grid>
					{hasMultiStageApproval &&
						state.authorizedActions.canChangeApproveTimesheets && (
							<>
								<Grid item xs={8}>
									<Tooltip title="Disabling limits Manager to Pre Approve Timesheets">
										<Stack direction="row" spacing={0.5}>
											<Typography>
												Timesheet Approval
											</Typography>
											<HelpOutlineOutlinedIcon
												color="neutral"
												sx={{ width: '1rem' }}
											/>
										</Stack>
									</Tooltip>
								</Grid>
								<Grid
									item
									display="flex"
									justifyContent="flex-end"
									xs={4}>
									<Switch
										checked={
											!state.user.disableTimesheetApproval
										}
										onClick={(): void =>
											dispatch(
												toggleDisableTimesheetApproval(
													!state.user
														.disableTimesheetApproval,
												),
											)
										}
										disabled={state.loading}
									/>
								</Grid>
							</>
						)}
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button
					onClick={handleCloseDialog}
					variant="outlined"
					disabled={state.loading}>
					Cancel
				</Button>
				<LoadingButton
					onClick={handleSave}
					variant="contained"
					loading={state.loading}>
					Save
				</LoadingButton>
			</DialogActions>
		</Dialog>
	);
};

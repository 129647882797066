import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import {
	Button,
	Stack,
	Typography,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import { accountTypes, UserDetails } from '../../../../constants/Common';
import { Timesheet } from '../../../../constants/Timesheet/Timesheet';
import { TimesheetStatus } from '../../../../constants/Timesheet/TimesheetStatus';
import { validTimesheetDateRange } from '../../../../constants/Timesheet/TimesheetUtilities';
import { HydratedTimesheetAction } from '../../timesheetActions';

const directions = { back: 'back', next: 'next' } as const;
type Direction = keyof typeof directions;

export type TimesheetActionsBarProps = {
	timesheet: Timesheet | null;
	timesheets: Timesheet[];
	user: UserDetails;
	setTimesheet: (timesheet: Timesheet) => void;
	action: HydratedTimesheetAction | null;
	handlePDFClicked: () => void;
	handleEditClicked: () => void;
};

export const TimesheetActionsBar = ({
	user,
	timesheet,
	timesheets,
	setTimesheet,
	action,
	handleEditClicked,
	handlePDFClicked,
}: TimesheetActionsBarProps): JSX.Element => {
	const theme = useTheme();
	const isXSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
	const needsPreApproveFormatting =
		action?.buttonText === 'Pre Approve' && !timesheet?.preApproval;
	// Keep buttons a constant width, just wider than the longest text
	const buttonStyle = {
		width: needsPreApproveFormatting ? '152px' : '104px',
	};

	const today = new Date();
	const dateRange = validTimesheetDateRange(today);
	const showEditButton =
		timesheet?.timesheetStatus === TimesheetStatus.Submitted &&
		!(
			timesheet.preApproval &&
			(user.disableTimesheetApproval ||
				user.accountType === accountTypes.handler)
		) &&
		today > dateRange.minDate &&
		today < dateRange.maxDate;

	const showActionButtons = showEditButton && action && !action?.disabled;

	const cycleTimesheets = (direction: Direction): void => {
		const currentIndex = timesheets.findIndex(
			(i) => i.id === timesheet?.id,
		);

		// lets the buttons cycle the list
		let timesheetIndex: number;
		if (direction === directions.next) {
			if (currentIndex + 1 >= timesheets.length) {
				timesheetIndex = 0;
			} else {
				timesheetIndex = currentIndex + 1;
			}
		} else {
			if (currentIndex - 1 < 0) {
				timesheetIndex = timesheets.length - 1;
			} else {
				timesheetIndex = currentIndex - 1;
			}
		}

		const timewithID = timesheets.at(timesheetIndex);
		if (timewithID) setTimesheet(timewithID);
	};

	const backButton = (
		<Button
			color="primary"
			title="Back"
			onClick={(): void => cycleTimesheets(directions.back)}
			disabled={timesheets.length <= 1}
			startIcon={<NavigateBeforeIcon />}>
			Back
		</Button>
	);

	const nextButton = (
		<Button
			color="primary"
			title="Next"
			onClick={(): void => cycleTimesheets(directions.next)}
			disabled={
				timesheets.length < 1 ||
				(timesheets.length === 1 && timesheet !== null)
			}
			endIcon={<NavigateNextIcon />}>
			Next
		</Button>
	);

	const editButton = showEditButton && (
		<Button
			color="primary"
			variant="outlined"
			onClick={handleEditClicked}
			sx={buttonStyle}>
			Edit
		</Button>
	);

	const pdfButton = timesheet && (
		<Button
			color="primary"
			variant="outlined"
			onClick={handlePDFClicked}
			sx={buttonStyle}>
			PDF
		</Button>
	);

	const actionButton = showActionButtons && (
		<Button
			variant="contained"
			onClick={action.onClick}
			sx={{
				...buttonStyle,
				mt: needsPreApproveFormatting ? 1 : undefined,
			}}
			disabled={action.disabled}>
			<Typography noWrap fontSize="inherit">
				{action.buttonText}
			</Typography>
		</Button>
	);

	// Needs to be two rows on small screens
	return isXSmallScreen ? (
		<>
			<Stack
				justifyContent="space-between"
				direction="row"
				m={1}
				spacing={1}>
				{backButton}
				{nextButton}
			</Stack>
			<Stack
				justifyContent="center"
				direction="row"
				mt={0}
				rowGap={1}
				spacing={1}
				mb={needsPreApproveFormatting ? 1 : undefined}
				flexWrap={needsPreApproveFormatting ? 'wrap' : undefined}>
				{editButton}
				{pdfButton}
				{actionButton}
			</Stack>
		</>
	) : (
		<Stack justifyContent="space-between" direction="row" m={1} spacing={1}>
			{backButton}
			<Stack direction="row" spacing={1}>
				{editButton}
				{pdfButton}
				{actionButton}
			</Stack>
			{nextButton}
		</Stack>
	);
};
